import { h } from 'preact'

import './index.sass'

export default function Hamburger({...props}){
  return <div
    className="Hamburger flex-col"
    {...props}
  >
    <span className="Hamburger-bar" />
    <span className="Hamburger-bar" />
    <span className="Hamburger-bar" />
  </div>
}
