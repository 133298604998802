import { h } from 'preact'
import moment from 'moment'
import { bindToAppState, useAppState } from 'lib/appState'
import Page from 'lib/Page'

import BobLayout from 'components/BobLayout'
import OneColumn from 'components/OneColumn'
import Header from 'components/Header'
import OrganizationIcon from 'components/OrganizationIcon'
import Link from 'components/Link'
import Loading from 'components/Loading'
import HelpButton from 'components/HelpButton'
import BisaButton from 'components/BisaButton'
import './index.sass'

class BusinessAgreementsPage extends Page {

  static pageTitle = 'Business - Agreements'

  render() {
    const {
      organizationProfile,
      loadingOrganizations,
      organizations,
      bisas,
      bisaOfferings,
    } = this.props

    const layoutProps = {
      location: this.props.location,
      crumbs: [
        'Business',
        'Agreements',
      ],
      organizationProfile,
      className: 'BusinessAgreementsPage',
    }

    if (
      !organizationProfile ||
      !organizations ||
      loadingOrganizations ||
      !bisas ||
      !bisaOfferings
    ){
      return <BobLayout {...layoutProps} >
        <Loading type="fullPage" />
      </BobLayout>
    }

    const myOrganizationApikey = organizationProfile.apikey

    const resolveBisaOffering = this.props.appAction('bisas.resolveBisaOffering')

    const renderedBisaOfferings = bisaOfferings
      .map(bisaOffering => {
        return <BisaOffering {...{
          key: bisaOffering.id,
          myOrganizationApikey,
          organizations,
          offeror: organizations[bisaOffering.offerorApikey],
          target: organizations[bisaOffering.targetApikey],
          bisaOffering,
          resolveBisaOffering,
        }}/>
      })

    const renderedBisas = bisas
      .map(bisa => {
        return <OrganizationRow {...{
          key: bisa.bisaId,
          myOrganizationApikey,
          organizations,
          bisa,
          offeror: organizations[bisa.offerorApikey],
          target: organizations[bisa.acceptorApikey],
          resolveBisaOffering,
        }}/>
      })

    return <BobLayout {...layoutProps} >
      <OneColumn>
        <Header size="md" underlined>BISA Offerings</Header>
        <div
          className="BusinessAgreementsPage-organizationList BusinessAgreementsPage-organizationList-offerings"
        >
          {renderedBisaOfferings}
        </div>

        <Header size="md" underlined>
          BISA Agreements
          <BisaHelpButton />
        </Header>
        <div
          className="BusinessAgreementsPage-organizationList BusinessAgreementsPage-organizationList-agreements"
        >
          {renderedBisas}
        </div>
      </OneColumn>
    </BobLayout>
  }
}

function BisaOffering({ bisaOffering, ...props }){
  const {
    resolvingBisaOffering,
    errorResolvingBisaOffering,
  } = useAppState({
    [`resolvingBisaOffering:${bisaOffering.id}`]: 'resolvingBisaOffering',
    [`errorResolvingBisaOffering:${bisaOffering.id}`]: 'errorResolvingBisaOffering',
  })
  return <OrganizationRow {...{
    ...props,
    bisaOffering,
    resolvingBisaOffering,
    errorResolvingBisaOffering,
  }}/>
}

function OrganizationRow({
  myOrganizationApikey,
  organizations,
  bisaOffering,
  bisa,
  resolveBisaOffering,
  resolvingBisaOffering,
  errorResolvingBisaOffering,
}){

  const bisaOfferedToMe = (bisa || bisaOffering).offerorApikey !== myOrganizationApikey

  const getOrgProp = prop =>
    bisaOfferedToMe
      ? (bisa ? bisa[`offeror${prop}`] : bisaOffering[`offeror${prop}`])
      : (bisa ? bisa[`acceptor${prop}`] : bisaOffering[`target${prop}`])

  const organizationApikey = getOrgProp('Apikey')
  const organizationIsPublic = !!organizations[organizationApikey]
  const organization = organizationIsPublic
    ? organizations[organizationApikey]
    : {
      apikey: organizationApikey,
      name: getOrgProp('Name'),
      business_icon: getOrgProp('Icon'),
    }

  const bisaStatusText = (
    bisa ? 'Signed BISA' :
    bisaOfferedToMe ? 'Offered to me' : 'Offered'
  )

  const createdAt = (
    bisaOffering && bisaOffering.offeredBisa.createdAt ||
    bisa && bisa.acceptedBisa.createdAt
  )
  const linkContent = <span>
    <OrganizationIcon
      organization={organization}
      brandingType="business"
    />
    <div className="BusinessAgreementsPage-OrganizationRow-nameAndBisaDate">
      <div className="BusinessAgreementsPage-OrganizationRow-name">
        <span>{organization.name || organization.apikey}</span>
        {organizationIsPublic || <small> *private</small>}
      </div>
      <div className="BusinessAgreementsPage-OrganizationRow-bisaDate">
        {bisaStatusText} on {moment(createdAt).format('MM/DD/YY')}
      </div>
    </div>
  </span>

  const link = organizationIsPublic
    ? <Link
      type="link"
      className="BusinessAgreementsPage-OrganizationRow-link"
      href={`/b/orgs/${organization.apikey}`}
    >
      {linkContent}
    </Link>
    : <div className="BusinessAgreementsPage-OrganizationRow-link">{linkContent}</div>

  return <div className="BusinessAgreementsPage-OrganizationRow">
    {link}
    <BisaButton {...{
      offerBisa: () => {},
      organization,
      bisaOffering,
      resolveBisaOffering,
      resolvingBisaOffering,
      errorResolvingBisaOffering,
      bisa,
    }}/>
  </div>
}

function BisaHelpButton() {
  return <HelpButton className="BusinessAgreementsPage-BisaHelpButton">
    <Header size="lg">BISA</Header>
    <p>
      A Business Information Sharing Agreement (BISA) provides a way for businesses to share
      data across the Internet. Under a BISA, signing parties agree to abide by the data sharing controls and
      permissions set using JLINC technology. JLINC automatically generates a digitally signed
      “BISA Event”, and stores a copy on jlinc.io. A digital proof of each BISA Event is also written to a
      public audit record.
    </p>
  </HelpButton>
}

export default bindToAppState(
  [
    'organizationProfile',
    'loadingOrganizations',
    'organizations',
    'bisas',
    'bisaOfferings',
  ],
  BusinessAgreementsPage,
  props => {
    props.takeAction('organizations.loadAll')
    props.takeAction('bisas.loadAll')
    props.takeAction('bisas.loadAllOfferings')
  },
)
