import { useEffect } from 'preact/hooks'
import useForceUpdate from 'lib/useForceUpdate'

export default function useUpdateEverySecond(){
  const forceUpdate = useForceUpdate()
  useEffect(
    () => {
      everySecond.add(forceUpdate)
      return () => {
        everySecond.remove(forceUpdate)
      }
    },
    []
  )
}

const everySecond = {
  subscribers: new Set(),
  start(){
    if (this.timeout) return
    this.timeout = setInterval(() => { this.call() }, 1000)
  },
  stop(){
    clearInterval(this.timeout)
    delete this.timeout
  },
  add(subscriber) {
    this.subscribers.add(subscriber)
    this.start()
  },
  remove(subscriber){
    this.subscribers.delete(subscriber)
    if (this.subscribers.size === 0) this.stop()
  },
  call(){
    this.subscribers.forEach(subscriber => { subscriber() })
  },
}
