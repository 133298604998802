import logger from 'lib/logger'
import { getAppVersion } from 'lib/appVersion'

const REPORT_PROP = '__jlinc_report'

export function getReport(error){ return error[REPORT_PROP] }

export async function reportError(error, context = {}){
  if (!(error instanceof Error)){
    logger.warn('[errorReporting] ignoring non-error', error)
    return { ignored: true }
  }
  if (error[REPORT_PROP]) return error[REPORT_PROP]
  if (isIgnorable(error)){
    logger.debug('[errorReporting] ignoring error', error)
    error[REPORT_PROP] = { ignored: true }
    return error[REPORT_PROP]
  }
  const report = error[REPORT_PROP] = {
    error: error.message,
    stack: error.stack,
    uuid: generateUUID(),
    at: Date.now(),
    context,
    version: getAppVersion(),
    window: getWindowDetails(),
    navigator: getNavigatorInfo(),
  }
  logger.debug('[errorReporting] reporting', report)
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
  }
  const body = JSON.stringify(report)
  try{
    await fetch(
      `${JLINC_A_API}/portal_errors`,
      { method: 'POST', body, headers }
    )
    report.reportedAt = new Date()
    logger.info('[errorReporting] reported', report)
  }catch(failed){
    logger.error('[errorReporting] report failed', failed)
    report.failed = failed
  }
  return report
}

const generateUUID = () =>
  `${Date.now()}${Math.random().toString().slice(2)}`

export function getNavigatorInfo(){
  const { appCodeName, appName, onLine, userAgent, vendor } = window.navigator
  return { appCodeName, appName, onLine, userAgent, vendor }
}

export function getWindowDetails(){
  return {
    height: window.innerHeight,
    width: window.innerWidth,
    location: `${window.location}`,
  }
}

const IGNORABLE_ERROR_MESSAGE_PATTERNS = [
  'NetworkError',
  'The operation was aborted',
  'Failed to fetch',
]

function isIgnorable(error){
  const msg = `${error.message}`
  return IGNORABLE_ERROR_MESSAGE_PATTERNS.some(pattern =>
    typeof pattern === 'string' ? msg.includes(pattern) :
    false
  )
}
