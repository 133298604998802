import { h, Fragment } from 'preact'
import { useRef, useState, useCallback, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'
import ReactEasyCrop from 'react-easy-crop'

import classNames from 'lib/classNames'
import { aspectRatioToTopPadding } from 'lib/imageHelpers'

import IconButton from 'components/IconButton'
import './index.sass'

export default function CropImage({
  className,
  image,
  circle,
  height,
  width,
  minZoom = 0.4,
  maxZoom = 10,
  type = 'image/png',
  backgroundFillStyle,
  encoderOptions,
  onChange,
}){
  const rootRef = useRef()
  const [cropSize, setCropSize] = useState()

  const aspect = width / height
  const calulateCropSize = useCallback(
    () => {
      const boundingClientRect = rootRef.current.getBoundingClientRect()
      const fittingWidth = Math.min(width, boundingClientRect.width)
      const fittingHeight = Math.min(height, boundingClientRect.height)
      const adjustedHeight = fittingHeight * aspect
      setCropSize(
        fittingWidth > adjustedHeight
          ? {width: adjustedHeight, height: fittingHeight}
          : {width: fittingWidth, height: fittingWidth / aspect}
      )
    },
    [height, width, rootRef.current]
  )

  useEffect(
    () => {
      calulateCropSize()
      window.addEventListener('resize', calulateCropSize)
      return () => {
        window.removeEventListener('resize', calulateCropSize)
      }
    },
    [calulateCropSize]
  )

  const cropTimeoutRef = useRef()
  const onCropComplete = useCallback(
    (_, croppedAreaPixels) => {
      const doTheCrop = () => {
        cropImage({
          image,
          height,
          width,
          crop: croppedAreaPixels,
          backgroundFillStyle,
          type,
          encoderOptions,
        }).then(onChange)
      }
      clearTimeout(cropTimeoutRef.current)
      cropTimeoutRef.current = setTimeout(doTheCrop, 200)
    },
    [height, width, onChange, backgroundFillStyle, type, encoderOptions],
  )

  return <div
    ref={rootRef}
    className={classNames('CropImage', { circle, className })}
    style={{
      paddingTop: aspectRatioToTopPadding(height, width),
      width: `${width}px`,
      backgroundColor: backgroundFillStyle,
    }}
  >
    {cropSize &&
      <Cropper {...{
        minZoom,
        maxZoom,
        image,
        circle,
        aspect,
        cropSize,
        onCropComplete,
      }}/>
    }
  </div>
}

CropImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.instanceOf(global.Image).isRequired,
  circle: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  type: PropTypes.string,
  backgroundFillStyle: PropTypes.string,
  encoderOptions: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

function Cropper({
  minZoom,
  maxZoom,
  image,
  circle,
  aspect,
  cropSize,
  onCropComplete,
}){
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const rootRef = useRef()
  const zoomRef = useRef()
  zoomRef.current = zoom
  const zoomingRef = useRef()

  const doZoom = useCallback(() => {
    if (zoomingRef.current === undefined){
      rootRef.current.imageRef.style.transition = ''
      return
    }
    zoomRef.current *= zoomingRef.current ? 1.1 : 0.9
    if (zoomRef.current < minZoom) zoomRef.current = minZoom; else
    if (zoomRef.current > maxZoom) zoomRef.current = maxZoom
    rootRef.current.imageRef.style.transition = 'transform 100ms ease-in-out 0s'
    setZoom(zoomRef.current)
  }, [])

  const stopZoom = useCallback(() => {
    zoomingRef.current = undefined
  }, [])

  const startZoom = useCallback(zoomIn => {
    zoomingRef.current = zoomIn
    doZoom()
  }, [])

  const zoomIn = useCallback(() => {startZoom(true)}, [])

  const zoomOut = useCallback(() => {startZoom(false)}, [])

  useEffect(
    () => {
      rootRef.current.imageRef.addEventListener('transitionend', doZoom)
      window.addEventListener('mouseup', stopZoom)
      return () => {
        rootRef.current.imageRef.removeEventListener('transitionend', doZoom)
        window.removeEventListener('mouseup', stopZoom)
      }
    },
    []
  )

  // https://github.com/ricardo-ch/react-easy-crop/tree/v3.1.1
  return <Fragment>
    <ReactEasyCrop {...{
      ref: rootRef,
      minZoom,
      maxZoom,
      image: image.src,
      cropShape: circle ? 'round' : 'rect',
      crop,
      zoom,
      aspect,
      cropSize,
      restrictPosition: false,
      showGrid: false,
      onCropChange: setCrop,
      onZoomChange: setZoom,
      onCropComplete,
    }}/>
    <div className="CropImage-buttons">
      <IconButton
        bordered
        value="+"
        onMouseDown={zoomIn}
        onMouseUp={stopZoom}
      />
      <IconButton
        bordered
        value="-"
        onMouseDown={zoomOut}
        onMouseUp={stopZoom}
      />
    </div>
  </Fragment>
}

const PIXEL_RATIO = 2 // we double image sizes for retina
async function cropImage({
  image,
  height,
  width,
  crop,
  backgroundFillStyle,
  type,
  encoderOptions,
}){
  const canvas = document.createElement('canvas')
  canvas.width = width * PIXEL_RATIO
  canvas.height = height * PIXEL_RATIO
  const ctx = canvas.getContext('2d')
  if (backgroundFillStyle) {
    ctx.fillStyle = backgroundFillStyle
    ctx.fillRect(0, 0, canvas.width, canvas.height)
  }

  const cropToCanvasRatio = crop.width / canvas.width
  let sx = 0
  let sy = 0
  let sWidth = image.width
  let sHeight = image.height
  let dx = Math.abs(crop.x) / cropToCanvasRatio
  let dy = Math.abs(crop.y) / cropToCanvasRatio
  let dWidth = image.width / cropToCanvasRatio
  let dHeight = image.height / cropToCanvasRatio
  if (crop.x >= 0) {
    sx = crop.x
    sWidth = image.width - crop.x
    dx = 0
    dWidth = (image.width - crop.x) / cropToCanvasRatio
  }
  if (crop.y >= 0) {
    sy = crop.y
    sHeight = image.height - crop.y
    dy = 0
    dHeight = (image.height - crop.y) / cropToCanvasRatio
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
  ctx.drawImage(
    image,
    sx,
    sy,
    sWidth,
    sHeight,
    dx,
    dy,
    dWidth,
    dHeight,
  )
  return canvas.toDataURL(type, encoderOptions)
}
