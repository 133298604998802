import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import BisaModal from 'components/BisaModal'
import './index.sass'

export default class BisaButton extends Component {

  static propTypes = {
    organization:               BisaModal.propTypes.organization,
    disabled:                   PropTypes.bool,
    offerBisa:                  BisaModal.propTypes.offerBisa,
    offeringBisa:               BisaModal.propTypes.offeringBisa,
    errorOfferingBisa:          BisaModal.propTypes.errorOfferingBisa,
    bisaOffering:               BisaModal.propTypes.bisaOffering,
    resolveBisaOffering:        BisaModal.propTypes.resolveBisaOffering,
    resolvingBisaOffering:      BisaModal.propTypes.resolvingBisaOffering,
    errorResolvingBisaOffering: BisaModal.propTypes.errorResolvingBisaOffering,
    bisa:                       PropTypes.object,
  }

  state = {
    bisaModalOpen: false,
  }

  openBisaModal = () => { this.setState({ bisaModalOpen: true }) }

  closeBisaModal = () => { this.setState({ bisaModalOpen: false }) }

  render(){
    const {
      organization,
      offerBisa,
      offeringBisa,
      errorOfferingBisa,
      bisaOffering,
      resolveBisaOffering,
      resolvingBisaOffering,
      errorResolvingBisaOffering,
      bisa,
      disabled,
    } = this.props
    const {
      bisaModalOpen
    } = this.state
    const {
      openBisaModal,
      closeBisaModal,
    } = this

    return <span className="BisaButton">
      {
        bisa ? <Button
          type="normal"
          href={`/b/orgs/${organization.apikey}/bisa`}
          value="View BISA"
          disabled={disabled}
        />
        : bisaOffering ? (
          bisaOffering.targetApikey === organization.apikey ?
            <Button
              type="normal"
              onClick={openBisaModal}
              value="BISA Offered"
              disabled={disabled}
            /> :
            <Button
              type="primary"
              onClick={openBisaModal}
              value="BISA Pending"
              disabled={disabled}
            />
        ) :
          <Button
            type="primary"
            disabled={offeringBisa || disabled}
            onClick={openBisaModal}
            value={offeringBisa ? 'Connecting…' : 'Connect'}
          />
      }
      {bisaModalOpen &&
        <BisaModal {...{
          closeBisaModal,
          organization,
          offerBisa,
          offeringBisa,
          errorOfferingBisa,
          bisaOffering,
          resolveBisaOffering,
          resolvingBisaOffering,
          errorResolvingBisaOffering,
        }}/>
      }
    </span>
  }
}
