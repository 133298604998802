import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import StripeCheckout from 'react-stripe-checkout'

import Button from 'components/Button'
import Card from 'components/Card'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'

import './index.sass'

export default class BobSignupStripeForm extends Component {

  static propTypes = {
    error: ErrorMessage.propTypes.error,
    signup: PropTypes.func.isRequired,
    dismissErrorMessage: PropTypes.func.isRequired,
    signingUp: PropTypes.bool.isRequired,
    email: PropTypes.string,
    autoOpenStripeForm: PropTypes.bool,
  }

  componentDidMount(){
    if (this.props.autoOpenStripeForm) this.stripeCheckout.onClick()
  }

  submitStripeForm = ({ id, email }) => {
    this.props.signup({
      stripeToken: id,
      email,
    })
  }

  render(){
    const { error, email, signingUp } = this.props
    const awaitingEmailConfirmationMessage = <div
      className="BobSignupStripeForm-emailConfirmationMessage"
    >
      {
        error
          ? "Please refresh the page and try again."
          : `To continue creating your account, please check your email.
             A signup link has been sent to ${email}.`
      }
    </div>

    const paymentForm = <div className="BobSignupStripeForm-paymentForm">
      <div>Begin by verifying a credit card with us. You will not be charged.</div>
      <span onClick={this.props.dismissErrorMessage}>
        <StripeCheckout
          ref={node => { this.stripeCheckout = node}}
          panelLabel="Verify"
          allowRememberMe={false}
          token={this.submitStripeForm}
          stripeKey={STRIPE_KEY} // eslint-disable-line no-undef
          name="Signup"
          label="Verify Identity"
        >
          <Button type="primary" fat>
            Verify Identity
          </Button>
        </StripeCheckout>
      </span>
    </div>

    return <Card
      className="BobSignupStripeForm"
    >
      <Card.Header>
        Create Organisation
      </Card.Header>
      <Card.Body>
        <ErrorMessage error={error} />
        {
          signingUp ?
            <Loading type="block" /> :
            email
              ? awaitingEmailConfirmationMessage
              : paymentForm
        }
      </Card.Body>
    </Card>
  }
}
