import api from '../api'
import { filterOutNonChanges } from 'lib/organizationProfileHelpers'

export default function(changes){
  if (this.getState().updatingOrganizationProfile) return Promise.resolve()
  const { organizationProfile = {} } = this.getState()
  changes = filterOutNonChanges(organizationProfile, changes)
  if (Object.keys(changes).length === 0) return Promise.resolve()

  this.setState({
    updatingOrganizationProfile: true,
    errorUpdatingOrganizationProfile: undefined,
  })

  const publishing = !organizationProfile.published && changes.published

  return api.updateOrganizationProfile({ changes }).then(
    ({organizationProfile}) => {
      const organizationProfileChanges = { ...this.getState().organizationProfileChanges }
      for (const key in changes) delete organizationProfileChanges[key]
      this.setState({
        updatingOrganizationProfile: undefined,
        organizationProfileChanges,
        organizationProfile,
      })
      if (publishing) {
        const { networkInvites = [] } = this.getState()
        const path = (
          networkInvites.length > 0 ? '/networks' : '/'
        )
        this.takeAction('location.set', path)
      }
      if (
        'consumer_marketplace_industries'  in changes ||
        'consumer_marketplace_brands'  in changes ||
        'consumer_marketplace_tags'  in changes
      ){
        this.takeAction('buyingInterests.reload', {type: 'consumer'})
      }
      if (
        'business_marketplace_industries'  in changes ||
        'business_marketplace_brands'  in changes ||
        'business_marketplace_tags'  in changes
      ){
        this.takeAction('buyingInterests.reload', {type: 'business'})
      }
    },
    errorUpdatingOrganizationProfile => {
      this.setState({
        updatingOrganizationProfile: undefined,
        errorUpdatingOrganizationProfile,
      })
    }
  )
}
