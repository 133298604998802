import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import SwitchListWithDescription from 'components/SwitchListWithDescription'

import './index.sass'

export default class CategorizedConsentsList extends Component {

    static propTypes = {
      className: PropTypes.string,
      enabledConsents: PropTypes.object.isRequired,
      changes: PropTypes.object,
      disabled: PropTypes.bool,
      onChange: PropTypes.func.isRequired,
      descriptions: PropTypes.object.isRequired,
      requestedConsents: PropTypes.array,
      descriptionsDefaultOpen: PropTypes.bool,
      consentsByCategory: PropTypes.object.isRequired,
    }

    static defaultProps = {
      className: '',
    }

    render() {
      const {
        className,
        changes = {},
        enabledConsents,
        disabled,
        descriptions,
        onChange,
        descriptionsDefaultOpen,
        consentsByCategory,
      } = this.props

      const categorizedConsentsList = Object.keys(consentsByCategory).map(category => {
        if (consentsByCategory[category].length === 0) return

        const switchesAndText = consentsByCategory[category].map(consent => {
          const unsaved = consent in changes
          const checked = unsaved
            ? !!changes[consent]
            : !!enabledConsents[consent]
          return {
            title: consent,
            description: descriptions[consent],
            checked,
            unsaved,
          }
        })

        return <div className="CategorizedConsentsList-row">
          <Header className="CategorizedConsentsList-row-header" size="md">
            {category}
          </Header>
          <SwitchListWithDescription
            disabled={disabled}
            switchesAndText={switchesAndText}
            onSwitchChange={onChange}
            descriptionsDefaultOpen={descriptionsDefaultOpen}
          />
        </div>
      })

      return <div className={`CategorizedConsentsList ${className}`}>
        {categorizedConsentsList}
      </div>
    }
}
