import { h } from 'preact'

import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'
import ResetPasswordForm from 'components/ResetPasswordForm'

import './index.sass'

class ResetPasswordPage extends Page {

  static pageTitle = 'Reset Password'

  render() {
    const {
      appAction,
      resetPasswordToken,
      verifyingResetPasswordTokenError,
      verifyingResetPasswordToken,
      verifiedResetPasswordToken,
      passwordResetRequest,
    } = this.props

    return <div className="ResetPasswordPage fullpage">
      <ResetPasswordForm
        resetPasswordToken={resetPasswordToken}
        verifyingResetPasswordToken={!!verifyingResetPasswordToken}
        verifyingResetPasswordTokenError={verifyingResetPasswordTokenError}
        resetPasswordTokenVerified={resetPasswordToken === verifiedResetPasswordToken}
        resetPassword={appAction('resetPassword')}
        passwordResetRequest={passwordResetRequest}
      />
    </div>
  }
}


export default bindToAppState(
  [
    'verifyingResetPasswordTokenError',
    'verifyingResetPasswordToken',
    'verifiedResetPasswordToken',
    'passwordResetRequest',
  ],
  ResetPasswordPage,
  props => {
    if (!props.verifiedResetPasswordToken) props.takeAction('verifyResetPasswordToken', props.resetPasswordToken)
  }
)
