import 'style/index.sass'
import { h } from 'preact'
import { useErrorBoundary } from 'preact/hooks'

import { AppState, AppStateProvider, useAppState } from 'lib/appState'
import { Portal } from 'lib/portal'
import history from 'lib/history'
import { onReturnToStaleApp } from 'lib/onReturnToStaleApp'

import LoginAndSignupPage from './pages/LoginAndSignupPage'
import CompleteSignupPage from './pages/CompleteSignupPage'
import SetUpPage from './pages/SetUpPage'
import ResetPasswordPage from './pages/ResetPasswordPage'

import router from './router'
import BPortalTheme from 'components/BPortalTheme'
import Loading from 'components/Loading'
import HelpAndFeedback from 'components/HelpAndFeedback'
import ErrorMessage from 'components/ErrorMessage'

import * as actions from './actions'
import api from './api'

global.DEBUG = global.DEBUG || {}
global.DEBUG.api = api

const appState = new AppState({actions, init})

function init(){
  api.on('unauthorized', () => {
    console.warn('logging out because of unauthorized error')
    this.takeAction('session.logout', {post: false})
  })

  history.subscribe(() => {
    this.takeAction('location.publish')
  })

  window.addEventListener('beforeunload', (event) => {
    const state = this.getState()
    const hasUnsavedChanges = (Object.keys(state.organizationProfileChanges || {}).length > 0)

    if (hasUnsavedChanges) {
      return event.returnValue = "Are you sure you want to reload? Changes you made will not be saved."
    }
  })

  onReturnToStaleApp(() => {
    this.takeAction('session.verify')
  })

  this.takeAction('location.publish')
  this.takeAction('session.restore')
}

export default function App(){
  return <AppStateProvider {...{appState}}>
    <BPortalTheme>
      <HelpAndFeedback />
      <AppRoot />
      <Portal/>
    </BPortalTheme>
  </AppStateProvider>
}

function AppRoot() {
  const [error] = useErrorBoundary()

  const {
    location,
    verifyingSession,
    currentUser,
    loadingOverview,
    organizationProfile,
  } = useAppState([
    'location',
    'verifyingSession',
    'currentUser',
    'loadingOverview',
    'organizationProfile',
  ], 'AppRoot')

  if (error) {
    console.error('AppRoot RENDER ERROR', error)
    return <ErrorMessage error={error}/>
  }
  const onSignupPage = location.pathname.match(/\/signup\/(.*)$/)
  const signupToken = onSignupPage && onSignupPage[1]
  if (signupToken || (organizationProfile && !organizationProfile.published))
    return <CompleteSignupPage location={location} signupToken={signupToken} />

  if (verifyingSession || loadingOverview)
    return <Loading type="fullScreen" />

  const resetPasswordPath = location.pathname.match(/^\/reset-password\/([^\s\/]{32})/)
  if (resetPasswordPath) {
    return <ResetPasswordPage
      resetPasswordToken={resetPasswordPath[1]}
      location={location}
    />
  }

  if (!currentUser) return <LoginAndSignupPage location={location} />

  const verifyEmail = location.pathname.match(/^\/verify_email\/([^\s\/]{32})/)
  if (verifyEmail) {
    return <SetUpPage
      location={location}
      params={{}}
      emailVerificationCode={verifyEmail[1]}
    />
  }

  const { Component, params } = router.resolve(location.pathname)
  return <Component location={location} params={params} />
}
