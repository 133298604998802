import { h, Component } from 'preact'
import { bindToAppState } from 'lib/appState'
import {BISA_SUMMARY} from 'lib/accountDataSpec'
import Page from 'lib/Page'

import Link from 'components/Link'
import Loading from 'components/Loading'
import BobLayout from 'components/BobLayout'
import Header from 'components/Header'
import OneColumn from 'components/OneColumn'
import Timestamp from 'components/Timestamp'
import TimeAgo from 'components/TimeAgo'
import Alert from 'components/Alert'
import SisaText from 'components/SisaText'

class BusinessAgreementPage extends Page {

  getPageTitle(props){
    return `${this.organizationDisplayName(props)} - BISA`
  }

  organizationDisplayName(props = this.props){
    const { organizationApikey } = props.params
    const organization = organizationApikey && (this.props.organizations || {})[organizationApikey]
    return (organization && organization.name) || organizationApikey
  }

  render() {
    const { organizationApikey } = this.props.params
    const {
      organizationProfile,
      loadingOrganizations,
      loadingBisas,
      loadingBisaOfferings,
      organizations,
      bisas,
    } = this.props

    const layoutProps = {
      className: 'BusinessAgreementPage',
      location: this.props.location,
      selectedOrganization: organizationApikey,
      crumbs: [
        <Link href="/b/orgs">Organizations</Link>,
        <Link href={`/b/orgs/${organizationApikey}`}>{this.organizationDisplayName()}</Link>,
        'Bisa'
      ],
      organizationProfile,
    }

    if (
      !organizations ||
      !organizationProfile ||
      loadingOrganizations ||
      loadingBisas ||
      loadingBisaOfferings
    ){
      return <BobLayout {...layoutProps}>
        <Loading type="fullPage" />
      </BobLayout>
    }

    const bisa = bisas && bisas.find(bisa =>
      bisa.acceptorApikey === organizationApikey ||
      bisa.offerorApikey === organizationApikey
    )

    const organization = organizations[organizationApikey] || (
      bisa.acceptorApikey === organizationProfile.apikey
        ? {
          apikey: bisa.offerorApikey,
          name: bisa.offerorName,
          business_icon: bisa.offerorIcon,
        }
        : {
          apikey: bisa.acceptorApikey,
          name: bisa.acceptorName,
          business_icon: bisa.acceptorIcon,
        }
    )

    return <BobLayout {...layoutProps}>
      <BusinessAgreementPageContent
        organization={organization}
        bisa={bisa}
        organizationProfile={organizationProfile}
      />
    </BobLayout>
  }
}

class BusinessAgreementPageContent extends Component {
  render(){
    const { bisa, organization, organizationProfile } = this.props

    const header = <Header size="lg" className="BusinessAgreementPage-header" underlined>
      Your BISA with {organization.name}
    </Header>

    if (!bisa) return <OneColumn>
      {header}
      <Alert type="warning">You do not have a BISA with {organization.name}</Alert>
    </OneColumn>

    const signedByAcceptor = bisa.acceptedBisa.createdAt

    const bisaText = <p>
      <SisaText
        text={BISA_SUMMARY}
        organization={organization}
      />
    </p>

    return <OneColumn>
      {header}
      <p>Between {organization.name} & {organizationProfile.name}</p>
      <p>
        Signed &nbsp;
        <TimeAgo time={signedByAcceptor}/> (<Timestamp time={signedByAcceptor} />)
      </p>
      {bisaText}
    </OneColumn>
  }
}

export default bindToAppState(
  [
    'organizationProfile',
    'loadingOrganizations',
    'loadingBisas',
    'loadingBisaOfferings',
    'organizations',
    'bisas',
  ],
  BusinessAgreementPage,
  props => {
    props.takeAction('organizations.loadAll')
    props.takeAction('bisas.loadAll')
  },
)
