import logger from 'lib/logger'

export default function(changes){
  logger.debug('stageOrganizationRequestedDataChanges', changes)

  const {
    organizationProfile: {
      requested_data,
    },
  } = this.getState()
  const organizationProfileChanges = {
    ...this.getState().organizationProfileChanges || {},
  }

  let updatedRequestedDataChanges = {
    ...organizationProfileChanges.requested_data,
    ...changes,
  }

  Object.keys(updatedRequestedDataChanges).forEach(key => {
    if (
      typeof updatedRequestedDataChanges[key] === 'undefined' ||
      updatedRequestedDataChanges[key] === requested_data[key]
    ){
      delete updatedRequestedDataChanges[key]
    }
  })

  if (Object.keys(updatedRequestedDataChanges).length === 0)
    updatedRequestedDataChanges = undefined

  organizationProfileChanges.requested_data = updatedRequestedDataChanges
  this.setState({
    organizationProfileChanges: {...organizationProfileChanges},
  })
}
