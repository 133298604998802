export const disableDocumentScrolling = function() {
  document.documentElement.classList.add('with-modal')
  document.body.addEventListener("touchmove", preventDefault, {passive:true})
}

export const enableDocumentScrolling = function() {
  document.documentElement.classList.remove('with-modal')
  document.body.removeEventListener("touchmove", preventDefault, {passive:true})
}

export const preventDefault = event => {
  event.preventDefault()
}
