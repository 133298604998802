import { h } from 'preact'
import PropTypes from 'prop-types'
import { bindToAppState, useAppState } from 'lib/appState'
import Page from 'lib/Page'
import PNFO from 'jlinc-shared/PNFO'

import OneColumn from 'components/OneColumn'
import BobLayout from 'components/BobLayout'
import Loading from 'components/Loading'
import Header from 'components/Header'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Button from 'components/Button'
import SisaText from 'components/SisaText'
import BobEmailList from 'components/BobEmailList'
import Alert from 'components/Alert'
import Spinner from 'components/Spinner'
import BobAddEmailForm from 'components/BobAddEmailForm'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationProfileForm from 'components/OrganizationProfileForm'

import  './index.sass'
import { BOB_SISA_SUMMARY, FULL_SISA_URL } from 'lib/accountDataSpec'

class SetUpPage extends Page {

  state = {
    viewSisaModalVisible: false,
  }

  getPageTitle(){
    const { organizationProfile } = this.props
    return organizationProfile
      ? `${organizationProfile.apikey} - Set-Up`
      : `Set-Up`
  }

  componentDidMount(){
    super.componentDidMount()
    this.props.takeAction('loadOrganizationProfile')
    this.props.takeAction('loadEmails')

    if (this.props.emailVerificationCode) {
      this.props.takeAction('verifyEmail', this.props.emailVerificationCode)
    }
  }

  hideViewSisaModal = () => {
    this.setState({ viewSisaModalVisible: false })
  }

  showViewSisaModal = () => {
    this.setState({ viewSisaModalVisible: true })
  }

  render() {
    const {
      emails,
      loadingEmails,
      loadingEmailsError,
      organizationProfile,
      organizationProfileChanges,
      updatingOrganizationProfile,
      errorUpdatingOrganizationProfile,
      verifyingEmail,
      addingEmailError,
      verifyingEmailError,
      addingEmail,
    } = this.props

    const layoutProps = {
      className: 'SetUpPage',
      location: this.props.location,
      crumbs: ['Set-Up'],
      organizationProfile,
    }

    if (!organizationProfile || !emails) return (
      <BobLayout {...layoutProps}>
        <Loading type="fullPage" />
      </BobLayout>
    )


    return <BobLayout {...layoutProps}>
      <OneColumn>
        <div className="SetUpPage-OrganizationProfile-topRow">
          <Header size="md" underlined>
            <div>{PNFO.singular} Profile</div>
            <div className="SetUpPage-OrganizationProfile-topRow-buttons">
              <Button type="primary" onClick={this.showViewSisaModal}>View SISA</Button>
            </div>
          </Header>
        </div>
        <OrganizationProfileForm
          organizationProfile={organizationProfile}
          organizationProfileChanges={organizationProfileChanges}
          updating={!!updatingOrganizationProfile || !!loadingEmails}
          hasChanges={!!(organizationProfileChanges && Object.keys(organizationProfileChanges).length > 0)}
          updatingOrganizationProfile={updatingOrganizationProfile}
          errorUpdatingOrganizationProfile={errorUpdatingOrganizationProfile}
          emails={emails}
          loadingEmailsError={loadingEmailsError}
          onChange={changes => this.props.takeAction('stageOrganizationProfileChanges', changes)}
          onCancel={this.props.appAction('clearOrganizationProfileChanges')}
          onUpdate={changes => this.props.takeAction('updateOrganizationProfile', changes)}
        />
        <AccountSettings
          verifyingEmail={verifyingEmail}
          emails={emails}
          addingEmailError={addingEmailError}
          verifyingEmailError={verifyingEmailError}
          organizationProfile={organizationProfile}
          addingEmail={addingEmail}
          resendEmail={this.props.appAction('resendEmailVerificationEmail')}
          addEmail={this.props.appAction('addEmail')}
        />
      </OneColumn>
      { this.state.viewSisaModalVisible &&
        <ViewSisaModal organization={organizationProfile} onClose={this.hideViewSisaModal}/>
      }
    </BobLayout>
  }
}

function AccountSettings(props) {
  const {
    verifyingEmail,
    emails,
    addingEmailError,
    verifyingEmailError,
    organizationProfile,
    addingEmail,
    resendEmail,
    addEmail,
  } = props

  const appState = useAppState(
    [
      ...Object.keys(emails).map(email => `resendingEmailVerification:${email}`)
    ],
    'SetUpPage'
  )

  const resendingEmailVerificationEmails = Object.keys(emails)
    .filter(email => appState[`resendingEmailVerification:${email}`])

  const verifyingEmailMessage = verifyingEmail
    ? <Alert type="warning">
      <Spinner />&nbsp;Verifying your email
    </Alert>
    : null

  return <div className="SetUpPage-AccountSettings">
    <Header size="md" underlined>Account Settings</Header>
    {verifyingEmailMessage}
    <ErrorMessage error={addingEmailError || verifyingEmailError} />
    <BobEmailList
      currentDpoEmail={organizationProfile.dpo_email}
      emails={emails}
      resendingEmailVerificationEmails={resendingEmailVerificationEmails}
      resendEmail={resendEmail}
    />
    <BobAddEmailForm
      emails={emails}
      addingEmail={addingEmail}
      onSubmit={addEmail}
    />
  </div>
}

const ViewSisaModal = ({organization, onClose}) => {
  const fullSisaLink = <Link type="link" newWindow href={FULL_SISA_URL}>
    Full SISA text
  </Link>

  return <Modal
    className="SetUpPage-SisaModal SetUpPage-ViewSisaModal"
    onClose={onClose}
  >
    <div className="flex-horizontal-center">
      <Header size="lg">Standard Information Sharing Agreement</Header>
    </div>
    <p><SisaText text={BOB_SISA_SUMMARY} organization={organization}/></p>
    <p>{fullSisaLink}</p>
    <div className="flex-horizontal-center">
      <Button
        type="primary"
        value="Continue"
        onClick={onClose}
        fat
      />
    </div>
  </Modal>
}

ViewSisaModal.propTypes = {
  organization: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}


export default bindToAppState(
  [
    'emails',
    'loadingEmails',
    'loadingEmailsError',
    'organizationProfile',
    'organizationProfileChanges',
    'updatingOrganizationProfile',
    'errorUpdatingOrganizationProfile',
    'verifyingEmail',
    'addingEmailError',
    'verifyingEmailError',
    'addingEmail',
  ],
  SetUpPage
)
