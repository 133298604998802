import api from '../api'

export default function(consents){
  this.setState({
    updatingOrganizationConsents: true,
  })

  return api.updateOrganizationProfile({ changes: { consents } }).then(
    ({organizationProfile}) => {
      this.setState({
        updatingOrganizationConsents: false,
        organizationProfile,
      })
    },
    updatingOrganizationConsentsError => {
      this.setState({
        updatingOrganizationConsents: false,
        updatingOrganizationConsentsError,
      })
    }
  )
}
