import api from '../api'

export default function(verificationCode) {
  this.setState({ verifyingEmail: true })

  api.verifyEmail({verificationCode})
    .then(
      ({email}) => {
        this.takeAction('location.set', '/')
        this.setState({
          emails: {...this.getState().emails, [email]: true},
          verifyingEmail: undefined,
        })
      },
      verifyingEmailError => {
        this.setState({
          verifyingEmail: undefined,
          verifyingEmailError
        })
      }
    )
}
