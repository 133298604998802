import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import {BISA_NOTICE, BISA_SUMMARY} from 'lib/accountDataSpec'

import Header from 'components/Header'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Modal from 'components/Modal'
import Timestamp from 'components/Timestamp'
import SisaText from 'components/SisaText'
import './index.sass'

export default class BisaModal extends Component {

  static propTypes = {
    className: PropTypes.string,
    closeBisaModal: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    offerBisa: PropTypes.func.isRequired,
    offeringBisa: PropTypes.bool,
    errorOfferingBisa: ErrorMessage.propTypes.error,
    bisaOffering: PropTypes.object,
    resolveBisaOffering: PropTypes.func.isRequired,
    resolvingBisaOffering: PropTypes.bool,
    errorResolvingBisaOffering: ErrorMessage.propTypes.error,
    bisa: PropTypes.object,
  }

  state = {
    showingSummary: false,
  }

  showSummary = () => {
    this.setState({ showingSummary: true })
  }

  render(){
    const {
      className = '',
      closeBisaModal,
      organization,
      offerBisa,
      offeringBisa,
      errorOfferingBisa,
      bisaOffering,
      resolveBisaOffering,
      resolvingBisaOffering,
      errorResolvingBisaOffering,
    } = this.props

    const {
      showingSummary,
    } = this.state

    const closeButton = <Button
      type="normal"
      onClick={closeBisaModal}
      value="close"
    />

    const bisaText = <p>
      <SisaText
        text={showingSummary ? BISA_SUMMARY : BISA_NOTICE}
        organization={organization}
        onSummaryClick={this.showSummary}
      />
    </p>

    return <Modal onClose={closeBisaModal} className={`BisaModal ${className}`}>
      {
        bisaOffering
          ? bisaOffering.targetApikey === organization.apikey
            ? <div>
              <Header size="lg">BISA with {organization.name} is pending</Header>
              <p>
                <span>You offered {organization.name} a BISA on </span>
                <Timestamp
                  time={bisaOffering.offeredBisa.createdAt}
                  format="MMM Do YYYY"
                />. We are waiting for them to either accept or reject.
              </p>
              {bisaText}
              <div className="BisaModal-buttons">
                {closeButton}
              </div>
            </div>
            : <div>
              <Header size="lg">{organization.name} is offering you a BISA</Header>
              <p>
                <span>{organization.name} offered you a BISA on </span>
                <Timestamp
                  time={bisaOffering.offeredBisa.createdAt}
                  format="MMM Do YYYY"
                />.
              </p>
              <ErrorMessage error={errorResolvingBisaOffering} />
              {bisaText}
              <div className="BisaModal-buttons">
                {closeButton}
                <Button
                  type="normal"
                  disabled={resolvingBisaOffering}
                  onClick={() => {
                    resolveBisaOffering({ bisaOffering, accept: false })
                    closeBisaModal()
                  }}
                  value={'Reject BISA'}
                />
                <Button
                  type="primary"
                  disabled={resolvingBisaOffering}
                  onClick={() => {
                    resolveBisaOffering({ bisaOffering, accept: true })
                    closeBisaModal()
                  }}
                  value={'Accept BISA'}
                />
              </div>
            </div>
          : <div>
            <Header size="lg">Offer a BISA to {organization.name}</Header>
            <ErrorMessage error={errorOfferingBisa} />
            {bisaText}
            <div className="BisaModal-buttons">
              {closeButton}
              <Button
                type="success"
                disabled={offeringBisa}
                onClick={() => {
                  offerBisa({ organizationApikey: organization.apikey })
                }}
                value={offeringBisa ? 'Creating BISA Offering…' : 'Offer BISA'}
              />
            </div>
          </div>
      }
    </Modal>
  }
}

