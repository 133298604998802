
import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import {
  organizationProfileShape,
  BRANDING_TYPES_PROP_TYPE,
} from 'lib/organizationProfileHelpers'
import filterObject from 'lib/filterObject'

import FormBuilder from 'components/FormBuilder'
import Header from 'components/Header'
import HelpButton from 'components/HelpButton'
import Form from 'components/Form'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationBannerInput from 'components/OrganizationBannerInput'
import OrganizationIconInput from 'components/OrganizationIconInput'
import WYSIWYGInput from 'components/WYSIWYGInput'

export default class BobBrandingForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    organizationProfile: PropTypes.shape(organizationProfileShape).isRequired,
    organizationProfileChanges: PropTypes.shape(organizationProfileShape),
    stageOrganizationProfileChanges: PropTypes.func.isRequired,
    updateOrganizationProfile: PropTypes.func.isRequired,
    updatingOrganizationProfile: PropTypes.bool,
    errorUpdatingOrganizationProfile: ErrorMessage.propTypes.error,
    additionalButtons: PropTypes.node,
    brandingType: BRANDING_TYPES_PROP_TYPE.isRequired,
  }

  render(){
    const { brandingType } = this.props
    const keys = [
      `${brandingType}_icon`,
      `${brandingType}_banner`,
      `${brandingType}_description`,
      'public',
    ]

    if (brandingType === 'network') {
      keys.push(`${brandingType}_name`)
    } else {
      keys.push(`${brandingType}_logo`)
    }

    return <FormBuilder
      className={`BobBrandingForm ${this.props.className || ''}`}
      error={this.props.errorUpdatingOrganizationProfile}
      value={filterObject(this.props.organizationProfileChanges, keys)}
      persistedValues={filterObject(this.props.organizationProfile, keys)}
      submitting={!!this.props.updatingOrganizationProfile}
      onChange={this.props.stageOrganizationProfileChanges}
      onSubmit={this.props.updateOrganizationProfile}
      render={form =>
        <div>
          <Header size="md">
            <span>
              Choose how your
              {{
                consumer: ` ${PNFO.singular} will appear to end users`,
                business: ` ${PNFO.singular} will appear to other ${PNFO.plural}`,
                network: ' network will appear to individuals and orgs',
              }[brandingType]}:
            </span>
            <HelpButton>
              You can either click-on or drag-and-drop a file onto the boxes next to LOGO, ICON or BANNER
              to upload an image.
            </HelpButton>
          </Header>
          {form.item({
            valueProp: `${brandingType}_banner`,
            label: 'BANNER',
            input:  <OrganizationBannerInput />,
          })}
          <Form.Row>
            {form.item({
              valueProp: `${brandingType}_icon`,
              label: 'ICON',
              input: <OrganizationIconInput />,
            })}
          </Form.Row>
          <Form.Row>
            {form.item({
              label: 'DESCRIPTION',
              valueProp: `${brandingType}_description`,
              bindTo: 'onInput',
              required: true,
              input: <WYSIWYGInput />,
            })}
          </Form.Row>
          <Form.ButtonRow>
            {this.props.additionalButtons}
            {form.resetButton({})}
            {form.submitButton({
              value: 'Update',
              submittingValue: 'Updating…',
            })}
          </Form.ButtonRow>
        </div>
      }
    />
  }
}
