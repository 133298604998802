import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Card from 'components/Card'
import Form from 'components/Form'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import Link from 'components/Link'
import OrganizationIcon from 'components/OrganizationIcon'

import './index.sass'

export default class BobSignupFromInviteForm extends Component {

  static propTypes = {
    inviteToken: PropTypes.string.isRequired,
    loadingInvite: PropTypes.bool,
    errorLoadingInvite: ErrorMessage.propTypes.error,
    invite: PropTypes.shape({
      created: PropTypes.string,
      invitedToNetwork: PropTypes.bool,
      invitingOrganization: PropTypes.shape({
        apikey: PropTypes.string.isRequired,
        business_logo: PropTypes.string.isRequired,
        business_icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        dpo_email: PropTypes.string.isRequired,
        domain: PropTypes.string.isRequired,
        contact_phone: PropTypes.string.isRequired,
        contact_phone_2: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        post_code: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        business_description: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        business_banner: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    }),
    onSubmit: PropTypes.func.isRequired,
    signingUp: PropTypes.bool,
    errorSigningUp: ErrorMessage.propTypes.error,
    signupEmail: PropTypes.string,
  }

  render(){
    const {
      inviteToken,
      loadingInvite,
      errorLoadingInvite,
      invite,
      onSubmit,
      signingUp,
      errorSigningUp,
      signupEmail,
    } = this.props

    const signInLink = <Link href="/" type="text">Sign in to existing account</Link>

    if (errorLoadingInvite){
      return <Card className="BobSignupFromInviteForm">
        <Card.Header>Whooops sorry!</Card.Header>
        <Card.Body>
          <ErrorMessage error={errorLoadingInvite} dismissable={false} />
          <p>{signInLink}</p>
        </Card.Body>
      </Card>
    }

    if (loadingInvite || !invite){
      return <Card className="BobSignupFromInviteForm">
        <Card.Header>Loading Invite</Card.Header>
        <Card.Body>
          <Loading type="block" />
        </Card.Body>
      </Card>
    }

    return <Card className="BobSignupFromInviteForm">
      <Card.Body>
        <InviteMessage invite={invite} />
        <ErrorMessage error={errorSigningUp} />
        { signupEmail
          ? <p>
            To continue creating your account, please check your email.
            A signup link has been sent to {signupEmail}.
          </p>
          : <SignupForm
            inviteToken={inviteToken}
            onSubmit={onSubmit}
            signingUp={signingUp}
          />
        }
        <div className="BobSignupFromInviteForm-bottomLinks">
          {signInLink}
          <Link
            href={'https://www.jlinc.com/technology'}
            type="link"
            newWindow
          >
            jlinc.com
          </Link>
        </div>
      </Card.Body>
    </Card>
  }
}

function InviteMessage({ invite: { invitingOrganization, invitedToNetwork } }){
  return <div className="BobSignupFromInviteForm-InviteMessage">
    <Header size="lg">You've been invited</Header>
    {invitingOrganization &&
      <p>
        <div className="BobSignupFromInviteForm-InviteMessage-by">by</div>
        <OrganizationIcon organization={invitingOrganization} brandingType="business" size="lg" />
        <Header size="md">{invitingOrganization.name}</Header>
      </p>
    }
    <p>to sign up on JLINC{invitedToNetwork && ' and join their Network'}</p>
  </div>
}

class SignupForm extends Component {

  componentDidMount(){
    this.focusEmailInput()
  }

  focusEmailInput(){
    if (this.emailInput) this.emailInput.focus()
  }

  state = {
    email: "",
  }

  onSubmit = () => {
    this.props.onSubmit({
      email: this.state.email,
      inviteToken: this.props.inviteToken,
    })
  }

  setEmail = email => {
    email = email.trim()
    this.setState({email})
  }

  render(){
    const { signingUp } = this.props
    const disabled = !!signingUp
    return <Form onSubmit={this.onSubmit}>
      <p>The first step to creating your organization is to verify your email address:</p>
      <Form.Item>
        <TextInput
          ref={textInput => { if (textInput) this.emailInput = textInput.input }}
          value={this.state.email}
          onInput={this.setEmail}
          placeholder="email"
          name="email"
          type="email"
          disabled={disabled}
          required
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          submit
          block
          disabled={disabled}
        >
          { signingUp ?
            <span><Spinner />&nbsp;Sending verification email…</span> :
            <span>Send verification email</span>
          }
        </Button>
      </Form.Item>
    </Form>
  }
}
