import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import { isInvalidPassword } from 'lib/validators'

import Header from 'components/Header'
import FormBuilder from 'components/FormBuilder'
import Button from 'components/Button'
import Card from 'components/Card'
import ErrorMessage from 'components/ErrorMessage'
import Checkbox from 'components/Checkbox'

import './index.sass'

export default class ResetPasswordForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    resetPasswordToken: PropTypes.string.isRequired,
    verifyingResetPasswordToken: PropTypes.bool,
    verifyingResetPasswordTokenError: ErrorMessage.propTypes.error,
    resetPasswordTokenVerified: PropTypes.bool,
    resetPassword: PropTypes.func.isRequired,
    passwordResetRequest: PropTypes.shape({
      pending: PropTypes.bool,
      success: PropTypes.bool,
      error: ErrorMessage.propTypes.error,
    }),
  }

  render(){
    return <Card
      className={`ResetPasswordForm ${this.props.className || ''}`}
    >
      <Card.Header size="lg" centered>Reset Password</Card.Header>
      <Card.Body>
        <Content {...this.props}/>
      </Card.Body>
    </Card>
  }
}

function Content({
  resetPasswordToken,
  verifyingResetPasswordTokenError,
  resetPasswordTokenVerified,
  resetPassword,
  passwordResetRequest,
}){

  if (verifyingResetPasswordTokenError){
    return <div>
      <Header size="md" centered>Whooops!</Header>
      {verifyingResetPasswordTokenError === 'invalid'
        ? <InvalidLinkMessage />
        : <ErrorMessage error={verifyingResetPasswordTokenError} />
      }
    </div>
  }

  if (passwordResetRequest && passwordResetRequest.success){
    return <SuccessMessage />
  }

  return <NewPasswordForm
    onSubmit={resetPassword}
    submitting={passwordResetRequest && passwordResetRequest.pending}
    error={passwordResetRequest && passwordResetRequest.error}
    resetPasswordToken={resetPasswordToken}
    verified={resetPasswordTokenVerified}
  />
}

function InvalidLinkMessage(){
  return <div>
    <p>Sorry! This password reset link is invalid</p>
    <Button
      block
      type="primary"
      href="/login"
      value="Login"
    />
  </div>
}

class NewPasswordForm extends Component {

  state = {
    password: '',
    passwordConfirmation: '',
    showPasswords: false,
  }

  onChange = ({ password, passwordConfirmation, showPasswords }) => {
    this.setState({ password, passwordConfirmation, showPasswords })
  }

  validator = ({ password, passwordConfirmation }) => {
    if (!password) return 'password is required'
    const invalidPasswordError = isInvalidPassword(password)
    if (invalidPasswordError) return invalidPasswordError
    if (!passwordConfirmation) return 'password is required'
    if (password !== passwordConfirmation) return 'passwords do not match'
  }

  render(){
    const { resetPasswordToken, error, submitting, onSubmit } = this.props
    const { password, passwordConfirmation, showPasswords } = this.state
    return <FormBuilder
      className="ResetPasswordForm-NewPasswordForm"
      error={error}
      value={{
        resetPasswordToken,
        password,
        passwordConfirmation,
        showPasswords,
      }}
      submitting={submitting}
      onChange={this.onChange}
      validator={this.validator}
      onReset={this.onReset}
      onSubmit={onSubmit}
      render={form =>
        <div>
          {form.passwordItem({
            type: showPasswords ? 'text' : 'password',
            valueProp: 'password',
            name: 'password',
            label: 'NEW PASSWORD',
            placeholder: 'New password',
            autocomplete: 'new-password',
            required: true,
            autoFocus: true,
          })}
          {form.passwordItem({
            type: showPasswords ? 'text' : 'password',
            valueProp: 'passwordConfirmation',
            name: 'password-confirmation',
            label: 'CONFIRM PASSWORD',
            placeholder: 'Confirm password',
            autocomplete: 'new-password',
            required: true,
          })}
          {form.item({
            valueProp: 'showPasswords',
            input: <Checkbox label="Show passwords" />
          })}
          <div className="ResetPasswordForm-NewPasswordForm-submitRow">
            {form.submitButton({
              value: 'Change password',
              submittingValue: 'Changing password…',
              fat: true,
            })}
          </div>
        </div>
      }
    />
  }
}

function SuccessMessage(){
  return <div className="ResetPasswordForm-success">
    <p><Header size="md">Password changed!</Header></p>
    <p>
      Your password has been successfully changed.
    </p>
    <Button
      block
      type="primary"
      href="/login"
      value="Login"
    />
  </div>
}
