import { h } from 'preact'
import PropTypes from 'prop-types'

import SwitchList from 'components/SwitchList'

const COMMUNICATION_CHANNELS = require('jlinc-shared/communication_channels')
const COMMUNICATION_CHANNEL_TITLES = require('jlinc-shared/communication_channel_titles')

export default function BobCommunicationChannels({channels, onChange, disabled, error}) {
  const content = COMMUNICATION_CHANNELS
    .map(key => {
      return {
        key,
        title: COMMUNICATION_CHANNEL_TITLES[key],
        checked: !!channels[key],
      }
    })

  return <SwitchList
    className="BobCommunicationChannels"
    content={content}
    onChange={(index, enabled) => onChange(content[index].key, enabled)}
    disabled={disabled}
    error={error}
  />
}

BobCommunicationChannels.propTypes = {
  channels: PropTypes.objectOf(PropTypes.bool).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}
