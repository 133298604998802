import api from '../api'

export default function({ email }) {
  const addingKey = `resendingEmailVerification:${email}`

  this.setState({
    [addingKey]: true,
    addingEmailError: undefined,
  })

  return api.addEmail({ email }).then(
    ({email, verified}) => {
      const emails = {...this.getState().emails, [email]: verified }
      this.setState({
        emails,
        [addingKey]: undefined,
      })
    },
    addingEmailError => {
      this.setState({
        [addingKey]: undefined,
        addingEmailError,
      })
    }
  )
}
