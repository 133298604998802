import { h } from 'preact'
import PropTypes from 'prop-types'

import Tooltip from 'components/Tooltip'
import './index.sass'

export default function RequiredStar({
  className = '',
  title = 'required',
  ...props
}){
  return <Tooltip text={title}>
    <span
      {...props}
      className={`RequiredStar ${className}`}
    />
  </Tooltip>
}

RequiredStar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
}
