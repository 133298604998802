import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Button from 'components/Button'
import Alert from 'components/Alert'

import './index.sass'

export default class BobEmailList extends Component {

  static propTypes = {
    className: PropTypes.string,
    currentDpoEmail: PropTypes.string,
    emails: PropTypes.object.isRequired,
    resendingEmailVerificationEmails: PropTypes.arrayOf(PropTypes.string.isRequired),
    resendEmail: PropTypes.func.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    const sendingEmailsLength = this.props.resendingEmailVerificationEmails.length
    if (sendingEmailsLength > 0 && nextProps.resendingEmailVerificationEmails.length < sendingEmailsLength) {
      this.setState({
        emailRecentlyAdded: this.props.resendingEmailVerificationEmails[0],
        email: ''
      })
      clearTimeout(this.emailRecentlyAddedTimeout)
      this.emailRecentlyAddedTimeout = setTimeout(() => { this.setState({ emailRecentlyAdded: false }) }, 5000)
    }
  }

  state = { emailRecentlyAdded: false }

  render() {
    const {emails, currentDpoEmail, className = '', resendEmail, resendingEmailVerificationEmails } = this.props

    const sortedEmails = Object.entries(emails)
      .sort(([aEmail, aVerified], [bEmail, bVerified]) => {
        if (aEmail === currentDpoEmail) return -1
        if (aVerified !== bVerified) return aVerified ? -1 : 1
        return aEmail < bEmail ? -1 : aEmail > bEmail ? 1 : 0
      })

    const emailList = sortedEmails.map(([email, verified]) => {
      const resending = resendingEmailVerificationEmails.includes(email)
      return <div className="BobEmailList-emails-email">
        <span>{email}</span>
        { !verified &&
          <span>
            <span className="BobEmailList-emails-email-label BobEmailList-emails-email-label-unverified">
              Unverified
            </span>
            <Button
              type="none"
              onClick={() => {
                resendEmail({email, reAdding: true})
              }}
              className="BobEmailList-emails-email-resendButton"
              disabled={resending}
            >
              {resending ? 'Sending...' : 'Resend email'}
            </Button>
          </span>
        }
        { email === currentDpoEmail &&
          <span className="BobEmailList-emails-email-label BobEmailList-emails-email-label-dpo">
            Current DPO email
          </span>
        }
      </div>
    })

    const successAlert = this.state.emailRecentlyAdded
      ? <Alert type="success">
        {`A verification link has been sent to ${this.state.emailRecentlyAdded}`}
      </Alert>
      : null

    return <div className={`BobEmailList ${className}`}>
      <Header size="sm">Your emails:</Header>
      { successAlert }
      <div className="BobEmailList-emails">
        {emailList}
      </div>
    </div>
  }
}
