import { h, Fragment, toChildArray, cloneElement } from 'preact'
import { useEffect, useMemo } from 'preact/hooks'
import useForceUpdate from 'lib/useForceUpdate'
import classNames from 'lib/classNames'
import createUUID from 'lib/createUUID'
import LazyLoad from 'components/LazyLoad'

export { toChildArray } from 'preact'

export function childrenOrFragmentToChildArray(children){
  children = toChildArray(children)
  return children.length === 1 && isFragment(children[0])
    ? toChildArray(children[0].props.children)
    : children
}

export function cloneElementAndAddClassName(element, className){
  return cloneElement(element, {
    className: (element.props.className || '') + ' ' + className,
  })
}

export function smartCloneElement(element, props){
  return cloneElement(element, {
    ...props,
    className: mergeClassNames(element.props.className, props.className),
  })
}

export function mergeClassNames(...classNames){
  return classNames
    .filter(className => typeof className === 'string')
    // TODO unique and maintain order
    .join(' ')
}

export function isFragment(node){
  return 'type' in node && node.type === Fragment
}

export function createClassComponent(componentClassName, Component = 'div'){
  function ClassComponent({ className, children, ...props }){
    return <Component {...{
      ...props,
      className: classNames(componentClassName, { className }),
    }}>{children}</Component>
  }
  ClassComponent.displayName = componentClassName
  return ClassComponent
}

export function createSharedState(initialValue){
  let value = typeof initialValue === 'function' ? initialValue() : initialValue
  let subscribers = new Set()
  function updateAll(){
    subscribers.forEach(forceUpdate => { forceUpdate() })
  }
  function setValue(newValue){
    value = newValue
    updateAll()
  }
  function useSharedState(){
    const forceUpdate = useForceUpdate()
    useEffect(
      () => {
        subscribers.add(forceUpdate)
        return () => {
          subscribers.delete(forceUpdate)
        }
      },
      [forceUpdate],
    )
    return [value, setValue]
  }
  useSharedState.get = () => value
  useSharedState.set = setValue
  useSharedState.use = useSharedState
  return useSharedState
}

export function lazyComponent(loader){
  const LazyComponent = props => <LazyLoad {...{loader, props}}/>
  LazyComponent.displayName = `LazyComponent(${loader})`
  return LazyComponent
}

export function refToDOMNode(ref){
  let node = ref && ref.current
  if (!node) return
  if (node instanceof window.HTMLElement) return node
  if (node.base instanceof window.HTMLElement) return node.base
}

export function useDOMID(args = []){
  return useMemo(createUUID, args)
}
