import moment from 'moment'
import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

export default function Timestamp({ className = '', time, format, ...props }){
  if (format in Timestamp.formats) format = Timestamp.formats[format]
  if (!time) return null
  const timeAsMoment = moment(time)
  const title = timeAsMoment.format(Timestamp.formats.full)
  const value = timeAsMoment.format(format)
  return <span {...{
    ...props,
    className: classNames('Timestamp', {className}),
    title,
  }}>{value}</span>
}

Timestamp.propTypes = {
  className: PropTypes.string,
  time: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]).isRequired,
  format: PropTypes.string.isRequired,
}

Timestamp.defaultProps = {
  format: 'MMM Do YYYY h:mmA',
}

Timestamp.formats = Object.freeze({
  date: 'MMM Do YYYY',
  time: 'h:mmA',
  full: 'dddd, MMMM Do YYYY, h:mm:ssa',
})
