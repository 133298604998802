import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import './index.sass'

export default function BannerImage({
  className,
  defaultSrc,
  src,
  style = {},
  ...props
}){
  if (!src) src = defaultSrc
  style.backgroundImage = src ? `url(${src})` : ''
  return <div {...{
    ...props,
    className: classNames('BannerImage', { className }),
    style,
  }}/>
}

BannerImage.propTypes = {
  className: PropTypes.string,
  defaultSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
}
