import api from '../api'

export function loadEndUserInvites({ page }){
  if (typeof page === 'undefined' || Number(page) < 1)
    throw new Error(`invites.loadEndUserInvites received bad page '${page}'`)

  const {
    [`loadingEndUserInvites:${page}`]: loadingEndUserInvites,
    [`endUserInvites:${page}`]: endUserInvitesPage
  } = this.getState()

  if (loadingEndUserInvites || endUserInvitesPage) return
  this.takeAction('invites.reloadEndUserInvites', { page })
}

export function reloadEndUserInvites({ page }){
  if (typeof page === 'undefined' || Number(page) < 1)
    throw new Error(`invites.reloadEndUserInvites received bad page '${page}'`)

  const loadingKey = `loadingEndUserInvites:${page}`
  const pageKey = `endUserInvites:${page}`
  const errorKey = `loadingEndUserInvitesError:${page}`

  const { [loadingKey]: loadingEndUserInvites } = this.getState()

  if (loadingEndUserInvites) return

  this.setState({
    [loadingKey]: true,
    [errorKey]: undefined
  })

  api.getEndUserInvites({ page }).then(
    ({endUserInvites}) => {
      this.setState({
        [loadingKey]: undefined,
        [pageKey]: endUserInvites,
      })
    },
    loadingEndUserInvitesError => {
      this.setState({
        [loadingKey]: undefined,
        [errorKey]: loadingEndUserInvitesError,
      })
    }
  )
}

export function deleteEndUserInvite({ inviteToken }){
  if (this.getState().deletingEndUserInvite) return

  this.setState({
    deletingEndUserInvite: inviteToken,
    errorDeletingEndUserInvite: undefined,
  })

  return api.deleteEndUserInvite({ inviteToken }).then(
    () => {
      this.setState({ deletingEndUserInvite: undefined })
      this.takeAction('invites.flushPagesCache')
    },
    errorDeletingEndUserInvite => {
      this.setState({
        errorDeletingEndUserInvite,
        deletingEndUserInvite: undefined
      })
    }
  )
}

export function flushPagesCache() {
  const pageKeys = Object.keys(this.getState()).filter(key => key.match(/^endUserInvites:.+$/))

  const newState = pageKeys.reduce((newState, key) => {
    newState[key] = undefined
    return newState
  }, {})

  this.setState(newState)
}

export function create({ email, mobile }) {
  if (this.getState().invitingEndUser) return

  this.setState({
    invitingEndUser: true,
    invitingEndUserError: undefined,
  })

  const endUserPersonalData = { mobilephone: mobile, email }

  api.inviteEndUser({endUserPersonalData}).then(
    signupUrl => {
      this.setState({
        invitingEndUser: undefined,
        invitedEndUserSignupUrl: signupUrl,
      })
      this.takeAction('invites.flushPagesCache')
    },
    invitingEndUserError => {
      this.setState({
        invitingEndUser: undefined,
        invitingEndUserError,
      })
    }
  )
}

export function resetEndUserInvite() {
  this.setState({
    invitingEndUser: undefined,
    invitingEndUserError: undefined,
    invitedEndUserSignupUrl: undefined,
  })
}

export function createOrganizationInvite({ invite_to_network }) {
  if (this.getState().creatingOrganizationInvite) return

  this.setState({
    creatingOrganizationInvite: true,
    errorCreatingOrganizationInvite: undefined,
  })

  api.createOrganizationInvite({ invite_to_network }).then(
    ({ invite }) => {
      const urlKey = invite_to_network ? 'networkInviteUrl' : 'organizationInviteUrl'
      this.setState({
        creatingOrganizationInvite: undefined,
        networkInviteUrl: undefined,
        [urlKey]: invite,
      })
    },
    errorCreatingOrganizationInvite => {
      this.setState({
        creatingOrganizationInvite: undefined,
        errorCreatingOrganizationInvite,
      })
    }
  )
}

export function resetOrganizationInvite({ invite_to_network }) {
  const urlKey = invite_to_network ? 'networkInviteUrl' : 'organizationInviteUrl'
  this.setState({
    creatingOrganizationInvite: undefined,
    errorCreatingOrganizationInvite: undefined,
    [urlKey]: undefined,
    networkInvite: undefined,
  })
}

export function getOrganizationInvite({ inviteToken }){
  if (this.getState().gettingOrganizationInvite) return

  this.setState({
    gettingOrganizationInvite: true,
    errorGettingOrganizationInvite: undefined,
  })

  api.getOrganizationInvite({ inviteToken }).then(
    ({ organizationAdminInvite }) => {
      this.setState({
        gettingOrganizationInvite: undefined,
        organizationInvite: {
          inviteToken,
          ...organizationAdminInvite,
        },
      })
    },
    errorGettingOrganizationInvite => {
      this.setState({
        gettingOrganizationInvite: undefined,
        errorGettingOrganizationInvite,
      })
    }
  )
}
