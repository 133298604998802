import { h } from 'preact'
import PropTypes from 'prop-types'
import './index.sass'

import { BRANDING_TYPES_PROP_TYPE } from 'lib/organizationProfileHelpers'
import { singleLetterIcon } from 'lib/imageHelpers'
import classNames from 'lib/classNames'

import DEFAULT from './default.svg'

export default function OrganizationIcon({
  className,
  organization,
  bordered,
  brandingType,
  size,
  ...props
}){
  let src
  if (!organization || !brandingType) {
    src = null
  }else{
    src = organization[`${brandingType}_icon`]
  }

  if (!src) src = singleLetterIcon(organization && (organization.name || organization.apikey) || '??')

  className = classNames(
    'OrganizationIcon',
    {
      bordered,
      className,
      [size]: 1,
    }
  )
  delete props.class // eslint-disable-line react/prop-types

  return <span className={className}>
    <img {...props} src={src || DEFAULT}/>
  </span>
}

OrganizationIcon.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  organization: PropTypes.object,
  bordered: PropTypes.bool,
  brandingType: BRANDING_TYPES_PROP_TYPE.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
}

OrganizationIcon.defaultProps = {
  brandingType: 'consumer',
  size: 'sm',
}

OrganizationIcon.DEFAULT = DEFAULT

