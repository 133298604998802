import api from '../api'

export function loadAll(){
  if (this.getState().organizations) return
  return this.takeAction('organizations.reloadAll')
}

export function reloadAll(){
  this.setState({
    loadingOrganizations: true,
  })
  return api.getAllOrganizations().then(
    organizations => {
      this.setState({
        loadingOrganizations: undefined,
        organizations,
      })
    },
    errorLoadingOrganizations => {
      this.setState({
        loadingOrganizations: undefined,
        errorLoadingOrganizations,
      })
    }
  )
}
