/* eslint-disable max-len */
import { h } from 'preact'

export default {
  create: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M36.075,24a2.5,2.5,0,0,1-2.5,2.5H26.5v7.071a2.5,2.5,0,0,1-5,0V26.5h-7.07a2.5,2.5,0,0,1,0-5H21.5V14.433a2.5,2.5,0,1,1,5,0V21.5h7.071A2.5,2.5,0,0,1,36.075,24ZM48,24A24,24,0,1,1,24,0,23.993,23.993,0,0,1,48,24Zm-5,0A19,19,0,1,0,24,43,19.021,19.021,0,0,0,43,24Z" fill="#231f20"/>
    </svg>
  ),
  home: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.38 45">
      <path d="M44.673,20.061H41.917V42.575A2.425,2.425,0,0,1,39.492,45H29.123V30.29H18.252V45H7.89a2.425,2.425,0,0,1-2.424-2.425V20.061H2.706a2.7,2.7,0,0,1-1.52-4.934l22-14.971a.9.9,0,0,1,1.013,0l22,14.971A2.7,2.7,0,0,1,44.673,20.061Z" fill="#231f20"/>
    </svg>
  ),
  hubs: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.022 43.499">
      <path d="M16.08,5.448a2.711,2.711,0,0,1,2.712-2.713H44.309a2.713,2.713,0,1,1,0,5.425H18.792A2.712,2.712,0,0,1,16.08,5.448Zm0,16.316a2.711,2.711,0,0,1,2.712-2.712H44.309a2.713,2.713,0,1,1,0,5.425H18.792A2.712,2.712,0,0,1,16.08,21.764Zm0,16.288a2.711,2.711,0,0,1,2.712-2.713H44.309a2.713,2.713,0,1,1,0,5.425H18.792A2.712,2.712,0,0,1,16.08,38.052ZM5.448,16.317A5.448,5.448,0,1,0,10.9,21.764,5.448,5.448,0,0,0,5.448,16.317Zm0,16.287A5.448,5.448,0,1,0,10.9,38.052,5.448,5.448,0,0,0,5.448,32.6Zm0-32.6A5.448,5.448,0,1,0,10.9,5.448,5.448,5.448,0,0,0,5.448,0Z" fill="#231f20"/>
    </svg>
  ),
  messages: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.75 45.114">
      <path d="M41.37,5.59H38.84A9.962,9.962,0,0,0,29.89,0H9.98A10,10,0,0,0,0,9.98V23.26a9.994,9.994,0,0,0,5.69,9L4.64,35.71a4.975,4.975,0,0,0,2.1,5.67,4.913,4.913,0,0,0,2.68.78,4.981,4.981,0,0,0,3.35-1.28l4.6-4.15H29.09a.558.558,0,0,1,.22.03c.09.03.14.05.18.06l10.342,7.974a1.238,1.238,0,0,0,1.49.13,1.248,1.248,0,0,0,.52-1.41L40.58,37.4a.83.83,0,0,1,.79-.67,7.379,7.379,0,0,0,7.38-7.37V12.97A7.375,7.375,0,0,0,41.37,5.59ZM11.94,34.88,9.42,37.16l1.17-3.85,1.04-3.42a1.66,1.66,0,0,0-1.65-1.66,3.313,3.313,0,0,1-.55-.04A4.956,4.956,0,0,1,5,23.26V9.98A4.987,4.987,0,0,1,9.98,5H29.89a4.905,4.905,0,0,1,2.35.59,5,5,0,0,1,2.62,4.39V23.26a4.976,4.976,0,0,1-4.97,4.97H19.93a1.521,1.521,0,0,0-.47.07,1.583,1.583,0,0,0-.45.21Z" fill="#231f20"/>
    </svg>
  ),
  notifications: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.979 47.23">
      <path d="M23.491,5h0A14.565,14.565,0,0,1,38.069,19.54v6.88A12.372,12.372,0,0,0,41.593,35.2a1.188,1.188,0,0,1,.386.865,1.281,1.281,0,0,1-1.28,1.28h-9.23a5,5,0,0,0-4.448,2.716,3.965,3.965,0,0,1-7.075,0A5,5,0,0,0,15.5,37.34H6.269A1.267,1.267,0,0,1,5.1,36.562a1.251,1.251,0,0,1,.285-1.375A11.914,11.914,0,0,0,8.9,26.86V19.74A14.584,14.584,0,0,1,22.075,5.068c.47-.045.946-.068,1.416-.068m0-5Q22.548,0,21.6.09A19.556,19.556,0,0,0,3.9,19.74v7.12a6.934,6.934,0,0,1-2.09,4.83,6.255,6.255,0,0,0,4.46,10.65H15.5a8.964,8.964,0,0,0,15.97,0H40.7a6.284,6.284,0,0,0,6.28-6.28,6.178,6.178,0,0,0-1.88-4.43,7.339,7.339,0,0,1-2.03-5.21V19.54A19.563,19.563,0,0,0,23.49,0Z" fill="#231f20"/>
    </svg>
  ),
  shareplane: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.713 45.296">
      <path d="M6.445,45.3a6.122,6.122,0,0,1-4.926-2.253A9.806,9.806,0,0,1,.339,35.2l2.794-9.781a2.33,2.33,0,0,1,1.9-1.667l5.525-.837c-6.531-1.029-6.872-1.738-7.287-2.605a2.309,2.309,0,0,1-.208-.716L.339,10.065A9.8,9.8,0,0,1,1.518,2.221C3.11.1,6.683-.631,9.382.594L42.1,15.464a8.326,8.326,0,0,1,4.612,7.166A8.327,8.327,0,0,1,42.1,29.8L9.381,44.667A7.151,7.151,0,0,1,6.445,45.3Zm.767-17.155L4.83,36.478a5.3,5.3,0,0,0,.427,3.761,2.427,2.427,0,0,0,2.19.176l32.719-14.87a3.766,3.766,0,0,0,1.875-2.915,3.768,3.768,0,0,0-1.875-2.915L7.449,4.846a2.429,2.429,0,0,0-2.194.178A5.3,5.3,0,0,0,4.83,8.783l2.511,8.791c2.96.7,12.438,1.884,20.919,2.732a2.335,2.335,0,0,1,.116,4.633Z" fill="#231f20"/>
    </svg>
  ),
  singlecomment: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.86 42.16">
      <g>
        <path d="M9.98,5a4.889,4.889,0,0,0-1.49.23,5.2,5.2,0,0,1,1.48-.22h9.96A1.623,1.623,0,0,0,20.14,5Zm-1.49.23a4.551,4.551,0,0,0-.88.37A6.815,6.815,0,0,1,8.49,5.23ZM5.69,32.27v-.01a11.028,11.028,0,0,1-1.21-.68A9,9,0,0,0,5.69,32.27ZM32.24,5.59a1.072,1.072,0,0,0-.22-.1c.16.08.32.17.47.26C32.41,5.69,32.32,5.64,32.24,5.59Zm-.22-.1a4.1,4.1,0,0,0-1.59-.44A5,5,0,0,1,32.02,5.49ZM29.77,5a.375.375,0,0,0,.11.01,2.62,2.62,0,0,1,.28.01A1.229,1.229,0,0,0,29.89,5Z" fill="#231f20"/>
        <path d="M38.63,5.19c-.11-.2-.23-.4-.36-.59s-.26-.39-.39-.57a11.313,11.313,0,0,0-.9-1.05c-.17-.16-.33-.32-.51-.48a11.262,11.262,0,0,0-1.09-.84A11.028,11.028,0,0,0,34.17.98V.97A9.881,9.881,0,0,0,29.89,0H9.98A10,10,0,0,0,0,9.98V23.26a9.809,9.809,0,0,0,1.02,4.39c.07.14.14.27.21.4.11.2.23.4.36.59s.26.39.39.57a11.313,11.313,0,0,0,.9,1.05c.17.16.33.32.51.48a11.262,11.262,0,0,0,1.09.84,11.028,11.028,0,0,0,1.21.68v.01L4.64,35.71a4.975,4.975,0,0,0,2.1,5.67,4.913,4.913,0,0,0,2.68.78,4.981,4.981,0,0,0,3.35-1.28l8.28-7.64h8.83a10,10,0,0,0,9.98-9.98V9.98A9.835,9.835,0,0,0,38.63,5.19ZM34.86,23.26a4.91,4.91,0,0,1-1.46,3.51,4.619,4.619,0,0,1-1.14.86.01.01,0,0,1-.01.01,4.551,4.551,0,0,1-.88.37,4.889,4.889,0,0,1-1.49.23H19.72a1.378,1.378,0,0,0-.26.06,1.583,1.583,0,0,0-.45.21l-5.25,4.73-1.82,1.64L9.42,37.16l1.17-3.85.02-.07,1.02-3.35a1.652,1.652,0,0,0-1.54-1.65H9.97a1.229,1.229,0,0,1-.27-.02c-.05,0-.11-.01-.16-.01a4.427,4.427,0,0,1-1.7-.46,1.072,1.072,0,0,1-.22-.1c-.08-.05-.17-.1-.25-.16a.551.551,0,0,1-.14-.09,1.941,1.941,0,0,1-.29-.21,2.644,2.644,0,0,1-.41-.34,4.7,4.7,0,0,1-.42-.47,4.538,4.538,0,0,1-.36-.5,1.74,1.74,0,0,1-.18-.33l-.15-.3c-.04-.1-.08-.2-.12-.31A4.679,4.679,0,0,1,5,23.26V9.98A4.951,4.951,0,0,1,6.46,6.47,5.024,5.024,0,0,1,7.6,5.61a.01.01,0,0,0,.01-.01,4.551,4.551,0,0,1,.88-.37A4.889,4.889,0,0,1,9.98,5H29.89a1.229,1.229,0,0,1,.27.02,2.477,2.477,0,0,1,.27.03,4.1,4.1,0,0,1,1.59.44,1.072,1.072,0,0,1,.22.1c.08.05.17.1.25.16.05.03.1.06.14.09a1.941,1.941,0,0,1,.29.21,4.624,4.624,0,0,1,.41.34,6.142,6.142,0,0,1,.42.47,4.2,4.2,0,0,1,.36.5,2.685,2.685,0,0,1,.18.33,2.1,2.1,0,0,1,.15.3,1.978,1.978,0,0,1,.12.31,4.718,4.718,0,0,1,.3,1.68Z" fill="#231f20"/>
      </g>
    </svg>
  ),
  truline: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.548 45.548">
      <path d="M31.748,4.823h8.977v4.49A4.486,4.486,0,0,1,36.239,13.8H27.262V40.725H22.771a4.486,4.486,0,0,1-4.485-4.488V13.8H4.823V9.308A4.486,4.486,0,0,1,9.309,4.823H22.777A4.487,4.487,0,0,1,27.262,9.31v0a4.486,4.486,0,0,1,4.486-4.485M40.725,0H31.75a9.259,9.259,0,0,0-4.482,1.151A9.255,9.255,0,0,0,22.778,0H9.31A9.32,9.32,0,0,0,0,9.306V13.8a4.823,4.823,0,0,0,4.823,4.823h8.64V36.237a9.321,9.321,0,0,0,9.307,9.311h4.492a4.822,4.822,0,0,0,4.822-4.823v-22.1h4.153a9.321,9.321,0,0,0,9.311-9.306V4.823A4.823,4.823,0,0,0,40.725,0Z" fill="#231f20"/>
    </svg>
  ),
  subgroup: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 39.992">
      <g>
        <path d="M3.68,13.123a1.638,1.638,0,0,0-.07.32,1.758,1.758,0,0,1,.13-.32Zm36.28,5.7a18.319,18.319,0,0,0-.48-3.331,19.548,19.548,0,0,0-4.72-8.969,5.569,5.569,0,0,1-.77,2.19A5.379,5.379,0,0,1,38.44,14v4.82H1.56V14a5.389,5.389,0,0,1,4.45-5.29A5.78,5.78,0,0,1,5.23,6.5,19.859,19.859,0,0,0,.04,18.823c-.03.39-.04.78-.04,1.169a20,20,0,0,0,40,0C40,19.6,39.99,19.213,39.96,18.823ZM5.65,30.293a17.571,17.571,0,0,1-3.27-9.12H9.47a46.529,46.529,0,0,0,.77,7.38A32.737,32.737,0,0,0,5.65,30.293ZM7.2,32.162a30.937,30.937,0,0,1,3.54-1.309,44.637,44.637,0,0,0,1.52,5.02A17.7,17.7,0,0,1,7.2,32.162Zm11.63,5.451A18.162,18.162,0,0,1,15.22,37,44.047,44.047,0,0,1,13,30.253a32.287,32.287,0,0,1,5.83-.74Zm0-10.451a33.939,33.939,0,0,0-6.31.8,43.924,43.924,0,0,1-.71-6.79h7.02ZM24.77,37a17.942,17.942,0,0,1-3.6.61v-8.1a32.178,32.178,0,0,1,5.82.74A42.984,42.984,0,0,1,24.77,37Zm-3.6-9.841V21.173h7.01a43.915,43.915,0,0,1-.7,6.79A34.046,34.046,0,0,0,21.17,27.162Zm6.57,8.711a46.813,46.813,0,0,0,1.52-5.02,30.107,30.107,0,0,1,3.53,1.309A17.653,17.653,0,0,1,27.74,35.873Zm6.61-5.58a32.4,32.4,0,0,0-4.6-1.74,45.4,45.4,0,0,0,.78-7.38h7.09A17.571,17.571,0,0,1,34.35,30.293Z" fill="#231f20"/>
        <g>
          <path d="M20,0a4.247,4.247,0,1,0,4.246,4.246A4.25,4.25,0,0,0,20,0Z" fill="#231f20"/>
          <path d="M24.524,9.667H15.482a3.779,3.779,0,0,0-3.775,3.775v5.386H28.3V13.442A3.779,3.779,0,0,0,24.524,9.667Z" fill="#231f20"/>
          <path d="M11,1.962a3.8,3.8,0,0,0-.051,7.606,5.972,5.972,0,0,1,3.391-1.985A3.8,3.8,0,0,0,11,1.962Z" fill="#231f20"/>
          <path d="M6.945,10.621A3.386,3.386,0,0,0,3.563,14v4.825H9.512V13.442a5.929,5.929,0,0,1,.71-2.821Z" fill="#231f20"/>
          <path d="M29.011,1.962A3.8,3.8,0,0,0,25.67,7.583a5.969,5.969,0,0,1,3.391,1.985,3.8,3.8,0,0,0-.05-7.606Z" fill="#231f20"/>
          <path d="M33.061,10.621H29.784a5.929,5.929,0,0,1,.71,2.821v5.386h5.949V14A3.386,3.386,0,0,0,33.061,10.621Z" fill="#231f20"/>
        </g>
        <g>
          <g>
            <path d="M18.385,8.167h.45v.16A2.181,2.181,0,0,1,18.385,8.167Z" fill="#231f20"/>
            <path d="M21.625,8.167a2.181,2.181,0,0,1-.45.16v-.16Z" fill="#231f20"/>
          </g>
          <g>
            <path d="M3.685,13.127h.06a1.788,1.788,0,0,0-.13.32A1.654,1.654,0,0,1,3.685,13.127Z" fill="#231f20"/>
            <path d="M9.515,17.9v.93h-.04C9.485,18.517,9.495,18.207,9.515,17.9Z" fill="#231f20"/>
          </g>
          <path d="M29.355,9.117l-.09.03a.037.037,0,0,0-.01-.03Z" fill="#231f20"/>
          <g>
            <path d="M30.5,17.9c.02.31.03.62.04.93H30.5Z" fill="#231f20"/>
            <path d="M36.4,13.447a15.322,15.322,0,0,0-.72-1.6A3.391,3.391,0,0,1,36.4,13.447Z" fill="#231f20"/>
          </g>
        </g>
        <path d="M3.685,13.127h.06a1.788,1.788,0,0,0-.13.32A1.654,1.654,0,0,1,3.685,13.127Z" fill="#231f20"/>
        <path d="M9.475,18.827c.01-.31.02-.62.04-.93v.93Z" fill="#231f20"/>
        <path d="M30.5,17.9c.02.31.03.62.04.93H30.5Z" fill="#231f20"/>
        <path d="M3.74,13.123a1.758,1.758,0,0,0-.13.32,1.638,1.638,0,0,1,.07-.32Z" fill="#231f20"/>
      </g>
    </svg>
  ),
  publicgroup: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g>
        <circle cx="20" cy="4.589" r="4.589" fill="#231f20"/>
        <path d="M25.6,11.068H14.4a3.271,3.271,0,0,0-3.272,3.271V26.56a1.911,1.911,0,0,0,3.822,0V40h3.88V27.694h2.344V40h3.88V26.56a1.911,1.911,0,0,0,3.822,0V14.339A3.271,3.271,0,0,0,25.6,11.068Z" fill="#231f20"/>
        <circle cx="8.27" cy="6.998" r="4.277" fill="#231f20"/>
        <path d="M8.783,26.56V14.339c0-.188.009-.373.027-.556H3.049A3.049,3.049,0,0,0,0,16.832v11.39a1.781,1.781,0,0,0,3.562,0V40H7.1V28.839H9.442V40h3.163V30.793A4.261,4.261,0,0,1,8.783,26.56Z" fill="#231f20"/>
        <circle cx="31.73" cy="6.998" r="4.277" fill="#231f20"/>
        <path d="M36.951,13.783H31.19c.018.183.027.368.027.556V26.56A4.261,4.261,0,0,1,27.4,30.793V40h3.163V28.839H32.9V40h3.536V28.222a1.781,1.781,0,0,0,3.562,0V16.832A3.049,3.049,0,0,0,36.951,13.783Z" fill="#231f20"/>
      </g>
    </svg>
  ),
  privatesubgroup: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g>
        <path d="M33.3,21.677a13.227,13.227,0,0,1-2.41,6.21,24.856,24.856,0,0,0-3.48-1.32,33.266,33.266,0,0,0,.55-4.89h-1.78a31.259,31.259,0,0,1-.5,4.44c-.37-.08-.75-.16-1.13-.22a5.136,5.136,0,0,1-.9,1.22,4.745,4.745,0,0,1,3.31,1.46,2.681,2.681,0,0,0,.07-.27,24.2,24.2,0,0,1,2.69,1,14.92,14.92,0,0,1-1.57,1.41,4.578,4.578,0,0,1,.15,1.17v.89a15.123,15.123,0,0,0,6.79-11.1Zm-8.4-9.32a23.255,23.255,0,0,1-4.01.49V6.7a13.187,13.187,0,0,1,2.74.46A33.274,33.274,0,0,1,25,11.087a4.779,4.779,0,0,1,1.17-2.25c-.09-.27-.19-.55-.29-.82.25.12.5.25.74.39a4.642,4.642,0,0,1,1.85-.94,15.193,15.193,0,0,0-16.94,0,4.617,4.617,0,0,1,1.86.94c.24-.14.49-.27.74-.39-.1.28-.2.55-.29.83A4.684,4.684,0,0,1,15,11.087a33.274,33.274,0,0,1,1.37-3.93,13.3,13.3,0,0,1,2.74-.46v6.15a23.255,23.255,0,0,1-4.01-.49v1.81a25.377,25.377,0,0,0,4.01.46v3.78a4.446,4.446,0,0,1,1.78,0v-3.78a25.377,25.377,0,0,0,4.01-.46Zm-.4,8.6h.4v-1.78h-2.07A5.007,5.007,0,0,1,24.5,20.957Zm-9.4,0h.4a5.092,5.092,0,0,1,1.67-1.78H15.1Zm-.78,5.16a31.259,31.259,0,0,1-.5-4.44h-1.78a31.974,31.974,0,0,0,.56,4.89,24.514,24.514,0,0,0-3.49,1.32,13.227,13.227,0,0,1-2.41-6.21H4.909a15.123,15.123,0,0,0,6.79,11.1v-.89a4.6,4.6,0,0,1,.15-1.18,12.185,12.185,0,0,1-1.56-1.4,23.325,23.325,0,0,1,2.68-1,2.681,2.681,0,0,0,.07.27,4.74,4.74,0,0,1,3.31-1.45,5.033,5.033,0,0,1-.91-1.23C15.059,25.957,14.689,26.037,14.319,26.117Z" fill="#231f20"/>
        <g>
          <path d="M23.534,28.617H16.466A3.269,3.269,0,0,0,13.2,31.882V40H26.8V31.882A3.269,3.269,0,0,0,23.534,28.617Z" fill="#231f20"/>
          <path d="M6.8,0A3.661,3.661,0,1,0,10.46,3.661,3.665,3.665,0,0,0,6.8,0Z" fill="#231f20"/>
          <path d="M10.333,8.8H3.265A3.268,3.268,0,0,0,0,12.062V20.18H13.6V12.062A3.268,3.268,0,0,0,10.333,8.8Z" fill="#231f20"/>
          <path d="M33.2,0a3.661,3.661,0,1,0,3.661,3.661A3.665,3.665,0,0,0,33.2,0Z" fill="#231f20"/>
          <path d="M20,19.82a3.661,3.661,0,1,0,3.661,3.661A3.665,3.665,0,0,0,20,19.82Z" fill="#231f20"/>
          <path d="M36.735,8.8H29.667A3.268,3.268,0,0,0,26.4,12.062V20.18H40V12.062A3.268,3.268,0,0,0,36.735,8.8Z" fill="#231f20"/>
        </g>
      </g>
    </svg>
  ),
  org: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 39.996">
      <g>
        <path d="M35.58,32a3.179,3.179,0,0,1-.06.62,20,20,0,1,0-31.03.01A3.207,3.207,0,0,1,4.43,32V28.31a17.356,17.356,0,0,1-2.04-7.14h3.5a4.077,4.077,0,0,1,2.05-.91,5.189,5.189,0,0,1-.64-1.44H2.39A17.4,17.4,0,0,1,5.66,9.7a32.273,32.273,0,0,0,4.59,1.74c-.11.58-.2,1.15-.28,1.73a4.658,4.658,0,0,1,2.07-.47,2.3,2.3,0,0,1,.37.02c.03-.23.07-.46.11-.69a31.408,31.408,0,0,0,3.62.6,5.086,5.086,0,0,1,7.73,0,31.408,31.408,0,0,0,3.62-.6c.04.23.08.46.11.69a2.3,2.3,0,0,1,.37-.02,4.809,4.809,0,0,1,2.08.47c-.09-.58-.18-1.15-.29-1.73A32.273,32.273,0,0,0,34.35,9.7a17.4,17.4,0,0,1,3.27,9.12H32.71a5.189,5.189,0,0,1-.64,1.44,4.077,4.077,0,0,1,2.05.91h3.5a17.357,17.357,0,0,1-2.04,7.15ZM10.74,9.14A30.607,30.607,0,0,1,7.21,7.83a17.539,17.539,0,0,1,5.06-3.71A44.628,44.628,0,0,0,10.74,9.14Zm8.09,1.34a32.178,32.178,0,0,1-5.82-.74,42.984,42.984,0,0,1,2.22-6.75,17.373,17.373,0,0,1,3.6-.61Zm2.35,0V2.38a17.373,17.373,0,0,1,3.6.61A42.984,42.984,0,0,1,27,9.74,32.178,32.178,0,0,1,21.18,10.48Zm8.09-1.34a44.628,44.628,0,0,0-1.53-5.02A17.539,17.539,0,0,1,32.8,7.83,30.607,30.607,0,0,1,29.27,9.14Z" fill="#231f20"/>
        <g>
          <circle cx="20.005" cy="15.967" r="3.114" fill="#231f20"/>
          <path d="M23.807,20.363H16.2a2.22,2.22,0,0,0-2.22,2.22v8.293a1.3,1.3,0,0,0,2.594,0V40H19.21V31.645H20.8V40h2.633v-9.12a1.3,1.3,0,1,0,2.593,0V22.583A2.219,2.219,0,0,0,23.807,20.363Z" fill="#231f20"/>
          <circle cx="12.045" cy="17.602" r="2.902" fill="#231f20"/>
          <path d="M12.393,30.876V22.583c0-.128.007-.253.019-.377H8.5a2.068,2.068,0,0,0-2.069,2.068V32A1.209,1.209,0,1,0,8.85,32V40h2.4V32.423h1.59V40h2.147V33.748A2.89,2.89,0,0,1,12.393,30.876Z" fill="#231f20"/>
          <circle cx="27.965" cy="17.602" r="2.902" fill="#231f20"/>
          <path d="M31.508,22.206H27.6c.012.124.019.249.019.377v8.293a2.89,2.89,0,0,1-2.594,2.872V40H27.17V32.423h1.59V40h2.4V32a1.209,1.209,0,1,0,2.417,0V24.274A2.069,2.069,0,0,0,31.508,22.206Z" fill="#231f20"/>
        </g>
      </g>
    </svg>
  ),
  friendsfamily: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.42 40">
      <g>
        <path d="M8.449,3.48a4.5,4.5,0,1,0,4.5,4.5A4.508,4.508,0,0,0,8.449,3.48Z" fill="#231f20"/>
        <path d="M36.533,14.083H28.76L24.133,6.668a2.767,2.767,0,0,0-3.8-.883c-.041.025-.081.052-.12.079-.039-.027-.079-.054-.12-.079a2.766,2.766,0,0,0-3.8.883l-4.628,7.415H3.886A3.89,3.89,0,0,0,0,17.969V28.064a2.765,2.765,0,0,0,2.763,2.762,2.7,2.7,0,0,0,.394-.028V40H7.264V28.81H9.633V40H13.74V21.2l6.47-10.377L26.68,21.2V40h4.107V28.81h2.368V40h4.108V30.8a2.7,2.7,0,0,0,.394.028,2.766,2.766,0,0,0,2.763-2.762V17.969A3.891,3.891,0,0,0,36.533,14.083Z" fill="#231f20"/>
        <path d="M31.971,3.48a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,31.971,3.48Z" fill="#231f20"/>
        <rect x="19.026" width="2.368" height="3.733" fill="#231f20"/>
        <rect x="14.856" y="0.757" width="2.368" height="3.733" transform="translate(0.363 6.914) rotate(-24.594)" fill="#231f20"/>
        <rect x="22.514" y="1.438" width="3.733" height="2.368" transform="translate(11.849 23.7) rotate(-65.406)" fill="#231f20"/>
      </g>
    </svg>
  ),
  blogpublish: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g>
        <path d="M0,0V10.262H1.549l3.679,4.26v-4.26h10.85V0ZM13.568,6.3H2.51V3.959H13.568Z" fill="#231f20"/>
        <path d="M22.447,7.876a4.734,4.734,0,1,0,4.734,4.734A4.739,4.739,0,0,0,22.447,7.876Z" fill="#231f20"/>
        <path d="M27.274,19.052H17.62a4.036,4.036,0,0,0-4.031,4.031v4.651H31.305V23.083A4.036,4.036,0,0,0,27.274,19.052Z" fill="#231f20"/>
        <polygon points="4.893 30.078 4.893 35.696 6.742 35.696 6.742 40 38.152 40 38.152 35.696 40 35.696 40 30.078 4.893 30.078" fill="#231f20"/>
      </g>
    </svg>

  ),
  privategroup: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g>
        <path d="M23.534,28.617H16.466A3.269,3.269,0,0,0,13.2,31.882V40H26.8V31.882A3.269,3.269,0,0,0,23.534,28.617Z" fill="#231f20"/>
        <path d="M6.8,0A3.661,3.661,0,1,0,10.46,3.661,3.665,3.665,0,0,0,6.8,0Z" fill="#231f20"/>
        <path d="M10.333,8.8H3.265A3.268,3.268,0,0,0,0,12.062V20.18H13.6V12.062A3.268,3.268,0,0,0,10.333,8.8Z" fill="#231f20"/>
        <path d="M33.2,0a3.661,3.661,0,1,0,3.661,3.661A3.665,3.665,0,0,0,33.2,0Z" fill="#231f20"/>
        <path d="M20,19.82a3.661,3.661,0,1,0,3.661,3.661A3.665,3.665,0,0,0,20,19.82Z" fill="#231f20"/>
        <path d="M36.735,8.8H29.667A3.268,3.268,0,0,0,26.4,12.062V20.18H40V12.062A3.268,3.268,0,0,0,36.735,8.8Z" fill="#231f20"/>
        <path d="M7.481,22.523H5.1a15.145,15.145,0,0,0,5.761,9.515v-.156a5.57,5.57,0,0,1,.554-2.429A12.807,12.807,0,0,1,7.481,22.523Z" fill="#231f20"/>
        <path d="M20,4.884a15.042,15.042,0,0,0-7.518,2A5.626,5.626,0,0,1,14.6,8.423a12.816,12.816,0,0,1,10.808,0,5.626,5.626,0,0,1,2.114-1.541A15.042,15.042,0,0,0,20,4.884Z" fill="#231f20"/>
        <path d="M34.41,22.523H32.52a12.8,12.8,0,0,1-3.931,6.93,5.576,5.576,0,0,1,.554,2.429v.158a15.149,15.149,0,0,0,5.76-9.517Z" fill="#231f20"/>
      </g>
    </svg>
  ),
  eboard: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g>
        <path d="M0,0V12.628H5.264L9.1,16.381V12.628h8.348L20,16.381l2.551-3.753H30.9v3.753l3.837-3.753H40V0ZM11.6,7.481H7.283V5.137H11.6Zm10.559,0H17.843V5.137h4.314Zm10.56,0H28.4V5.137h4.315Z" fill="#231f20"/>
        <path d="M20,19.9a4.534,4.534,0,1,0,4.534,4.534A4.539,4.539,0,0,0,20,19.9Z" fill="#231f20"/>
        <path d="M24.827,30.218H15.173a4.035,4.035,0,0,0-4.031,4.031V40H28.858V34.249A4.035,4.035,0,0,0,24.827,30.218Z" fill="#231f20"/>
        <path d="M10.382,21.991a4.061,4.061,0,0,0-.054,8.122,6.369,6.369,0,0,1,3.621-2.119,4.062,4.062,0,0,0-3.567-6Z" fill="#231f20"/>
        <path d="M6.058,31.237a3.615,3.615,0,0,0-3.611,3.611V40H8.8V34.249a6.339,6.339,0,0,1,.758-3.012Z" fill="#231f20"/>
        <path d="M29.618,21.991a4.062,4.062,0,0,0-3.567,6,6.369,6.369,0,0,1,3.621,2.119,4.061,4.061,0,0,0-.054-8.122Z" fill="#231f20"/>
        <path d="M33.942,31.237h-3.5a6.339,6.339,0,0,1,.758,3.012V40h6.351V34.848A3.615,3.615,0,0,0,33.942,31.237Z" fill="#231f20"/>
      </g>
    </svg>
  ),
  megaphone: (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 488.5 488.5" style="enable-background:new 0 0 488.5 488.5;" >
      <g>
        <g>
          <path d="M405.1,210.95v0.4c0,7.5,6.1,13.7,13.7,13.7h56c7.5,0,13.7-6.1,13.7-13.7v-0.4c0-7.5-6.1-13.7-13.7-13.7h-56
            C411.2,197.25,405.1,203.35,405.1,210.95z"/>
          <path d="M406.2,270.75l53,21.1c7.4,2.9,15.6-1,18.1-8.5c2.2-6.8-1.3-14.2-8-16.9l-53-21.1c-7.4-2.9-15.6,1-18.1,8.5
            C396.1,260.75,399.5,268.15,406.2,270.75z"/>
          <path d="M151.8,132.15H56.3c-19,0-34.4,15.4-34.4,34.4v15.3H11.5c-6.4,0-11.5,5.2-11.5,11.5v43.1c0,6.4,5.2,11.5,11.5,11.5h10.4
            v13.2c0,19,15.4,34.4,34.4,34.4h8.6l39.3,130.8l5.9-1.4l19.9-4.7l5.9-1.4l-15.6-123.3h31.5v-14.3c41.8,6.8,110.8,24.5,159,70.2
            c0.5,8.5,8,15.1,16.9,14.1c7.7-0.9,13.3-8,13.3-15.8v-88.5c19.4-7.2,33.2-25.9,33.2-47.7c0-21.9-13.8-40.5-33.2-47.7v-87.8
            c0-7.8-5.5-14.9-13.3-15.8c-8.9-1-16.3,5.6-16.9,14.1c-48.2,45.7-117.2,63.4-159,70.2L151.8,132.15L151.8,132.15z"/>
          <path d="M459.3,130.45l-53,21.1c-6.7,2.7-10.2,10-8,16.9c2.4,7.5,10.7,11.5,18.1,8.5l53-21.1c6.7-2.7,10.2-10,8-16.9
            C474.9,131.45,466.6,127.55,459.3,130.45z"/>
        </g>
      </g><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g />
    </svg>
  )
}
