import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import { organizationProfileShape, BRANDING_TYPES_PROP_TYPE } from 'lib/organizationProfileHelpers'
import filterObject from 'lib/filterObject'

import Form from 'components/Form'
import FormBuilder from 'components/FormBuilder'
import TagsInput from 'components/TagsInput'
import ErrorMessage from 'components/ErrorMessage'

export default class BobMarketplaceTagsForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    marketplace: BRANDING_TYPES_PROP_TYPE.isRequired,
    organizationProfile: PropTypes.shape(organizationProfileShape).isRequired,
    organizationProfileChanges: PropTypes.shape(organizationProfileShape),
    stageOrganizationProfileChanges: PropTypes.func.isRequired,
    updateOrganizationProfile: PropTypes.func.isRequired,
    updatingOrganizationProfile: PropTypes.bool,
    errorUpdatingOrganizationProfile: ErrorMessage.propTypes.error,
  }

  render(){
    const keys = [
      `${this.props.marketplace}_marketplace_industries`,
      `${this.props.marketplace}_marketplace_brands`,
      `${this.props.marketplace}_marketplace_tags`,
    ]
    return <FormBuilder
      className={`BobMarketplaceTagsForm ${this.props.className || ''}`}
      error={this.props.errorUpdatingOrganizationProfile}
      value={filterObject(this.props.organizationProfileChanges, keys)}
      persistedValues={filterObject(this.props.organizationProfile, keys)}
      submitting={!!this.props.updatingOrganizationProfile}
      onChange={this.props.stageOrganizationProfileChanges}
      onSubmit={this.props.updateOrganizationProfile}
      render={form =>
        <div>
          {form.item({
            valueProp: `${this.props.marketplace}_marketplace_industries`,
            label: 'INDUSTRIES',
            input: <TagsInput placeholder="Select industries"/>
          })}
          {form.item({
            valueProp: `${this.props.marketplace}_marketplace_brands`,
            label: 'BRANDS',
            input: <TagsInput placeholder="Select brands"/>
          })}
          {form.item({
            valueProp: `${this.props.marketplace}_marketplace_tags`,
            label: 'MARKETPLACE TAGS',
            input: <TagsInput placeholder="Select tags"/>
          })}
          <Form.ButtonRow>
            {form.resetButton({})}
            {form.submitButton({
              value: 'Update',
              submittingValue: 'Updating…',
            })}
          </Form.ButtonRow>
        </div>
      }
    />
  }
}
