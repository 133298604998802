
import { h } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default function Loading({
  type = 'block',
  size = 'lg',
  delayed = false,
  ...props
}){
  let className = 'Loading'
  if (type) className += ` Loading-${type}`
  if (size) className += ` Loading-${size}`
  if (delayed) className += ' Loading-delayed'
  if (props.className) className += ` ${props.className}`
  return <div {...props} className={className}>
    <div className="Loading-spinner" />
  </div>
}

Loading.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['fullScreen', 'fullPage', 'block']),
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  delayed: PropTypes.bool,
}
