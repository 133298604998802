import { h, Component } from 'preact'

import DropdownPicker from 'components/DropdownPicker'
import { SORTED_COUNTRY_NAMES } from 'lib/countries'

const propTypes = {...DropdownPicker.propTypes}
delete propTypes.options
delete propTypes.noOptions

export default class CountryDropdownPicker extends Component {
  static propTypes = propTypes

  static defaultProps = {
    autocomplete: 'country country-name',
  }

  render() {
    const { className = '', required, ...props } = this.props

    return <DropdownPicker
      options={SORTED_COUNTRY_NAMES}
      className={`CountryDropdownPicker ${className}`}
      required={required}
      name="country"
      allowCustom
      {...props}
    />
  }
}
