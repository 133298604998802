import api from '../api'

export function load(){
  if (this.getState().customPersonalDataFields) return
  this.takeAction('customPersonalDataFields.reload')
}

export function reload(){
  if (this.getState().loadingCustomPersonalDataFields) return

  this.setState({
    loadingCustomPersonalDataFields: true,
    errorLoadingCustomPersonalDataFields: undefined,
  })
  api.getCustomPersonalDataFields().then(
    ({customPersonalDataFields}) => {
      this.setState({
        loadingCustomPersonalDataFields: undefined,
        customPersonalDataFields,
      })
    },
    errorLoadingCustomPersonalDataFields => {
      this.setState({
        loadingCustomPersonalDataFields: undefined,
        errorLoadingCustomPersonalDataFields,
      })
    }
  )
}
