import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Form from 'components/Form'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import Link from 'components/Link'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'

import './index.sass'

export default class RequestPasswordResetForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    goBack: PropTypes.func.isRequired,
    inputType: TextInput.propTypes.type,
    placeholder: PropTypes.string.isRequired,
    login: PropTypes.string,
    onInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    passwordResetRequest: PropTypes.shape({
      pending: PropTypes.bool,
      sent: PropTypes.bool,
      error: ErrorMessage.propTypes.error,
      mobilePhoneVerificationRequired: PropTypes.bool,
      emailVerificationRequired: PropTypes.bool,
    }),
  }

  componentDidMount(){
    setTimeout(() => {
      this.base.querySelector('input[name="login"]').focus()
    }, 0)
  }

  goBack = () => {
    this.props.goBack()
    this.props.reset()
  }

  onSubmit = () => {
    this.props.onSubmit({ login: this.props.login })
  }

  reset = () => {
    const { reset, onInput } = this.props
    onInput('')
    reset()
  }

  render() {
    const {
      className = '',
      login,
      inputType,
      placeholder,
      onInput,
      passwordResetRequest = {},
    } = this.props

    const {
      pending,
      sent,
      error,
      emailVerificationRequired,
    } = passwordResetRequest

    return <div className={`RequestPasswordResetForm ${className}`}>
      <Link
        onClick={this.goBack}
        type="text"
        className="RequestPasswordResetForm-backButton"
      >
        <Icon type="left-open"/>
        Back
      </Link>
      {
        sent
          ? emailVerificationRequired
            ? <EmailVerificationRequired
              onReset={this.reset}
              email={passwordResetRequest.login}
            />
            : <ResetMessageSent
              onReset={this.reset}
            />
          : <RequestForm
            inputType={inputType}
            placeholder={placeholder}
            login={login}
            onInput={onInput}
            onSubmit={this.onSubmit}
            onReset={this.reset}
            pending={pending}
            error={error}
          />
      }
    </div>
  }
}


function EmailVerificationRequired({ email, onReset }){
  return <div className="RequestPasswordResetForm-success">
    <Header size="md">Email not yet verififed!</Header>
    <p>
      We've sent an email to {email} with a verification link.
      Please continue by clicking that link.
    </p>
    <div className="RequestPasswordResetForm-success-link">
      <Link type="text" onClick={onReset}>
        Start over
      </Link>
    </div>
  </div>
}

function ResetMessageSent({ onReset }){
  return <div className="RequestPasswordResetForm-success">
    <Header size="md">Success!</Header>
    <p>
      We've sent you a message with a reset password link.
      Please continue by clicking that link.
    </p>
    <div className="RequestPasswordResetForm-success-link">
      <Link type="text" onClick={onReset}>
        Start over
      </Link>
    </div>
  </div>
}

function RequestForm({
  inputType,
  placeholder,
  login,
  onInput,
  onSubmit,
  pending,
  error,
}){
  const disabled = !!pending
  return <div>
    <Header size="md">Reset your password</Header>
    <ErrorMessage error={error} />
    <Form onSubmit={onSubmit}>
      <Form.Item>
        <TextInput
          type={inputType || 'text'}
          value={login}
          onInput={onInput}
          placeholder={placeholder}
          name="login"
          required
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          submit
          block
          disabled={disabled}
          fat
        >
          { pending ?
            <span><Spinner />&nbsp;Sending reset password link…</span> :
            'Send reset password link'
          }
        </Button>
      </Form.Item>
    </Form>
  </div>
}
