import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Button from 'components/Button'
import Icon from 'components/Icon'
import './index.sass'

export default function IconButton({
  children,
  className,
  disabled,
  bordered,
  padded,
  // icon props
  type,
  value,
  size,
  rotate,
  transitionRotation,
  spin,
  blue,
  subtle,
  // button props
  ...props
}){
  className = classNames('IconButton', { disabled, bordered, padded, className })
  return <Button {...{...props, className, disabled, type: 'none'}}>
    <span>
      {typeof children === 'undefined'
        ? <Icon {...{type, value, size, rotate, transitionRotation, spin, blue, subtle }}/>
        : children
      }
    </span>
  </Button>
}

IconButton.propTypes = {
  className:          Button.propTypes.className,
  disabled:           Button.propTypes.disabled,
  children:           PropTypes.node,
  bordered:           PropTypes.bool,
  padded:             PropTypes.bool,
  block:              Button.propTypes.block,
  submit:             Button.propTypes.submit,
  href:               Button.propTypes.href,
  onClick:            Button.propTypes.onClick,
  type:               Icon.propTypes.type,
  value:              Icon.propTypes.value,
  size:               Icon.propTypes.size,
  rotate:             Icon.propTypes.rotate,
  transitionRotation: Icon.propTypes.transitionRotation,
  spin:               Icon.propTypes.spin,
  blue:               Icon.propTypes.blue,
  subtle:             Icon.propTypes.subtle,
}
