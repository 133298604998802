import { h } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'

import BobLayout from 'components/BobLayout'
import OneColumn from 'components/OneColumn'

class BusinessAdminPage extends Page {
  static pageTitle = 'Business - Dashboard'

  render() {
    return <BobLayout
      className="BusinessAdminPage"
      location={this.props.location}
      crumbs={['Business', 'Admin Dashboard']}
      organizationProfile={this.props.organizationProfile}
    >
      <OneColumn>
        <div>Coming soon!</div>
      </OneColumn>
    </BobLayout>
  }
}

export default bindToAppState(
  [
    'organizationProfile',
  ],
  BusinessAdminPage,
)
