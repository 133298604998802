import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import SwitchListMember from 'components/SwitchListMember'

export default class SwitchListWithDescription extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    switchesAndText: PropTypes.arrayOf(
      PropTypes.shape({
        checked: PropTypes.bool.isRequired,
        unsaved: PropTypes.bool,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ).isRequired,
    onSwitchChange: PropTypes.func.isRequired,
    descriptionsDefaultOpen: PropTypes.bool,
  }

  static defaultProps = {
    descriptionsDefaultOpen: false,
  }

  render(){
    const {
      switchesAndText,
      onSwitchChange,
      className = '',
      disabled,
      descriptionsDefaultOpen,
    } = this.props
    return <div className={`SwitchListWithDescription ${className}`}>
      {switchesAndText.map(_switch =>
        <SwitchListMember
          key={_switch.title}
          disabled={disabled}
          unsaved={_switch.unsaved}
          label={_switch.title}
          description={_switch.description}
          checked={_switch.checked}
          onChange={checked => { onSwitchChange({[_switch.title]: checked}) }}
          descriptionDefaultOpen={descriptionsDefaultOpen}
        />
      )}
    </div>
  }
}
