import { h } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'

import BobLayout from 'components/BobLayout'
import OneColumn from 'components/OneColumn'
import Loading from 'components/Loading'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import BobMarketplaceTagsForm from 'components/BobMarketplaceTagsForm'
import BuyingInterestList from 'components/BuyingInterestList'

class BusinessMarketSellPage extends Page {

  static pageTitle = 'Business - Marketplace Sell'

  render() {
    const {
      organizationProfile,
      organizationProfileChanges,
      updatingOrganizationProfile,
      errorUpdatingOrganizationProfile,
      ['buyingInterests:business']: buyingInterests,
      ['buyingInterests:business:loading:error']: loadingBuyingInterestsError,
    } = this.props

    const layoutProps = {
      className: 'BusinessMarketSellPage',
      crumbs: ['Business', 'Market Sell'],
      location: this.props.location,
      organizationProfile,
    }

    if (!buyingInterests || !organizationProfile) {
      return <BobLayout {...layoutProps} >
        <Loading type="fullPage" />
      </BobLayout>
    }

    const buyingInterestsForMyProducts = (Object.values(buyingInterests || []))
      .filter(buyingInterest => buyingInterest.organization_apikey !== organizationProfile.apikey)

    return <BobLayout {...layoutProps}>
      <OneColumn>
        <Header size="lg">Business Marketplace</Header>
        <ErrorMessage error={loadingBuyingInterestsError} />
        <BobMarketplaceTagsForm
          marketplace="business"
          organizationProfile={organizationProfile}
          organizationProfileChanges={organizationProfileChanges}
          stageOrganizationProfileChanges={this.props.appAction('stageOrganizationProfileChanges')}
          updateOrganizationProfile={this.props.appAction('updateOrganizationProfile')}
          updatingOrganizationProfile={updatingOrganizationProfile}
          errorUpdatingOrganizationProfile={errorUpdatingOrganizationProfile}
        />
        <Header size="md" underlined>Buying interests for your products/services</Header>
        <BuyingInterestList buyingInterests={buyingInterestsForMyProducts} />
      </OneColumn>
    </BobLayout>
  }
}

export default bindToAppState(
  [
    'organizationProfile',
    'organizationProfile',
    'organizationProfileChanges',
    'updatingOrganizationProfile',
    'errorUpdatingOrganizationProfile',
    'buyingInterests:business', // buyingInterests,
    'buyingInterests:business:loading:error', // loadingBuyingInterestsError,
  ],
  BusinessMarketSellPage,
  props => {
    props.takeAction('buyingInterests.load', {type: 'business'})
  },
)
