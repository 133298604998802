import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import PERSONAL_DATA_LABELS from 'jlinc-shared/personal_data_labels'
import { getLabelForPersonalDataField } from 'lib/accountDataSpec'

import Loading from 'components/Loading'
import Paginator from 'components/Paginator'
import Table from 'components/Table'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Header from 'components/Header'
import HelpButton from 'components/HelpButton'
import './index.sass'


export default class PageOfEndUsers extends Component {

  static propTypes = {
    toHref: PropTypes.func.isRequired,
    sessionId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    filters: PropTypes.array,
    endUsers: PropTypes.array,
    consents: PropTypes.array.isRequired,
    requestedPersonalData: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  renderEndUserInvitesTable(){
    const { endUsers, loading } = this.props
    if (loading) return <Loading type="block" delayed />
    const [columns, ...rows] = endUsers
    return <Table
      columns={columns}
      rows={rows}
      keyProperty="invite_token"
      renderRow={this.renderRow}
    />
  }

  pageToHref = page => {
    const { filters } = this.props
    return this.props.toHref({ page, filters })
  }

  render(){
    const {
      toHref,
      sessionId,
      page,
      filters,
      endUsers,
      consents,
      requestedPersonalData,
    } = this.props

    const downloadCsvButton = <form
      className="PageOfEndUsers-downloadCsv"
      method="get"
      action={`${JLINC_B_API}/end_users.csv`}
    >
      <input type="hidden" name="sessionId" value={sessionId} />
      <input type="hidden" name="filters" value={JSON.stringify(filters)} />
      <Button
        submit
        type="normal"
        value="Download as CSV"
      />
    </form>

    const renderedFilters = filters.map(filter =>
      <Filter
        toHref={toHref}
        filters={filters}
        filter={filter}
      />
    )

    return <div className="PageOfEndUsers">
      <div className="PageOfEndUsers-controls">
        <NewFilterControl
          toHref={toHref}
          filters={filters}
          consents={consents}
          requestedPersonalData={requestedPersonalData}
        />
        {downloadCsvButton}
      </div>
      <div className="PageOfEndUsers-filters">
        {renderedFilters}
      </div>
      <div className="PageOfEndUsers-table">{this.renderEndUserInvitesTable()}</div>
      <Paginator
        currentPage={page}
        pageToHref={this.pageToHref}
        onLastPage={!!(endUsers && endUsers.length < 10)}
      />
    </div>
  }
}


export const FILTER_TYPES = Object.freeze([
  'Permission Enabled',
  'Permission Disabled',
  'Requested Data Shared',
  'Requested Data Not Shared',
])

class NewFilterControl extends Component {

  static propTypes = {
    toHref: PropTypes.func.isRequired,
    // filtersToHref: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    consents: PropTypes.array.isRequired,
    requestedPersonalData: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  setType = type => {
    this.setState({ type, value: null })
  }

  setValue = value => {
    this.setState({ value })
  }

  onAddFilter = () => {
    this.setState({ type: null, value: null })
  }

  render(){
    const { toHref,  filters, consents, requestedPersonalData } = this.props
    const { type, value } = this.state

    let optionsPlaceholder
    let options

    if (type && type.includes('Permission')){
      optionsPlaceholder = 'Select a Permissions'
      options = consents
    }

    if (type && type.includes('Requested Data')){
      optionsPlaceholder = 'Select a Requested Data Field'
      options = requestedPersonalData.map(getLabelForPersonalDataField)
    }

    const addFilterHref = type && value
      ? toHref({ page: 1, filters: [...filters, [type, value]] })
      : null

    return <div className="PageOfEndUsers-NewFilterControl">
      <Dropdown
        placeholder="Select a type of filter"
        options={FILTER_TYPES}
        value={type}
        onChange={this.setType}
      />
      {options &&
        <Dropdown
          placeholder={optionsPlaceholder}
          options={options}
          value={value}
          onChange={this.setValue}
        />
      }
      <div>
        <Button
          type="primary"
          value="Add Filter"
          href={addFilterHref}
          disabled={!addFilterHref}
          onClick={this.onAddFilter}
        />
        <HelpButton>
          <Header size="lg">Filtering Your End Users</Header>
          <p>You can add as many filters as you want.</p>
          <p>
            Each time you add a filter the URL changes so if you want to save
            your current configuration of filters just bookmark this page.
          </p>
        </HelpButton>
      </div>
    </div>
  }
}


class Filter extends Component {

  static propTypes = {
    toHref: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    filter: PropTypes.array.isRequired,
  }

  renderDescription(){
    const { filter } = this.props

    const [type, value] = filter

    switch(type) {
    case 'Permission Enabled':
      return <div className="PageOfEndUsers-Filter-description">
        <span>users who have given you consent to message them about&nbsp;</span>
        <span className="PageOfEndUsers-Filter-value">
          {value}
        </span>
      </div>
    case 'Permission Disabled':
      return <div className="PageOfEndUsers-Filter-description">
        <span>users who have not given you consent to message them about&nbsp;</span>
        <span className="PageOfEndUsers-Filter-value">
          {value}
        </span>
      </div>
    case 'Requested Data Shared':
      return <div className="PageOfEndUsers-Filter-description">
        <span>users who are sharing their&nbsp;</span>
        <span className="PageOfEndUsers-Filter-value">
          {PERSONAL_DATA_LABELS[value] || value}
        </span>
        <span>&nbsp;with you</span>
      </div>
    case 'Requested Data Not Shared':
      return <div className="PageOfEndUsers-Filter-description">
        <span>users who are not sharing their&nbsp;</span>
        <span className="PageOfEndUsers-Filter-value">
          {PERSONAL_DATA_LABELS[value] || value}
        </span>
        <span>&nbsp;with you</span>
      </div>
    }
  }

  render(){
    const {
      toHref,
      filters,
      filter,
    } = this.props

    const removeFilterHref = toHref({
      page: 1,
      filters: filters.filter(f => f !== filter),
    })

    return <div className="PageOfEndUsers-Filter">
      {this.renderDescription()}
      <Button
        type="none"
        href={removeFilterHref}
      >
        <Icon type="cancel-circled" />
      </Button>
    </div>
  }
}
