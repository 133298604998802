import api from '../api'

export default function(){
  if (this.getState().loadingEmails) return

  this.setState({
    loadingEmails: true,
    loadingEmailsError: undefined,
  })

  api.getEmails().then(
    ({emails}) => {
      const emailsAsObject = {}
      emails.forEach(({email, verified}) => {
        emailsAsObject[email] = verified
      })
      this.setState({
        loadingEmails: undefined,
        emails: emailsAsObject,
      })
    },
    loadingEmailsError => {
      this.setState({
        loadingEmails: undefined,
        loadingEmailsError,
      })
    }
  )
}
