import { h } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import Clipboard from 'clipboard'

import ClickChange from 'components/ClickChange'

export default function Copier({children, value, inputRef}){
  const rootRef = useRef()

  useEffect(
    () => {
      const node = rootRef.current.base
      const clipboard = new Clipboard(
        node,
        {
          action: () => {},
          target: () => {},
          text: () => value,
        }
      )

      function onClick() {
        clipboard.onClick({ delegateTarget: node })
        if (!inputRef || !inputRef.current) return
        let input = inputRef.current
        if (input.base) input = input.base
        if (input.nodeName !== 'INPUT') input = input.querySelector('input')
        input.select()
        document.execCommand('copy')
      }
      node.addEventListener('click', onClick)
      return () => {
        node.removeEventListener('click', onClick)
        clipboard.destroy()
      }
    },
    [value, inputRef]
  )

  return <ClickChange {...{
    ref: rootRef,
  }}>
    {children}
  </ClickChange>
}

Copier.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  inputRef: PropTypes.node,
}
