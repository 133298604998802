import { h } from 'preact'

import { BANNER_SIZE } from 'lib/imageHelpers'

import CropImageAssetInput from 'components/CropImageAssetInput'
import OrganizationBanner from 'components/OrganizationBanner'
import './index.sass'

export default function OrganizationBannerInput({ className = '', ...props }){
  return <CropImageAssetInput
    {...props}
    className={`OrganizationBannerInput ${className}`}
    defaultValue={OrganizationBanner.DEFAULT}
    height={BANNER_SIZE.height}
    width={BANNER_SIZE.width}
    backgroundFillStyle="#ffffff"
  />
}

OrganizationBannerInput.width = BANNER_SIZE.width
OrganizationBannerInput.height = BANNER_SIZE.height
