import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Dropdown from 'components/Dropdown'

import './index.sass'

export default class BobMailing extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    activeConsents: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  render({value, onChange, onSubmit, activeConsents}){

    return <div className="BobMailing">
      <div className="BobMailing-row">
        <Dropdown
          value={value}
          onChange={onChange}
          options={activeConsents}
          placeholder="Choose permission type"
        />
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={!value}
        >
          <span>Download CSV</span>
        </Button>
      </div>
    </div>
  }
}
