import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Link from 'components/Link'
import './index.sass'

export default function Header({
  className = '',
  size,
  underlined,
  centered,
  bold,
  italic,
  subtle,
  children,
  ...props
}){
  className = classNames('Header', {
    [size]: 1, underlined, centered, bold, italic, subtle, className,
  })
  return props.onClick || props.href
    ? <Link type="none" className={className} {...props}>{children}</Link>
    : <div className={className} {...props}>{children}</div>
}

Header.SIZES = Object.freeze(['xs', 'sm', 'md', 'lg', 'xl'])
Header.propTypes = {
  size: PropTypes.oneOf(Header.SIZES).isRequired,
  underlined: PropTypes.bool,
  centered: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  subtle: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
}

Header.defaultProps = {
  size: 'md',
  underlined: false,
  centered: false,
}
