export function getVerticalScrollParent(element){
  if (element === window || element === document) return element
  const computedStyle = window.getComputedStyle(element, null)
  const overflow = computedStyle.getPropertyValue('overflow')
  if (overflow === 'auto' || overflow === 'scroll') return element
  const overflowY = computedStyle.getPropertyValue('overflow-y')
  if (overflowY === 'auto' || overflowY === 'scroll') return element
  return getVerticalScrollParent(element.parentNode)
}

export function isScrolledToBottom(element, buffer = 0){
  const { scrollHeight, clientHeight, scrollTop } = element
  const scrollBottom = clientHeight + scrollTop
  const delta = Math.abs(scrollHeight - scrollBottom)
  return delta <= buffer
}

export function hasRoomForMore(element){
  return element.scrollHeight <= element.clientHeight
}

export function isScrolledPastHalfWay(element){
  return element.scrollTop > (element.scrollHeight / 2)
}

export function remainingScrollAreaShorterThanHeight(element){
  return (
    element.scrollHeight -
    element.clientHeight -
    element.scrollTop <
    element.clientHeight
  )
}

export function addScrollEventListener(element, onScroll){
  ifHtmlGetDocument(element).addEventListener('scroll', onScroll, false)
}

export function removeScrollEventListener(element, onScroll){
  ifHtmlGetDocument(element).removeEventListener('scroll', onScroll)
}

function ifHtmlGetDocument(element){
  return (
    element &&
    element.ownerDocument &&
    element === element.ownerDocument.documentElement
  ) ? element.ownerDocument : element
}

export function scrollIntoView(element, offset = 0){
  const offsetTop = element.offsetTop
  const scrollParent = getVerticalScrollParent(element)
  scrollParent.scrollTo({
    top: offsetTop - offset,
    behavior: 'smooth'
  })
}

const focusableElementsSelectors = [
  'a[href]',
  'button:not([disabled])',
  'area[href]',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'iframe',
  'object',
  'embed',
  '*[tabindex]',
  '*[contenteditable]'
]

export function queryFocusable(element){
  if (!element || typeof element.querySelectorAll !== 'function'){
    console.error(new Error('queryFocusable given bad element'), { element })
    return []
  }
  const nodeList = element.querySelectorAll(
    focusableElementsSelectors.join(',')
  )
  return [...nodeList].filter(node =>
    !node.disabled &&
    node.type !== 'hidden' &&
    node.style.display !== 'none' &&
    node.style.visibility !== 'hidden'
  )
}

export function focusWithin(element, options = {}){
  if (!element || typeof element.contains !== 'function'){
    console.log(element)
    console.trace('focusWithin given no element')
    return
  }
  const activeElement = document.activeElement
  if (activeElement && element.contains(activeElement)) return
  const { focusFirst } = options
  const elements = queryFocusable(element)
  if (elements.length === 0) return
  const elementToFocus = (focusFirst && elements.includes(focusFirst))
    ? focusFirst : elements[0]
  if (elementToFocus) elementToFocus.focus()
}

export function submitParentForm(element){
  const form = element.form || element.closest('form')
  if (!form) { console.warn('part form not found for', element); return }
  try{
    // form.requestSubmit()
    form.querySelector('input[type=submit]').click()
  }catch(error){
    console.error('failed to submit parent form', error)
  }
}

export function preventFocusOut(container, options = {}){
  const { onFocus = focusWithin } = options
  const isInside = element => container.contains(element)
  const isFocusInside = () => isInside(document.activeElement)

  const ensureFocusedIn = event => {
    if (isFocusInside()) return
    onFocus(container)
    if (event) return preventDefault(event)
  }

  function preventKeyLeaks(event){
    const { target, key, shiftKey } = event
    if (key !== 'Tab') return
    if (!isInside(target)) {
      onFocus(container)
      return preventDefault(event)
    }
    const focusables = queryFocusable(container)
    const first = focusables[0]
    const last = focusables[focusables.length - 1]
    const [edge, next] = shiftKey ? [first, last] : [last, first]
    if (edge === target) {
      next.focus()
      return preventDefault(event)
    }
  }

  ensureFocusedIn()
  container.addEventListener('keydown', preventKeyLeaks, {passive:false})
  document.documentElement.addEventListener('focusin', ensureFocusedIn, {passive:false})
  container.addEventListener('blur', ensureFocusedIn, {passive:false})
  return () => {
    container.removeEventListener('keydown', preventKeyLeaks)
    document.documentElement.removeEventListener('focusin', ensureFocusedIn)
    container.removeEventListener('blur', ensureFocusedIn)
  }
}

export function onThisKeyDown(element, code, handler){
  const onKeyDown = event => {
    if (event.code === code) handler()
  }
  element.addEventListener('keydown', onKeyDown)
  return () => {
    element.removeEventListener('keydown', onKeyDown)
  }
}

function preventDefault(event){
  event.preventDefault()
  return false
}

export function isElementScrollable(element){
  return element.offsetHeight < element.scrollHeight
}

export function _preventOverscroll(event){
  const { target } = event
  const { offsetHeight, scrollHeight, scrollTop } = target
  // if target is not a scrollable element
  if (!isElementScrollable(target)) return
  const { wheelDeltaY } = event

  // if were overscrolling up
  if (wheelDeltaY > 0 && scrollTop <= 0){
    target.scrollTop = 0
    return preventDefault(event)
  }
  // if were overscrolling down
  if (wheelDeltaY < 0 && (scrollTop + offsetHeight) >= scrollHeight){
    target.scrollTop = scrollHeight
    return preventDefault(event)
  }
}

export function preventOverscroll(container){
  function onContainerMousewheel(event){
    if (
      event.target !== container &&
      container.contains(event.target) &&
      isElementScrollable(event.target)
    ) return _preventOverscroll(event)
    container.scrollTop = 0
    return preventDefault(event)
  }

  const BLOCKED_KEYS = 'PageUp PageDown End Home ArrowDown ArrowUp'.split(' ')
  function onContainerKeyDown(event){
    if (
      // SPECIAL CASE that makes this solution suck
      event.target.nodeName !== 'TEXTAREA' &&
      BLOCKED_KEYS.includes(event.key)
    ) return preventDefault(event)
    if (container.contains(event.target)) {
      event.stopPropagation()
    }else{
      return preventDefault(event)
    }
  }
  //EVENTS TO TRY: scroll wheel DOMMouseScroll mousewheel'
  container.addEventListener('mousewheel', onContainerMousewheel, {passive:false})
  container.addEventListener('keydown', onContainerKeyDown, {passive:false})
  return () => {
    container.removeEventListener('mousewheel', onContainerMousewheel)
    container.removeEventListener('keydown', onContainerKeyDown)
  }
}

export function preventInteractionsOutsideOf(container, options = {}){
  const undoPreventFocusOut = preventFocusOut(container, options)
  const undoPreventOverscroll = preventOverscroll(container, options)
  return () => {
    undoPreventFocusOut()
    undoPreventOverscroll()
  }
}

// https://stackoverflow.com/questions/1145850/how-to-get-height-of-entire-document-with-javascript
export function getDocumentHeight(document){
  const body = document.body
  const html = document.documentElement
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  )
}

export function isTargetFixed(target){
  if (target === document.body || target === document.documentElement) return false
  const { position, top } = global.getComputedStyle(target)
  if (position === 'fixed') return true
  if (position === 'sticky'){
    const stickyTop = parseInt(top, 10)
    const realTop = target.getBoundingClientRect().top
    return realTop <= stickyTop
  }
  if (target.parentElement) return isTargetFixed(target.parentElement)
}

// function logActiveElementForever(){
//   let lastActiveElement
//   setInterval(() => {
//     if (lastActiveElement !== document.activeElement){
//       lastActiveElement = document.activeElement
//       console.log('ACTIVE ELEMENT', document.activeElement)
//     }
//   }, 50)
// }

// logActiveElementForever()


export function selectNodeTextContents(node){
  if (document.body.createTextRange) {
    const range = document.body.createTextRange()
    range.moveToElementText(node)
    range.select()
  } else if (window.getSelection) {
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(node)
    selection.removeAllRanges()
    selection.addRange(range)
  } else {
    console.warn("Could not select text in node: Unsupported browser.")
  }
}
