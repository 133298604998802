import { h } from 'preact'
import { useRef, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'
import TextInput from 'components/TextInput'

export default function CopyTextInput({
  className = '', value, ...props
}){

  const ref = useRef()
  const selectAll = useCallback(
    (event) => {
      if (event) event.preventDefault()
      TextInput.focus(ref)
    },
    [],
  )

  return <TextInput {...{
    ...props,
    ref,
    className: `CopyTextInput ${className}`,
    readonly: true,
    value,
    onClick: selectAll,
    onFocus: selectAll,
  }}/>
}

CopyTextInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
}
