import { h } from 'preact'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'

import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import BuyingInterest from 'components/BuyingInterest'

import './index.sass'

const BuyingInterestList = memo(props => {
  const {
    error,
    buyingInterests,
    onEdit,
    onDelete,
  } = props

  return <div className="BuyingInterestList">
    <ErrorMessage error={error} />
    {buyingInterests
      ? <div className="BuyingInterestList-list">
        {Object.values(buyingInterests)
          .sort(newestFirst)
          .map(buyingInterest =>
            <BuyingInterest
              key={buyingInterest.uid}
              buyingInterest={buyingInterest}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )
        }
      </div>
      : <Loading type="block" />
    }
  </div>
})

BuyingInterestList.propTypes = {
  error: ErrorMessage.propTypes.error,
  buyingInterests: PropTypes.objectOf({}),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default BuyingInterestList

function newestFirst(a, b){
  a = a.created
  b = b.created
  return a < b ? 1 : a > b ? -1 : 0
}
