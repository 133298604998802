/* eslint max-len: 0 */
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'
import ORGANIZATION_BRANDING_TYPES from 'jlinc-shared/organization_branding_types'
export { default as isLegalEntity } from 'jlinc-shared/isLegalEntity'

export function isOrganizationProfileValueEqual(left, right){
  return (
    (
      (left === null || left === "" || left === undefined) &&
      (right === null || right === "" || right === undefined)
    ) ||
    left === right
  )
}

export function filterOutNonChanges(organizationProfile, changes){
  const realChanges = {}
  for(const key in changes){
    if (!isOrganizationProfileValueEqual(organizationProfile[key], changes[key])){
      realChanges[key] = changes[key]
    }
  }
  return realChanges
}

export const ORGANIZATION_TYPE_LABELS = require('jlinc-shared/organization_types_labels')

export const BRANDING_TYPES_PROP_TYPE = PropTypes.oneOf(ORGANIZATION_BRANDING_TYPES)

export const organizationProfileShape = {
  address:         PropTypes.string,
  city:            PropTypes.string,
  contact_phone:   PropTypes.string,
  contact_phone_2: PropTypes.string,
  country:         PropTypes.string,
  description:     PropTypes.string,
  domain:          PropTypes.string,
  dpo_email:       PropTypes.string,
  icon:            PropTypes.string,
  logo:            PropTypes.string,
  name:            PropTypes.string,
  legal_name:      PropTypes.string,
  type:            PropTypes.string,
  post_code:       PropTypes.string,
  state:           PropTypes.string,
  public:          PropTypes.bool,
  published:       PropTypes.bool,
}

export const ORGANIZATION_TYPE_DESCRIPTIONS = {
  individual_thought_leader: 'A personal publishing channel that the author is solely responsible for moderating.',
  informal_organization: `An informal group convened by an individual founder who is responsible for moderating the ${PNFO.singular} and channel content.`,
  small_business: `A for-profit entity, that can qualify as a small business, legally responsible for moderating the ${PNFO.singular} and channel content.`,
  ngo: `A non-profit entity legally responsible for moderating the ${PNFO.singular} and channel content.`,
  association: `An association that is legally constituted as an entity legally responsible for moderating the ${PNFO.singular} and channel content.`,
  faithbased_religious: `A faith-based or religious entity legally responsible for moderating the ${PNFO.singular} and channel content.`,
  academic_entity: `An academic or educational entity legally responsible for moderating the ${PNFO.singular} and channel content.`,
  government: `A government or political entity legally responsible for moderating the ${PNFO.singular} and channel content.`,
  corporation: `A for-profit corporate enterprise larger than a small business legally responsible for moderating the ${PNFO.singular} and channel content.`,
}

export function isInformalType({ type }) {
  return (
    type === 'individual_thought_leader' ||
    type === 'informal_organization'
  )
}
