import { h } from 'preact'

import { ICON_SIZE } from 'lib/imageHelpers'

import CropImageAssetInput from 'components/CropImageAssetInput'
import OrganizationIcon from 'components/OrganizationIcon'

export default function OrganizationIconInput({ className = '', ...props }){
  return <CropImageAssetInput
    title="Click here to upload a new icon"
    {...props}
    defaultValue={OrganizationIcon.DEFAULT}
    className={`OrganizationIconInput ${className}`}
    height={ICON_SIZE.height}
    width={ICON_SIZE.width}
  />
}

OrganizationIconInput.width = ICON_SIZE.width
OrganizationIconInput.height = ICON_SIZE.height
