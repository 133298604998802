import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'
import { useCallback } from 'preact/hooks'

import classNames from 'lib/classNames'

import Form from 'components/Form'
import TagsInput from 'components/TagsInput'
import PriceInput from 'components/PriceInput'
import FormBuilder from 'components/FormBuilder'
import DatePicker from 'components/DatePicker'
import CropImageInput from 'components/CropImageInput'

import './index.sass'

export default function BuyingInterestForm({
  className,
  value,
  cancelButton,
  ...props
}){
  const onChange = useCallback(
    changes => {
      for (const prop in changes)
        if (!changes[prop]) changes[prop] = null
      props.onChange(changes)
    },
    [props.onChange]
  )
  return <FormBuilder {...{
    ...props,
    className: classNames('BuyingInterestForm', { className }),
    onChange,
    value,
    render: form => (
      <Fragment>
        <Form.Row>
          {form.textItem({
            valueProp: 'name',
            name: 'name',
            label: 'NAME',
            required: true,
            trim: true,
            maxLength: 140,
          })}
        </Form.Row>
        <Form.Row className="BuyingInterestForm-imageRow">
          {form.item({
            valueProp: 'image',
            name: 'image',
            label: 'IMAGE',
            input: <CropImageInput {...{
              circle: false,
              height: 200,
              width: 200,
              title: `example if a ${name} input`,
            }}/>
          })}
          {form.textAreaItem({
            valueProp: 'description',
            name: 'description',
            label: 'ITEM DESCRIPTION',
            required: true,
          })}
        </Form.Row>
        <Form.Row>
          {form.item({
            valueProp: 'priceLow',
            label: 'MINIMUM PRICE',
            bindTo: 'onInput',
            input: <PriceInput {...{
              onCurrencySelect: currency => { form.change({currency}) },
              currency: form.getValue('currency'),
            }}/>
          })}
          {form.item({
            valueProp: 'priceHigh',
            label: 'MAXIMUM PRICE',
            bindTo: 'onInput',
            input: <PriceInput {...{
              onCurrencySelect: currency => { form.change({currency}) },
              currency: form.getValue('currency'),
            }}/>
          })}
        </Form.Row>
        <Form.Row>
          {form.item({
            valueProp: 'beginningDate',
            label: 'BEGINNING DATE',
            input: <DatePicker />
          })}
          {form.item({
            valueProp: 'endDate',
            label: 'END DATE',
            input: <DatePicker />
          })}
        </Form.Row>
        {form.textItem({
          valueProp: 'location',
          name: 'location',
          label: 'LOCATION',
          required: true,
        })}
        {form.textItem({
          valueProp: 'industry',
          name: 'industry',
          label: 'INDUSTRY',
          required: true,
        })}
        {form.item({
          valueProp: 'brands',
          label: 'BRANDS',
          input: <TagsInput placeholder="Enter brands" />
        })}
        {form.item({
          valueProp: 'productFeatures',
          label: 'PRODUCT FEATURES',
          required: true,
          input: <TagsInput placeholder="Enter features" />,
        })}
        <Form.ButtonRow reverse>
          {form.submitButton({
            value: 'Save',
            submittingValue: 'Saving…',
          })}
          {form.resetButton({})}
          {cancelButton}
        </Form.ButtonRow>
      </Fragment>
    ),
  }}/>
}

BuyingInterestForm.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object.isRequired,
  onChange: FormBuilder.propTypes.onChange,
  cancelButton: PropTypes.node,
}
