import { h } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'
import BobLayout from 'components/BobLayout'
import OneColumn from 'components/OneColumn'
import Loading from 'components/Loading'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import BobMarketplaceTagsForm from 'components/BobMarketplaceTagsForm'
import BuyingInterestList from 'components/BuyingInterestList'

import './index.sass'

class ConsumerMarketplacePage extends Page {

  static pageTitle = 'Consumer - Marketplace Sell'

  render() {
    const {
      appAction,
      organizationProfile,
      organizationProfileChanges,
      updatingOrganizationProfile,
      errorUpdatingOrganizationProfile,
      ['buyingInterests:consumer']: buyingInterests,
      ['buyingInterests:consumer:loading:error']: loadingBuyingInterestsError,
    } = this.props

    const layoutProps = {
      crumbs: ['Consumer', 'Market Sell'],
      location: this.props.location,
      organizationProfile,
    }

    if (!buyingInterests || !organizationProfile) {
      return <BobLayout {...layoutProps} >
        <Loading type="fullPage" />
      </BobLayout>
    }

    return <BobLayout className="ConsumerMarketplacePage" {...layoutProps}>
      <OneColumn>
        <Header size="lg">Consumer Marketplace</Header>
        <BobMarketplaceTagsForm
          marketplace="consumer"
          organizationProfile={organizationProfile}
          organizationProfileChanges={organizationProfileChanges}
          stageOrganizationProfileChanges={appAction('stageOrganizationProfileChanges')}
          updateOrganizationProfile={appAction('updateOrganizationProfile')}
          updatingOrganizationProfile={updatingOrganizationProfile}
          errorUpdatingOrganizationProfile={errorUpdatingOrganizationProfile}
        />
        <Header size="md" underlined>Buying interests for your products/services</Header>
        <ErrorMessage error={loadingBuyingInterestsError} />
        <BuyingInterestList
          buyingInterests={buyingInterests ? Object.values(buyingInterests) : []}
        />
      </OneColumn>
    </BobLayout>
  }
}

export default bindToAppState(
  [
    'organizationProfile',
    'organizationProfileChanges',
    'updatingOrganizationProfile',
    'errorUpdatingOrganizationProfile',
    'buyingInterests:consumer', // buyingInterests,
    'buyingInterests:consumer:loading:error', // loadingBuyingInterestsError,
  ],
  ConsumerMarketplacePage,
  props => {
    props.takeAction('buyingInterests.load', {type: 'consumer'})
  },
)
