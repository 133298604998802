import api from '../api'

export default function(resetPasswordToken) {
  this.setState({
    resetPasswordToken,
    verifyingResetPasswordToken: true,
  })

  api.verifyResetPasswordToken({resetPasswordToken}).then(
    () => {
      this.setState({
        verifyingResetPasswordToken: undefined,
        verifiedResetPasswordToken: resetPasswordToken,
      })
    },
    verifyingResetPasswordTokenError => {
      if (`${verifyingResetPasswordTokenError}`.includes('invalid resetPasswordToken'))
        verifyingResetPasswordTokenError = 'invalid'
      this.setState({
        verifyingResetPasswordToken: undefined,
        verifyingResetPasswordTokenError,
      })
    }
  )
}
