import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useAssetUpload from 'lib/useAssetUploadHook'
import { dataURItoFile } from 'lib/imageHelpers'

import CropImageInput from 'components/CropImageInput'
import ErrorMessage from 'components/ErrorMessage'
import Icon from 'components/Icon'
import Overlay from 'components/Overlay'
import './index.sass'

export default function CropImageAssetInput({
  className = '',
  circle,
  height,
  width,
  minZoom,
  type,
  backgroundFillStyle,
  encoderOptions,
  title,
  defaultValue,
  disabled,
  unsaved,
  value,
  onChange,
  onUploading,
}){
  const [croppedImage, setCroppedImage] = useState()

  const {
    uploadAsset,
    cancelAssetUpload,
    uploadingAsset,
    assetUrl,
    uploadingAssetError,
  } = useAssetUpload('CropImageAssetInput')

  const reset = () => {
    cancelAssetUpload()
    setCroppedImage()
    if (onUploading) onUploading(false)
  }

  useEffect(reset, [value])

  useEffect(
    () => { if (onUploading) onUploading(uploadingAsset) },
    [uploadingAsset, onUploading]
  )

  useEffect(
    () => {
      if (!assetUrl) return
      if (value === assetUrl){
        reset()
      }else{
        onChange(assetUrl)
      }
    },
    [value, assetUrl],
  )

  const onCrop = croppedImageDataURL => {
    if (onUploading) onUploading(true)
    setCroppedImage(croppedImageDataURL)
    uploadAsset(dataURItoFile(croppedImageDataURL))
  }

  return <div className={classNames('CropImageAssetInput', {circle, className})}>
    <ErrorMessage error={uploadingAssetError} onDismiss={reset} />
    <CropImageInput {...{
      disabled: (disabled || uploadingAsset),
      circle,
      height,
      width,
      minZoom,
      type,
      backgroundFillStyle,
      encoderOptions,
      title,
      defaultValue,
      unsaved,
      value: (uploadingAsset ? croppedImage : value),
      onChange: onCrop,
    }}/>
    {uploadingAsset &&
      <Overlay>
        <Icon type="up-bold"/>
        <span>Uploading…</span>
      </Overlay>
    }
  </div>
}

CropImageAssetInput.propTypes = {
  ...CropImageInput.propTypes,
  onUploading: PropTypes.func,
}
