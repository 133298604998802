import { h } from 'preact'
import PropTypes from 'prop-types'
import './index.sass'

export default function OneColumn({
  className = '',
  children,
  noPadding,
  ...props
}){
  className = `OneColumn ${className}`
  if (noPadding) className += ' OneColumn-noPadding'
  return <div className={className} {...props}>
    {children}
  </div>
}

OneColumn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
}
