import api from '../api'

export function loadAll() {
  if (this.getState().bisas) return
  this.takeAction('bisas.reloadAll')
}

export function reloadAll() {
  if (this.getState().loadingBisas) return

  this.setState({
    loadingBisas: true,
    errorLoadingBisas: undefined,
  })

  api.getBisas().then(
    ({ bisas }) => {
      this.setState({
        loadingBisas: undefined,
        bisas,
      })
    },
    errorLoadingBisas => {
      this.setState({
        loadingBisas: undefined,
        errorLoadingBisas,
      })
    }
  )
}

export function loadAllOfferings() {
  const { loadingBisaOfferings, bisaOfferings } = this.getState()
  if (loadingBisaOfferings || bisaOfferings) return
  return this.takeAction('bisas.reloadAllOfferings')
}

export function reloadAllOfferings() {
  if (this.getState().loadingBisaOfferings) return

  this.setState({
    loadingBisaOfferings: true,
    errorLoadingBisaOfferings: undefined,
  })

  api.getBisaOfferings().then(
    ({ bisaOfferings }) => {
      this.setState({
        loadingBisaOfferings: undefined,
        bisaOfferings,
      })
    },
    errorLoadingBisaOfferings => {
      this.setState({
        loadingBisaOfferings: undefined,
        errorLoadingBisaOfferings,
      })
    }
  )
}


export function offer({ organizationApikey }) {
  if (this.getState()[`offeringBisa:${organizationApikey}`]) return

  this.setState({
    [`offeringBisa:${organizationApikey}`]: true,
    [`errorOfferingBisa:${organizationApikey}`]: undefined,
  })

  api.offerBisa({ targetOrganizationApikey: organizationApikey }).then(
    ({ bisaOffering }) => {
      this.setState({
        [`offeringBisa:${organizationApikey}`]: undefined,
        bisaOfferings: [
          ...(this.getState().bisaOfferings || []),
          bisaOffering,
        ],
      })
    },
    errorOfferingBisa => {
      this.setState({
        [`offeringBisa:${organizationApikey}`]: undefined,
        [`errorOfferingBisa:${organizationApikey}`]: errorOfferingBisa,
      })
    }
  )
}

export function resolveBisaOffering({ bisaOffering, accept }) {
  if (!bisaOffering) throw new Error(`bisaOffering is required`)
  if (typeof accept !== 'boolean') throw new Error(`accept must be a boolean`)
  const bisaOfferingId = bisaOffering.id
  if (!bisaOfferingId) throw new Error(`bisaOffering.id is required`)

  if (this.getState()[`resolvingBisaOffering:${bisaOfferingId}`]) return

  this.setState({
    [`resolvingBisaOffering:${bisaOfferingId}`]: true,
    [`errorResolvingBisaOffering:${bisaOfferingId}`]: undefined,
  })

  api.resolveBisaOffering({ bisaOfferingId, accept }).then(
    ({ bisa }) => {
      const { bisaOfferings, bisas } = this.getState()

      this.setState({
        [`resolvingBisaOffering:${bisaOfferingId}`]: undefined,
        bisaOfferings: bisaOfferings.filter(offering => offering.id !== bisaOfferingId),
        bisas: accept ? [...bisas, bisa] : bisas,
      })
    },
    errorResolvingBisaOffering => {
      this.setState({
        [`resolvingBisaOffering:${bisaOfferingId}`]: undefined,
        [`errorResolvingBisaOffering:${bisaOfferingId}`]: errorResolvingBisaOffering,
      })
    }
  )
}

export function createEvent({ bisa, key, enabled }) {
  if (this.getState()[`creatingBisaEvent:${bisa.bisaId}`]) return

  this.setState({
    [`creatingBisaEvent:${bisa.bisaId}`]: true,
    [`errorCreatingBisaEvent:${bisa.bisaId}`]: undefined,
  })

  const bisaEvent = {
    bisaId: bisa.bisaId,
    eventType: 'permissionEvent',
    event: {
      [key]: enabled,
    }
  }

  api.createBisaEvent({ bisaEvent }).then(
    ({bisaEvent}) => {
      let { bisas, organizationProfile } = this.getState()
      const stateKey = bisa.offerorApikey === organizationProfile.apikey
        ? 'offerorState'
        : 'acceptorState'

      bisa[stateKey] = {
        ...bisa[stateKey],
        ...bisaEvent.event,
      }

      bisas = bisas.reduce((allBisas, curBisa) => {
        if (curBisa.bisaId === bisa.bisaId) {
          allBisas.push(bisa)
        } else {
          allBisas.push(curBisa)
        }
        return allBisas
      }, [])

      this.setState({
        [`creatingBisaEvent:${bisa.bisaId}`]: undefined,
        bisas,
      })
    },
    errorCreatingBisaEvent => {
      this.setState({
        [`creatingBisaEvent:${bisa.bisaId}`]: undefined,
        [`errorCreatingBisaEvent:${bisa.bisaId}`]: errorCreatingBisaEvent,
      })
    }
  )
}

