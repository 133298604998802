import { h, Fragment } from 'preact'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'

import IconButton from 'components/IconButton'
import Tooltip from 'components/Tooltip'
// import AlertModal from 'components/AlertModal'
import StyleishModal from 'components/StyleishModal'

import './index.sass'

const HelpButton =  memo(({
  children,
  className = '',
  iconType,
  modalClassName,
  showCloseXButton,
  title,
  scrollInternally,
  ...props
}) => {
  const [isOpen, open, close] = useToggle()

  return <Fragment>
    <Tooltip text={title}>
      <IconButton
        type={iconType}
        {...props}
        className={classNames('HelpButton', {className})}
        onClick={open}
      />
    </Tooltip>
    <StyleishModal {...{
      modalClassName,
      showCloseXButton,
      title,
      scrollInternally,
      open: isOpen,
      onClose: close,
    }}>
      {children}
    </StyleishModal>
  </Fragment>
})

HelpButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  iconSize: IconButton.propTypes.size.isRequired,
  modalClassName: StyleishModal.propTypes.modalClassName,
  showCloseXButton: StyleishModal.propTypes.showCloseXButton,
  title: StyleishModal.propTypes.title,
  scrollInternally: StyleishModal.propTypes.scrollInternally,
  // buttons: AlertModal.propTypes.buttons,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  tabIndex: PropTypes.string,
}

HelpButton.defaultProps = {
  iconType: 'help-circled',
  tabIndex: '-1',
  title: 'Help',
}

export default HelpButton

// export default class HelpButton extends Component {

//   static propTypes = {
//     className: PropTypes.string,
//     iconType: PropTypes.string,
//     buttons: AlertModal.propTypes.buttons,
//     children: PropTypes.node.isRequired,
//     onOpen: PropTypes.func,
//     onClose: PropTypes.func,
//     tabIndex: PropTypes.string,
//   }

//   static defaultProps = {
//     iconType: 'help-circled',
//     tabIndex: '-1',
//   }

//   state = { open: false }

//   open = () => {
//     this.setState({ open: true })
//     this.props.onOpen && this.props.onOpen()
//   }

//   close = () => {
//     this.setState({ open: false })
//     this.props.onClose && this.props.onClose()
//   }

//   render(){
//     let {
//       className = '',
//       iconType,
//       buttons,
//       ...props
//     } = this.props

//     return <div
//       className={`HelpButton ${className}`}
//     >
//       <Button
//         {...props}
//         type="none"
//         onClick={this.open}
//       >
//         <Icon
//           type={iconType}
//           className="HelpButton-Icon"
//         />
//       </Button>
//       {
//         this.state.open && <AlertModal
//           onClose={this.close}
//           narrow
//           buttons={buttons}
//         >
//           {this.props.children}
//         </AlertModal>
//       }
//     </div>
//   }
// }
