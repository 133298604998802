import { h } from 'preact'
import PropTypes from 'prop-types'

import TextInput from 'components/TextInput'

import './index.sass'

export default function TextInputWithIcon({ icon, position, disabled, className = '', ...props }) {
  const iconElement = <span className="TextInputWithIcon-icon">{icon}</span>
  className = `TextInputWithIcon TextInputWithIcon-${position} ${className}`
  if (disabled) className += ' TextInputWithIcon-disabled'

  return <div className={className}>
    { position === 'left' && iconElement }
    <TextInput disabled={disabled} {...props} />
    { position === 'right' && iconElement }
  </div>
}

TextInputWithIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    'left',
    'right',
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onInput: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}
