import { h } from 'preact'
import { useErrorBoundary } from 'preact/hooks'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import classNames from 'lib/classNames'
import { usePortal } from 'lib/portal'

import Modal from 'components/Modal'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'

export default function StyleishModal({
  modalClassName,
  className,
  children,
  open,
  scrollInternally,
  title,
  body,
  padded,
  ...props
}){
  const renderInPortal = usePortal()

  modalClassName = classNames('StyleishModal', { className: modalClassName })
  className = classNames('StyleishModal-window', { padded, className })
  if (scrollInternally) modalClassName += ` StyleishModal-scrollInternally`
  let modal

  if (open) modal = <CSSTransition
    timeout={300}
    classNames="StyleishModal-animation"
  >
    <Modal {...{...props, modalClassName, className}}>
      <Header size="lg" className="StyleishModal-title" centered>{title}</Header>
      <ModalBody>{body || children}</ModalBody>
    </Modal>
  </CSSTransition>

  return renderInPortal(
    <TransitionGroup component="span">{modal}</TransitionGroup>
  )
}

StyleishModal.propTypes = {
  ...Modal.propTypes,
  modalClassName: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  showCloseXButton: PropTypes.bool,
  title: PropTypes.node,
  scrollInternally: PropTypes.bool.isRequired,
}

StyleishModal.defaultProps = {
  open: false,
  showCloseXButton: true,
  scrollInternally: false,
  padded: true,
}

function ModalBody({ children }){
  const [error, onDismiss] = useErrorBoundary()
  if (error) console.error('ModalBody render error', error)
  return <div className="StyleishModal-body">
    {error ? <ErrorMessage {...{error, onDismiss}}/> : children}
  </div>
}
