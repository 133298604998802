import api from '../api'

export function start({email, stripeToken, inviteToken}) {
  this.setState({
    signingUp: true,
    signupError: undefined,
  })

  return api.signup({email, stripeToken, inviteToken}).then(
    () => {
      this.setState({
        signingUp: undefined,
        signupEmail: email,
      })
    },
    signupError => {
      this.setState({
        signupError,
        signingUp: undefined,
      })
    }
  )
}

export function verifySignupToken({signupToken}) {
  this.setState({
    verifyingSignup: true,
    verifySignupError: undefined,
  })

  this.takeAction('session.logout')
  return api.verifySignup({signupToken}).then(
    ({ email }) => {
      this.setState({
        verifyingSignup: undefined,
        signupEmail: email,
      })
    },
    verifySignupError => {
      this.setState({
        verifySignupError,
        verifyingSignup: undefined,
      })
    }
  )
}

export function clearErrors() {
  this.setState({
    signupError: undefined,
  })
}

export function complete({
  signupToken,
  name,
  password,
  passwordConfirmation,
  keepMeLoggedIn,
}) {
  this.setState({
    completingSignup: true,
    completeSignupError: undefined,
  })

  return api.completeSignup({
    signupToken,
    name,
    password,
    passwordConfirmation,
    keepMeLoggedIn,
  }).then(
    ({ email, sessionId, organization }) => {
      this.setState({
        completingSignup: undefined,
        signupToken: undefined,
        organizationProfile: organization,
      })
      this.takeAction('session.setCurrentUser', { email, sessionId })
      this.takeAction('location.replace', '/')
    },
    completeSignupError => {
      this.setState({
        completingSignup: undefined,
        completeSignupError,
      })
    }
  )
}
