import api from '../api'

export function resolveInvite({ organizationApikey, accept }) {
  if (!organizationApikey) throw new Error(`organizationApikey is required`)
  if (typeof accept !== 'boolean') throw new Error(`accept must be a boolean`)

  if (this.getState()[`resolvingNetworkInvite:${organizationApikey}`]) return

  this.setState({
    [`resolvingNetworkInvite:${organizationApikey}`]: true,
    [`errorResolvingNetworkInvite:${organizationApikey}`]: undefined,
  })

  const myOrganizationApikey = this.getState().organizationProfile.apikey

  api.resolveNetworkInvite({ organizationApikey, accept }).then(
    () => {
      const { networkInvites, organizations } = this.getState()
      const networkOrganization = organizations[organizationApikey]
      const networkOrganizationNetworkMembers = networkOrganization.network_members || []
      if (accept) networkOrganizationNetworkMembers.unshift(myOrganizationApikey)

      const updatedOrganizations = {
        ...organizations,
        [organizationApikey]: {
          ...networkOrganization,
          network_members: networkOrganizationNetworkMembers,
        },
      }

      const updatedNetworkInvites = networkInvites.filter(
        invite => invite.networkOrganizationApikey !== organizationApikey
      )

      this.setState({
        [`resolvingNetworkInvite:${organizationApikey}`]: undefined,
        networkInvites: updatedNetworkInvites,
        organizations: updatedOrganizations,
      })
    },
    errorResolvingNetworkInvite => {
      this.setState({
        [`resolvingNetworkInvite:${organizationApikey}`]: undefined,
        [`errorResolvingNetworkInvite:${organizationApikey}`]: errorResolvingNetworkInvite,
      })
    }
  )
}

export function removeOrganization({ memberOrganizationApikey }) {
  const confirmed = confirm('Are you sure you want to remove this organization from your Network?') // eslint-disable-line
  if (!confirmed) return

  if (!memberOrganizationApikey) throw new Error(`organizationApikey is required`)

  const removingKey = `removingFromNetwork:${memberOrganizationApikey}`
  const errorKey = `errorRemovingFromNetwork:${memberOrganizationApikey}`

  if (this.getState()[removingKey]) return

  this.setState({
    [removingKey]: true,
    [errorKey]: undefined,
  })

  api.removeOrganizationFromNetwork({ memberOrganizationApikey }).then(
    () => {
      const { organizationProfile } = this.getState()
      const updatedOrganizationProfile = {
        ...organizationProfile,
        network_members: organizationProfile.network_members.filter(
          member => member !== memberOrganizationApikey
        ),
      }

      this.setState({
        organizationProfile: updatedOrganizationProfile,
        [removingKey]: undefined,
        [errorKey]: undefined,
      })
    },
    errorRemovingFromNetwork => {
      this.setState({
        [removingKey]: undefined,
        [errorKey]: errorRemovingFromNetwork,
      })
    }
  )
}

export function leave({ networkOrganizationApikey }){
  const confirmed = confirm('Are you sure you want to leave this Network?') // eslint-disable-line
  if (!confirmed) return

  if (!networkOrganizationApikey) throw new Error(`networkOrganizationApikey is required`)

  const memberOrganizationApikey = this.getState().organizationProfile.apikey

  const leavingKey = `leavingNetwork:${networkOrganizationApikey}`
  const errorKey = `errorLeavingNetwork:${networkOrganizationApikey}`

  if (this.getState()[leavingKey]) return

  this.setState({
    [leavingKey]: true,
    [errorKey]: undefined,
  })

  api.leaveNetwork({ networkOrganizationApikey }).then(
    () => {
      const { organizations } = this.getState()
      const networkOrganization = organizations[networkOrganizationApikey]
      const network_members = networkOrganization.network_members.filter(
        member => member !== memberOrganizationApikey
      )
      const updatedOrganizations = {
        ...organizations,
        [networkOrganizationApikey]: {
          ...networkOrganization,
          network_members,
        }
      }

      this.setState({
        organizations: updatedOrganizations,
        [leavingKey]: undefined,
        [errorKey]: undefined,
      })
    },
    errorRemovingFromNetwork => {
      this.setState({
        [leavingKey]: undefined,
        [errorKey]: errorRemovingFromNetwork,
      })
    }
  )
}

export function inviteOrganization(organizationName) {
  const organization = Object.values(this.getState().organizations)
    .find(organization => organization.name === organizationName)

  this.setState({
    invitingOrganizationToNetwork: true,
    errorInvitingOrganizationToNetwork: undefined,
  })

  api.inviteOrganizationToNetwork({ inviteeOrganizationApikey: organization.apikey })
    .then(
      ({ networkInvite }) => {
        const { invitesToMyNetwork } = this.getState()
        this.setState({
          invitingOrganizationToNetwork: undefined,
          networkInviteUrl: undefined,
          networkInvite,
          invitesToMyNetwork: [networkInvite, ...invitesToMyNetwork],
        })
      },
      errorInvitingOrganizationToNetwork => {
        this.setState({
          invitingOrganizationToNetwork: undefined,
          errorInvitingOrganizationToNetwork,
        })
      }
    )
}

export function loadInvites() {
  if (this.getState().loadingNetworkInvites) return

  this.setState({
    loadingNetworkInvites: true,
    errorLoadingNetworkInvites: undefined,
  })

  api.getNetworkInvites()
    .then(
      ({networkInvites}) => {
        this.setState({
          loadingNetworkInvites: undefined,
          invitesToMyNetwork: networkInvites,
        })
      },
      errorLoadingNetworkInvites => {
        this.setState({
          loadingNetworkInvites: undefined,
          errorLoadingNetworkInvites,
        })
      }
    )
}

export function clearNetworkInvite() {
  this.setState({ networkInvite: undefined })
}
