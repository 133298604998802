import api from '../api'

export function setCurrentUser(currentUser){
  saveCurrentUserToLocalStorage(currentUser)
  this.setState({ currentUser })
  if (currentUser){
    api.sessionId = currentUser.sessionId
    this.takeAction('loadOverview')
  }
}

export function login({ email, password, keepMeLoggedIn, organizationAdminInviteToken }){
  this.setState({
    loggingIn: true,
    registrationError: undefined,
    loginError: undefined,
    completeSignupError: undefined,
    verifySignupError: undefined,
  })

  api.login({ email, password, keepMeLoggedIn, organizationAdminInviteToken }).then(
    ({ email, sessionId, invitedToNetwork }) => {
      this.setState({ loggingIn: undefined })
      this.takeAction('session.setCurrentUser', { email, sessionId })
      if (invitedToNetwork) this.takeAction('location.set', '/networks')
    },
    loginError => {
      this.setState({
        loggingIn: undefined,
        loginError,
      })
      this.takeAction('session.setCurrentUser', undefined)
    }
  )
}

export function logout(options = {}){
  if (options.post !== false && this.getState().currentUser) api.logout()
  this.resetState()
  this.takeAction('session.setCurrentUser', undefined)
  this.takeAction('location.publish')
}

export async function verify(){
  if (this.getState().currentUser) await api.verifySession()
}

export function restore(){
  const {
    sessionId, email, password, it: inviteToken
  } = this.getState().location.query
  if (inviteToken){
    this.takeAction('session.setCurrentUser', undefined)
    return
  }
  if (sessionId){
    this.takeAction('session.setCurrentUser', { sessionId, email })
    this.takeAction('location.replace', '/')
    return
  }
  if (email && password) {
    this.takeAction('session.login', { email, password })
    this.takeAction('location.replace', '/')
    return
  }
  const currentUser = getCurrentUserFromLocalStorage()
  if (!currentUser) return
  this.setState({
    verifyingSession: true,
  })
  api.sessionId = currentUser.sessionId
  api.verifySession()
    .then(
      () => currentUser,
      () => undefined,
    )
    .then(currentUser => {
      this.takeAction('session.setCurrentUser', currentUser)
      this.setState({
        verifyingSession: undefined,
      })
    })
}

const saveCurrentUserToLocalStorage = (currentUser) => {
  if (currentUser)
    window.localStorage['jlinc-bportal-currentUser'] = JSON.stringify(currentUser)
  else
    delete window.localStorage['jlinc-bportal-currentUser']
}

const getCurrentUserFromLocalStorage = () => {
  let currentUser

  if (!window.localStorage['jlinc-bportal-currentUser']) return

  try{
    currentUser = JSON.parse(window.localStorage['jlinc-bportal-currentUser'])
  } catch(error){
    console.error('error parsing localStorage["jlinc-bportal-currentUser"]', error)
    return
  }

  if (currentUser.sessionID) {
    currentUser.sessionId = currentUser.sessionID
    delete currentUser.sessionID
  }

  return currentUser
}
