import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { bindToAppState } from 'lib/appState'

import {
  disableDocumentScrolling,
  enableDocumentScrolling,
} from 'lib/modalHelpers'

import Header from 'components/Header'
import Form from 'components/Form'
import Checkbox from 'components/Checkbox'
import TextInput from 'components/TextInput'
import TextArea from 'components/TextArea'
import Button from 'components/Button'
import Icon from 'components/Icon'
import ErrorMessage from 'components/ErrorMessage'
import Alert from 'components/Alert'
import SuccessAnimation from 'components/SuccessAnimation'
import './index.sass'

const ANIMATION_DURATION = 200 // ms
const waitForAnimation = cb => setTimeout(cb, ANIMATION_DURATION)

class HelpAndFeedback extends Component {

  componentDidMount(){
    document.addEventListener('click', this.onAnyClick)
    document.addEventListener('focusout', this.onAnyFocusOut)
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.onAnyClick)
    document.removeEventListener('focusout', this.onAnyFocusOut)
  }

  state = {
    isOpen: false,
    isOpening: false,
    isClosing: false,
  }

  open = () => {
    const { isOpen, isOpening, isClosing } = this.state
    if (isOpen || isOpening || isClosing) return
    this.setState({ isOpening: true })
    waitForAnimation(() => {
      this.setState({ isOpen: true, isOpening: false })
    })
  }

  close = () => {
    const { isOpen, isOpening, isClosing } = this.state
    if (!isOpen || isOpening || isClosing) return
    this.setState({ isOpen: false, isClosing: true })
    waitForAnimation(() => {
      this.setState({ isClosing: false })
    })
  }

  onAnyClick = event => {
    const { isOpen, isOpening } = this.state
    if (
      (isOpen || isOpening) &&
      this.base &&
      (event.target === this.base || !this.base.contains(event.target))
    ){
      event.preventDefault()
      this.close()
      return false
    }
  }

  onAnyFocusOut = event => {
    const { isOpen, isOpening } = this.state
    if (
      (isOpen || isOpening) &&
      this.base &&
      !this.base.contains(event.target)
    ){
      this.close()
    }
  }

  render(){
    const {
      sendingHelpRequest,
      errorSendingHelpRequest,
    } = this.props

    const {
      isOpen,
      isOpening,
      isClosing,
    } = this.state

    let className = 'HelpAndFeedback'
    if (isOpen) className += ' HelpAndFeedback-open'
    if (isOpening) className += ' HelpAndFeedback-opening'
    if (isClosing) className += ' HelpAndFeedback-closing'

    const content = isOpen
      ? isOpening || isClosing
        ? null
        : <HelpAndFeedbackForm
          error={errorSendingHelpRequest}
          sendingHelpRequest={sendingHelpRequest}
          onClose={this.close}
          onSubmit={this.props.appAction('sendHelpRequest')}
        />
      : <HelpAndFeedbackOpenButton open={this.open} />

    return <div className={className}>
      <div className="HelpAndFeedback-container">{content}</div>
    </div>
  }
}

const HelpAndFeedbackOpenButton = ({ open }) =>
  <button className="HelpAndFeedback-OpenButton" onClick={open}>
    <Icon type="help" size="xs" />
  </button>


class HelpAndFeedbackForm extends Component {

  static propTypes = {
    error: ErrorMessage.propTypes.error,
    sendingHelpRequest: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  componentDidMount(){
    disableDocumentScrolling()
    this.focusTextarea()
  }

  componentWillReceiveProps(nextProps){
    if (this.props.sendingHelpRequest && !nextProps.sendingHelpRequest){
      nextProps.error ? this.onFailure() : this.onSuccess()
    }
  }

  componentWillUnmount(){
    enableDocumentScrolling()
  }

  state = {
    mobile: '',
    email: '',
    message: '',
    attachInfo: window.localStorage['HelpAndFeedback.attachInfo'] !== '0',
    isShowingAttachInfoHelp: false,
    messageSentSuccessfully: false,
  }

  focusSelector(selector){
    setTimeout(() => {
      const element = this.base && this.base.querySelector(selector)
      if (element) element.focus()
    }, 1)
  }

  focusTextarea(){ this.focusSelector('textarea') }
  focusCloseButton(){ this.focusSelector('.Button') }

  set(key){
    return value => {
      this.setState({
        [key]: value,
        messageSentSuccessfully: false,
      })
      if (key === 'attachInfo') window.localStorage['HelpAndFeedback.attachInfo'] = value ? '1' : '0'
    }
  }

  toggleShowingAttachInfoHelp = () => {
    this.setState({
      isShowingAttachInfoHelp: !this.state.isShowingAttachInfoHelp,
    })
  }

  submit = () => {
    if (this.props.sendingHelpRequest) return
    const { email, mobile, message, attachInfo } = this.state
    this.props.onSubmit({ email, mobile, message, attachInfo })
  }

  onSuccess(){
    this.setState(
      {
        message: '',
        messageSentSuccessfully: true,
      },
      () => { this.focusCloseButton() }
    )
  }

  onFailure(){
    this.focusTextarea()
  }

  render(){
    const {
      error,
      sendingHelpRequest,
      onClose,
    } = this.props

    const {
      mobile,
      email,
      message,
      attachInfo,
      isShowingAttachInfoHelp,
      messageSentSuccessfully,
    } = this.state

    const submittable = message && message.length > 2

    if (!error && messageSentSuccessfully){
      return <Form>
        <Header size="lg">Help & Feedback</Header>
        <Alert type="success" className="HelpAndFeedback-successAlert">
          <p> Your message was sent! </p>
          <SuccessAnimation />
        </Alert>
        <Form.ButtonRow>
          <Button
            autoFocus
            type="primary"
            onClick={onClose}
            value="Close"
          />
        </Form.ButtonRow>
      </Form>
    }

    return <Form onSubmit={this.submit}>
      <Header size="lg">Help & Feedback</Header>

      <ErrorMessage error={error}/>

      <Form.Row>
        <TextInput
          type="tel"
          placeholder="mobile (optional)"
          value={mobile}
          onInput={this.set('mobile')}
          disabled={sendingHelpRequest}
        />
      </Form.Row>

      <Form.Row>
        <TextInput
          type="email"
          placeholder="email (optional)"
          value={email}
          onInput={this.set('email')}
          disabled={sendingHelpRequest}
        />
      </Form.Row>

      <TextArea
        autoFocus
        placeholder="How can we help?"
        value={message}
        onInput={this.set('message')}
        resize="none"
        disabled={sendingHelpRequest}
      />

      <Form.Row>
        <Checkbox
          checked={!!attachInfo}
          onChange={this.set('attachInfo')}
          label={
            <span>
              <span>Attach information about bug</span>
              <Button
                type="none"
                onClick={this.toggleShowingAttachInfoHelp}
                className="HelpAndFeedback-attachInfoHelpButton"
              >
                <Icon type="help-circled" className="HelpButton-Icon" />
              </Button>
            </span>
          }
          disabled={sendingHelpRequest}
        />
      </Form.Row>
      {
        isShowingAttachInfoHelp && <div className="HelpAndFeedback-attachInfoHelp">
          <small><i>
            When enabled, this form will send detailed information about the current
            state of this web page to our support staff to help better debug your issue.
          </i></small>
        </div>
      }
      <Form.ButtonRow>
        <Button
          type="normal"
          onClick={onClose}
          value="cancel"
          disabled={sendingHelpRequest}
        />
        <Button
          type="primary"
          submit
          value={sendingHelpRequest ? 'Sending…' : 'Send'}
          disabled={!submittable || sendingHelpRequest}
        />
      </Form.ButtonRow>
    </Form>
  }
}

export default bindToAppState(
  ['sendingHelpRequest', 'errorSendingHelpRequest'],
  HelpAndFeedback
)
