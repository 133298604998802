import { h } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default function Overlay({
  small,
  hidden,
  children,
  ...props
}){
  let className = 'Overlay'
  if (small) className += ' Overlay-small'
  if (hidden) className += ' Overlay-hidden'
  if (props.children) className += ` ${props.className}`
  return <div {...props} className={className}>
    {children}
  </div>
}

Overlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  small: PropTypes.bool,
  hidden: PropTypes.bool,
}
