import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Icon from 'components/Icon'
import Button from 'components/Button'
import './index.sass'

const alertTypesToIconMap = {
  success: 'ok-circled',
  info: 'info',
  warning: 'attention',
  error: 'cancel-circled',
}

export default function Alert({
  className = '', type, title, onDismiss, children, ...props
}){
  const withTitle = !!title
  return <div
    {...props}
    className={classNames('Alert', {
      withTitle, [type]: 1, className
    })}
  >
    {withTitle && <div className="Alert-title">{title}</div>}
    <div className="Alert-body">
      <Icon className="Alert-icon" type={alertTypesToIconMap[type]} size="lg" />
      <div className="Alert-content">{children}</div>
      {onDismiss && <DismissButton onClick={onDismiss}/>}
    </div>
  </div>
}

Alert.TYPES = Object.freeze([
  'placeholder',
  'success',
  'info',
  'warning',
  'error',
])

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Alert.TYPES).isRequired,
  title: PropTypes.node,
  onDismiss: PropTypes.func,
  children: PropTypes.node.isRequired,
}

function DismissButton({ onClick }){
  return <Button
    className="Alert-dismissButton"
    value="close"
    type="none"
    onClick={onClick}
  />
}
