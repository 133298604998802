import { h } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default function ButtonRow({className = '', children}){
  return <div className={`ButtonRow ${className}`}>
    {children}
  </div>
}

ButtonRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
