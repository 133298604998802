import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import IconButton from 'components/IconButton'
import Paginator from 'components/Paginator'
import Table from 'components/Table'
import Loading from 'components/Loading'
import TimeAgo from 'components/TimeAgo'
import BobInviteEndUserBatch from 'components/BobInviteEndUserBatch'
import BobInviteEndUserForm from 'components/BobInviteEndUserForm'

import PERSONAL_DATA_LABELS from 'jlinc-shared/personal_data_labels'
import './index.sass'

export default class BobInviteEndUsers extends Component {

  static propTypes = {
    resetEndUserInvite: PropTypes.func.isRequired,
    createEndUserInvite: PropTypes.func.isRequired,
    deleteEndUserInvite: PropTypes.func.isRequired,
    invitingEndUser: PropTypes.bool,
    invitedEndUserSignupUrl: PropTypes.string,
    invitingEndUserError: ErrorMessage.propTypes.error,
    deletingEndUserInvite: PropTypes.bool,
    errorDeletingEndUserInvite: ErrorMessage.propTypes.error,
    loadingEndUserInvites: PropTypes.bool,
    endUserInvites: PropTypes.array,
    loadingEndUserInvitesError: ErrorMessage.propTypes.error,
    sessionId: PropTypes.string,
    organizationProfile: PropTypes.object,
    page: PropTypes.number,
  }

  render() {
    const {
      invitingEndUserError,
      errorDeletingEndUserInvite,
      invitingEndUser,
      invitedEndUserSignupUrl,
      sessionId,
      loadingEndUserInvitesError,
      loadingEndUserInvites,
      endUserInvites,
      deletingEndUserInvite,
      organizationProfile,
      resetEndUserInvite,
      page,
      createEndUserInvite,
      deleteEndUserInvite,
    } = this.props

    return <div className="BobInviteEndUsers">
      <Header size="md" underlined className="BobInviteEndUsers-inviteUsersHeader">
        Invite Your End Users
      </Header>
      <ErrorMessage error={invitingEndUserError || errorDeletingEndUserInvite} />
      <div className="BobInviteEndUsers-inviteTools">
        <BobInviteEndUserForm
          organizationName={organizationProfile.name}
          invitingEndUser={invitingEndUser}
          invitedEndUserSignupUrl={invitedEndUserSignupUrl}
          onSubmit={createEndUserInvite}
          onReset={resetEndUserInvite}
        />
        <BobInviteEndUserBatch sessionId={sessionId} />
      </div>

      <Header size="md" underlined>Existing End User Invites</Header>
      <ErrorMessage error={loadingEndUserInvitesError} />
      <PageOfEndUserInvites
        page={page}
        loading={loadingEndUserInvites || !endUserInvites}
        endUserInvites={endUserInvites}
        deletingEndUserInvite={deletingEndUserInvite}
        onDelete={deleteEndUserInvite}
      />
    </div>
  }
}

const TABLE_COLUMNS = Object.freeze([
  'Actions',
  'Invite token',
  'Created At',
  'Used At',
  ...Object.values(PERSONAL_DATA_LABELS),
])

class PageOfEndUserInvites extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
    endUserInvites: PropTypes.array,
    deletingEndUserInvite: PropTypes.string,
  }

  pageToHref(page){
    return `/c/admin?page=${page}`
  }

  renderRow = endUserInvite => {
    const {
      onDelete,
      deletingEndUserInvite,
    } = this.props
    const {
      invite_token,
      invite_url,
      created,
      used_at,
    } = endUserInvite

    return [
      <InviteActions
        usedAt={used_at}
        inviteUrl={invite_url}
        inviteToken={invite_token}
        onDelete={onDelete}
        deleting={deletingEndUserInvite === invite_token}
      />,
      invite_token,
      <TimeAgo time={created} />,
      used_at ? <TimeAgo time={used_at} /> : null,
      ...Object.keys(PERSONAL_DATA_LABELS).map(key => endUserInvite.personal_data[key])
    ]
  }

  renderEndUserInvitesTable(){
    const { endUserInvites, loading } = this.props
    if (loading) return <Loading type="block" />
    return <Table
      columns={Array.from(TABLE_COLUMNS)}
      rows={endUserInvites}
      keyProperty="invite_token"
      renderRow={this.renderRow}
    />
  }

  render(){
    const { page, endUserInvites } = this.props
    return <div className="BobInviteEndUsers-PageOfEndUserInvites">
      <div className="BobInviteEndUsers-table">{this.renderEndUserInvitesTable()}</div>
      <Paginator
        currentPage={page}
        pageToHref={this.pageToHref}
        onLastPage={!!(endUserInvites && endUserInvites.length < 10)}
      />
    </div>
  }
}


class InviteActions extends Component {
  copyEndUserInviteUrl = () => {
    this.inviteUrlInput.select()
    document.execCommand('Copy')
  }
  render(){
    const { usedAt, inviteUrl, inviteToken, deleting, onDelete } = this.props
    return <div className="BobInviteEndUsers-InviteActions">
      <IconButton
        type="cancel-circled"
        title="delete end user invite"
        spin={deleting}
        disabled={!!usedAt || !!deleting}
        onClick={() => { onDelete(inviteToken) }}
      />
      <input
        ref={node => { this.inviteUrlInput = node }}
        className="BobInviteEndUsers-InviteActions-hiddenInput"
        type="text"
        value={inviteUrl}
      />
      <IconButton
        type="mail"
        title="copy end user invite url"
        onClick={this.copyEndUserInviteUrl}
      />
    </div>
  }
}
