import { h } from 'preact'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Copier from 'components/Copier'

export default function CopyButton({
  className = '',
  copyValue,
  value = 'copy',
  copiedValue = 'copied',
  inputRef,
  ...props
}){
  return <Copier value={copyValue} inputRef={inputRef}>
    {copied =>
      <Button
        className={`CopyButton ${className}`}
        value={copied ? copiedValue : value}
        {...props}
      />
    }
  </Copier>
}

CopyButton.propTypes = {
  className: PropTypes.string,
  copyValue: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  copiedValue: PropTypes.node.isRequired,
  inputRef: PropTypes.node,
}
