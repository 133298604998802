import renderApp from 'lib/renderApp'
import App from './App'

if ('serviceWorker' in global.navigator) {
  window.addEventListener('load', () => {
    global.navigator.serviceWorker.register('/service-worker.js').then(
      registration => {
        console.log('SW registered: ', registration)
      },
      registrationError => {
        console.error('SW registration failed: ', registrationError)
      }
    )
  })
}

renderApp(App)
