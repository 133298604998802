import querystring from 'querystring'
import history from 'lib/history'

export const searchToObject = (search) => {
  return querystring.parse((search || '').replace(/^\?/, ''))
}

export const objectToSearch = (object) => {
  if (!object) return
  for (const key in object) {
    const value = object[key]
    if (value === null || value === undefined)
      delete object[key]
    if (value instanceof Location) object[key] = value.toString()
  }
  if (Object.keys(object).length === 0) return ''
  return querystring.stringify(object)
}

export const getLocation = () => {
  const pathname = window.location.pathname
  const query = searchToObject(window.location.search)
  return new Location({pathname, query})
}

export function toHref(pathname, query){
  query = objectToSearch(query)
  return pathname + (query ? ('?' + query) : '')
}

export const locationToHref = location => {
  if (typeof location === 'string') return location
  return toHref(
    location.pathname || window.location.pathname,
    location.query
  )
}

export const set = function(location){
  history.pushState(null, window.document.title, locationToHref(location))
}

export const replace = function(location){
  history.replaceState(null, window.document.title, locationToHref(location))
}

export const setQuery = function(query, location = getLocation(), _replace = false) {
  query = { ...location.query, ...query }
  !(_replace ? replace : set).call(this, { ...location, query })
}

export const setPathname = function(pathname){
  const { query } = getLocation()
  set({ pathname, query })
}

export const stripTrailingSlash = function(string){
  return string.replace(/\/+$/, '')
}

export const getPathnameFromHref = function(href){
  const a = global.document.createElement('a')
  a.href = href
  return a.pathname
}

export const normalizePathname = function(pathname){
  // https://github.com/pillarjs/path-to-regexp
  return stripTrailingSlash(decodeURI(pathname))
    // Replaces repeated slashes in the URL.
    .replace(/\/+/g, "/")
    // Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
    .normalize()
}

export class Location {
  constructor({pathname, query}){
    this.pathname = pathname
    this.query = query
    Object.freeze(this)
  }

  toString(){
    return locationToHref(this)
  }

  merge(location){
    return new this.constructor({
      ...this,
      pathname: 'pathname' in location ? location.pathname : this.pathname,
      query: {...this.query, ...location.query},
    })
  }

  withPathname(pathname){
    return this.merge({pathname})
  }

  withQuery(query){
    return this.merge({query})
  }

  replaceQuery(query){
    return new this.constructor({
      pathname: this.pathname,
      query,
    })
  }
}
