import api from '../api'

export default function({ email }) {
  this.setState({
    addingEmail: true,
    addingEmailError: undefined,
  })

  return api.addEmail({ email }).then(
    ({email, verified}) => {
      const emails = {...this.getState().emails, [email]: verified }
      this.setState({
        emails,
        addingEmail: undefined,
      })
    },
    addingEmailError => {
      this.setState({
        addingEmail: undefined,
        addingEmailError,
      })
    }
  )
}
