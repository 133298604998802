/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const ACCOUNT_DATA_COLUMNS = require('jlinc-shared/account_data_keys')
export const PERSONAL_DATA_KEYS = require('jlinc-shared/personal_data_keys')
export const PERSONAL_DATA_LABELS = require('jlinc-shared/personal_data_labels')
export const SHARED_PERSONAL_DATA_KEYS = require('jlinc-shared/shared_personal_data_keys')
export const VALID_CONSENT_KEYS = require('jlinc-shared/consents')
export const GDPR_CONSENT_CATEGORIES = require('jlinc-shared/GDPR_consent_categories')
export const CCPA_CONSENTS = require('jlinc-shared/CCPA_consents')
export const GDPR_CONSENTS = require('jlinc-shared/GDPR_consents')
export const ALICE_CONSENT_DESCRIPTIONS = require('jlinc-shared/alice_consent_descriptions')
export const BOB_CONSENT_DESCRIPTIONS = require('jlinc-shared/bob_consent_descriptions')
export const VALID_COMMUNICATION_CHANNEL_KEYS = require('jlinc-shared/communication_channels')
export const VALID_COMMUNICATION_CHANNEL_FREQUENCIES = require('jlinc-shared/communication_channel_frequencies')
export const COMMUNICATION_CHANNEL_TITLES = require('jlinc-shared/communication_channel_titles')
export const DEFAULT_DEFAULT_ACCOUNT_DATA = require('jlinc-shared/default_account_data')

export const HIDDEN_CONSENT_CATEGORIES = []
export const HIDDEN_CONSENTS = ['Specific Product Marketing']

export function isCustomPersonalDataField(field){
  return field.match(/^_.+$/)
}

export function getLabelForPersonalDataField(field){
  return isCustomPersonalDataField(field) ? field.slice(1) : PERSONAL_DATA_LABELS[field] || field
}

function getValidConsentKeys() {
  return VALID_CONSENT_KEYS
}

function getConsentDescriptionsWithOrgName({ consentDescriptions, organizationName }) {
  const descriptionsWithOrgName = {}
  getValidConsentKeys().forEach(key => {
    descriptionsWithOrgName[key] = consentDescriptions[key]
      .replace(/\[Organi(z|s)ation\]/g, organizationName)
  })
  return descriptionsWithOrgName
}

export const getBobConsentDescriptionsWithOrgName = ({ organizationName }) =>
  getConsentDescriptionsWithOrgName({
    consentDescriptions: BOB_CONSENT_DESCRIPTIONS,
    organizationName,
  })

export const getAliceConsentDescriptionsWithOrgName = ({ organizationName }) =>
  getConsentDescriptionsWithOrgName({
    consentDescriptions: ALICE_CONSENT_DESCRIPTIONS,
    organizationName,
  })

export const filterConsents = ({ consents, requestedConsents }) => {
  const validConsentKeys = getValidConsentKeys()
  return consents.filter(consent =>
    !HIDDEN_CONSENTS.includes(consent) &&
    validConsentKeys.includes(consent) &&
    (!requestedConsents || (requestedConsents[consent] && requestedConsents[consent].enabled))
  )
}

export const getConsentsByCategory = ({ requestedConsents }) => {
  const consentsByCategory = {}

  Object.entries(GDPR_CONSENT_CATEGORIES).forEach(([category, consents]) => {
    if (HIDDEN_CONSENT_CATEGORIES.includes(category)) return
    consentsByCategory[category] = filterConsents({ consents, requestedConsents })
    if (consentsByCategory[category].length === 0) delete consentsByCategory[category]
  })

  return consentsByCategory
}

export const getCCPAConsents = ({ requestedConsents }) => {
  return filterConsents({
    consents: CCPA_CONSENTS,
    requestedConsents,
  })
}

export const getGDPRConsents = ({ requestedConsents }) => {
  return filterConsents({
    consents: GDPR_CONSENTS,
    requestedConsents,
  })
}

export const areThereStagedConsentChanges = ({ type, changes = {} }) => {
  const consents = type === 'CCPA' ? CCPA_CONSENTS : GDPR_CONSENTS
  return consents.some(consent => consent in changes)
}

export const arrayToObjectWithValue = (array, value) => {
  const object = {}
  array.forEach(key => { object[key] = value })
  return object
}

export const SHARED_PERSONAL_DATA_PROP_TYPE = PropTypes.shape(
  arrayToObjectWithValue(SHARED_PERSONAL_DATA_KEYS, PropTypes.bool)
)

export const PERSONAL_DATA_PROP_TYPE = PropTypes.shape(
  arrayToObjectWithValue(PERSONAL_DATA_KEYS, PropTypes.string)
)

export const CONSENTS_PROP_TYPE = PropTypes.shape(
  arrayToObjectWithValue(VALID_CONSENT_KEYS, PropTypes.bool)
)

export const COMMUNICATION_CHANNELS_PROP_TYPE = PropTypes.shape(
  arrayToObjectWithValue(VALID_COMMUNICATION_CHANNEL_KEYS, PropTypes.shape({
    enabled: PropTypes.bool,
    frequency: PropTypes.string,
  }))
)

export const REQUESTED_COMMUNICATION_CHANNELS_PROP_TYPE = PropTypes.shape(
  arrayToObjectWithValue(VALID_COMMUNICATION_CHANNEL_KEYS, PropTypes.bool)
)

export const REQUESTED_CONSENTS_PROP_TYPE = PropTypes.objectOf(
  PropTypes.shape({
    enabled: PropTypes.bool,
    description: PropTypes.string,
  })
)

export const ALICE_SISA_NOTICE = (
  `This Standard Information Sharing Agreement (SISA) lets you control how information about you may be held, shared and processed by [Organisation], which has signed this SISA.

  For more information, see the [SISA Summary]

  By clicking Sign SISA I agree to share my personal data with [Organisation]${!isDataYogi ? ' under my control using JLINC' : ''}.`
)

export const DATAYOGI_ALICE_SISA_NOTICE = (
  `The Standard Information Sharing Agreement is the contract that governs every data exchange between you and the organisation that is inviting you, or you are inviting to a trustworthy data sharing relationship.

  [Organisation] has pre-signed this standard information sharing agreement as the ‘Data Custodian’ and thereby agrees to abide by the data sharing controls and permissions that you the ’Data Rights Holder’ set in [Organisation].

  [Organisation] automatically generates an event (audit log record) each time you change any setting or content in your data and share that. That audit log is shared by both parties to the agreement in order that trust in the system can develop over time, and contractual recourse can be taken if either party fails to meet the obligations they have signed up to.`
)

export const ALICE_SISA_SUMMARY = (
  `A Standard Information Sharing Agreement (SISA) gives you a way to manage your personal data across the Internet by controlling exactly what each party may do with your information.

  [Organisation] has signed this SISA as the "Data Custodian", and thereby agrees to abide by the data sharing controls and permissions that you, the "Data Rights Holder", set${isDataYogi ? '' : ' using JLINC technology'}.

  JLINC automatically generates a digitally signed “SISA Event”, and stores a copy for you, each time you change any setting for your personal data, permissions, communication preference or marketing consent.

  A digital proof of each SISA Event is also written to a public audit record, which will determine the reputation of each Data Custodian under the SISA.

  By signing the SISA you agree to share your data under your control with this Data Custodian${isDataYogi ? '' : ' using JLINC'}.`
)

export const BOB_SISA_NOTICE = (
  `This Standard Information Sharing Agreement (SISA) lets individual users who are the "Data Right Holders", directly control how information about them may be held, shared and processed by a "Data Custodian".

  By clicking Sign SISA the authorized party acting on behalf of [Organisation] as a Data Custodian agrees to maintain personal data and permissions under the automated control of individual Data Right Holders using JLINC technology as specified under the provisions of the SISA.`
)

export const BOB_SISA_SUMMARY = (
  `A Standard Information Sharing Agreement (SISA) provides a way for individual users to manage their personal data across the Internet by setting exactly what each party may do with it.

  By signing this SISA as the "Data Custodian", you agree to abide by the data sharing controls and permissions that each individual "Data Rights Holder" transmits to you using JLINC technology.

  JLINC automatically generates a digitally signed “SISA Event”, and stores a copy on jlinc.io for you, each time one of your connected users changes a setting for their personal data, permissions, communication preference or marketing consent.

  A digital proof of each SISA Event is also written to a public audit record, which will verify each Data Custodian's reputation under the SISA.

  By signing the SISA you agree to uphold the automated personal data exchange, permissions and preferences instructions that your system receives from individuals using JLINC.`
)

export const FULL_SISA_URL = 'https://sisa.jlinc.org/v1/3b39160c2b9ae7b2ef81c3311c7924f1c4d4fa9ca47cfe7c840c9852b50d68d5'


export const BISA_NOTICE = (
  `This Business Information Sharing Agreement (BISA) provides an automated data exchange framework with [Organization], which has also signed this BISA.

  See the [BISA Summary] for more details

  By clicking Sign BISA I agree to enter into a data sharing framework agreement with [Organization] using JLINC.`
)

export const BISA_SUMMARY = (
  `A Business Information Sharing Agreement (BISA) gives you a way to manage your data by controlling exactly what each party may do with it.

  [Organization] has also signed this BISA, and thereby agrees to abide by the automated data sharing controls and permissions defined.

  JLINC generates a digitally signed “BISA Event”, and stores a copy for you, each time either party changes any setting.

  A digital proof of each BISA Event is also written to an audit record, which provides legally binding evidence of the agreement and contributes to the reputation of each party under the BISA.

  By signing the BISA you agree to exchange data with [Organization] using JLINC.`
)

export const FULL_BISA_URL = 'https://sisa.jlinc.org/v1/3b39160c2b9ae7b2ef81c3311c7924f1c4d4fa9ca47cfe7c840c9852b50d68d5'

export const COMMUNICATION_CHANNELS_HELP = (
  `
    When enabling a communication channel, please
    make sure to enter and share the corresponding
    personal data field in order to recieve communciations.
  `
)

export const DECORATED_COMMUNICATION_CHANNEL_TITLES = (() => {
  const value = {...COMMUNICATION_CHANNEL_TITLES}
  ;['google', 'whatsapp', 'instagram', 'facebook'].forEach(key => {
    value[key] += ' 👁️'
  })
  ;['twitter', 'linkedin'].forEach(key => {
    value[key] += ' 👣'
  })
  return Object.freeze(value)
})()
