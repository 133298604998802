import metadata from 'libphonenumber-js/metadata.mobile.json'
import * as libphonenumber from 'libphonenumber-js/core'
import ALL_COUNTRIES, { SORTED_COUNTRY_CODES } from 'lib/countries'

export const COUNTRIES = Object.freeze(
  SORTED_COUNTRY_CODES
    .map(countryCode => {
      const country = {
        countryCode,
        callingCode: getCountryCallingCode(countryCode),
        name: ALL_COUNTRIES[countryCode],
      }
      return country
    })
    .filter(country => !!country.callingCode)
)

export function getCountryCallingCode(countryCode){
  try{
    return libphonenumber.getCountryCallingCode(countryCode, metadata)
  }catch(error){
    if (error.message.includes('Unknown country')) return
    throw error
  }
}

export function humanizePartialPhoneNumber(partialPhoneNumber, countryCode){
  return new libphonenumber.AsYouType(countryCode, metadata).input(partialPhoneNumber)
}

export function normalizePhoneNumber(value){
  if (typeof value !== 'string') return
  const variations = [value, `+1${value}`, `+${value}`]
    .map(variation => libphonenumber.parsePhoneNumberFromString(variation, metadata))
    .filter(phoneNumber => !!phoneNumber)

  const validVariation = variations.find(variation => variation.isValid())
  if (validVariation) return validVariation.number

  const possibleVariation = variations.find(variation => variation.isPossible())
  if (possibleVariation) return possibleVariation.number

  const longShot = variations[0]
  return longShot && longShot.number
}

export function normalizePartialPhoneNumber(partialPhoneNumber){
  const normalizedPartialNumber = libphonenumber.parseIncompletePhoneNumber(partialPhoneNumber)
  const normalizedPhoneNumber = normalizePhoneNumber(normalizedPartialNumber)
  return normalizedPhoneNumber || normalizedPartialNumber
}

export function getCountryCallingCodeFromPartialPhoneNumber(value){
  const phoneNumber = libphonenumber.parsePhoneNumberFromString(value, metadata)
  if (phoneNumber) return phoneNumber.countryCallingCode
}

export function getCountryCodesForPartialPhoneNumber(value){
  const countryCallingCode = getCountryCallingCodeFromPartialPhoneNumber(value)
  return (metadata.country_calling_codes[countryCallingCode] || []).slice()
}

export function stripCountryCallingCode(value){
  const phoneNumber = libphonenumber.parsePhoneNumberFromString(value, metadata)
  if (phoneNumber) return phoneNumber.nationalNumber
}

export function isValidMobilePhone(value){
  if (typeof value !== 'string' || value === '') return false
  const phoneNumber = libphonenumber.parsePhoneNumberFromString(value, metadata)
  // isValid rejects non-mobile number but isPossible seems to be a more soft isValid
  // so we're allowing possible numbers here and relying on Twilio to ensure this
  // is a valid mobile phone
  const valid = !!phoneNumber && phoneNumber.isPossible()
  return valid
}
