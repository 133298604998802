import { h } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import useToggle from 'lib/useToggleHook'

import Link from 'components/Link'
import './index.sass'

export default function BreadCrumbs({ className, crumbs, disabled, ...props }) {
  const [unloading, setUnloading, unsetUnloading] = useToggle(false)
  const [renderedCrumbs, setRenderedCrumbs] = useState(crumbs)
  const ref = useRef()
  useEffect(
    () => {
      if (unloading) return
      const firstLeaving = renderedCrumbs.findIndex((crumb, index) =>
        !crumbs[index] || crumbs[index].key !== crumb.key
      )
      const update = () => {
        clearTimeout(ref.timeout)
        setRenderedCrumbs(crumbs)
        unsetUnloading()
        scrollRightFor(ref.current, 200)
      }
      if (firstLeaving !== -1){
        setUnloading()
        setRenderedCrumbs(renderedCrumbs.slice(0, firstLeaving))
        ref.timeout = setTimeout(update, 200)
      }else{
        update()
      }
    },
    [unloading, ...crumbs]
  )

  return <div {...props} ref={ref} className={classNames('BreadCrumbs', { className })}>
    <TransitionGroup component="div">
      {renderedCrumbs
        .map((crumb, index) =>
          <CSSTransition {...{
            key: crumb.key,
            timeout: 200,
            classNames: 'BreadCrumbs-transition',
            onEnter(node){
              node.style.maxWidth = 'none'
              const width = node.clientWidth
              node.style.maxWidth = null
              setClientWidth(node, width)
            },
            onExit(node){
              setClientWidth(node, node.clientWidth)
            },
          }}>
            <Link {...{
              ...crumb,
              disabled,
              className: 'BreadCrumbs-Crumb',
              style: { zIndex: 100 - index },
            }}>
              <span>{crumb.value}</span>
              <span/>
            </Link>
          </CSSTransition>
        )
        .reverse()
      }
    </TransitionGroup>
  </div>
}
const setClientWidth = (node, width) => {
  node.style.setProperty('--client-width', `${width}px`)
}

BreadCrumbs.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  crumbs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
    ...Link.propTypes,
  })).isRequired,
}


function scrollRightFor(node, ms){
  const scrollRight = () => { node.scrollLeft = 9999999 }
  const scollInterval = setInterval(scrollRight, 0)
  const cancel = () => { clearInterval(scollInterval) }
  setTimeout(cancel, ms)
  return cancel
}
