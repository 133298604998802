import { h } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'
import BobLayout from 'components/BobLayout'
import OneColumn from 'components/OneColumn'

class NotFoundPage extends Page {

  static pageTitle = 'Not Found'

  render() {
    return <BobLayout
      className="NotFoundPage"
      location={this.props.location}
      organizationProfile={this.props.organizationProfile}
    >
      <OneColumn>
        <h1>Page Not Found</h1>
      </OneColumn>
    </BobLayout>
  }
}

export default bindToAppState(
  ['organizationProfile'],
  NotFoundPage,
)
