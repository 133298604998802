import { h } from 'preact'
import { useErrorBoundary } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import WYSIWYGEditor from 'components/WYSIWYGEditor'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'

export default function WYSIWYGInput({
  className,
  disabled,
  unsaved,
  placeholder,
  value,
  onInput,
  onChange,
  onUploading,
  noUploads,
  autoFocus,
  ...props
}){
  const [error, dismissError] = useErrorBoundary()
  if (error) console.error('WYSIWYGInput render error', error)
  return <div {...{
    ...props,
    className: classNames('WYSIWYGInput', { unsaved, className })
  }}>
    {error
      ? <ErrorMessage error={error} onDismiss={dismissError}/>
      : <WYSIWYGEditor {...{
        disabled,
        unsaved,
        placeholder,
        value,
        onInput,
        onChange,
        onUploading,
        noUploads,
        autoFocus,
      }}/>
    }
  </div>
}

WYSIWYGInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  unsaved: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onUploading: PropTypes.func,
  noUploads: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

WYSIWYGInput.trim = function(html){
  if (typeof html !== 'string') return ''
  const lineBreak = '<p>&nbsp;</p>'
  html = html.trim()
  while(html.startsWith(lineBreak)) html = html.slice(lineBreak.length, html.length).trim()
  while(html.endsWith(lineBreak)) html = html.slice(0, html.length - lineBreak.length).trim()
  return html
}
