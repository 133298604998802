import { h } from 'preact'

import classNames from 'lib/classNames'

import TextInput from 'components/TextInput'

export default function DatePicker({className, ...props}) {
  return <TextInput {...{
    ...props,
    className: classNames('DatePicker', {className}),
    type: 'date',
  }}/>
}
