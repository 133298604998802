import { h } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'

import BobLayout from 'components/BobLayout'
import OneColumn from 'components/OneColumn'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import BuyingInterestForm from 'components/BuyingInterestForm'
import BuyingInterestList from 'components/BuyingInterestList'

import './index.sass'

class BusinessMarketBuyPage extends Page {

  static pageTitle = 'Business - Marketplace Buy'

  render() {
    const {
      location,
      takeAction,
      appAction,
      organizationProfile,
      ['buyingInterests:business:draftBuyingInterest']: newBuyingInterest,
      ['buyingInterests:business:creating']: creatingBuyingInterest,
      ['buyingInterests:business']: buyingInterests,
      ['buyingInterests:business:loading:error']: loadingBuyingInterestsError,
      ['buyingInterests:business:deleting:error']: deletingBuyingInterestError,
      ['buyingInterests:business:creating:error']: creatingBuyingInterestError,
    } = this.props

    const myBuyingInterests = (Object.values(buyingInterests || []))
      .filter(buyingInterest => buyingInterest.organization_apikey === organizationProfile.apikey)

    return <BobLayout
      className="BusinessMarketBuyPage"
      location={location}
      crumbs={['Business', 'Market Buy']}
      organizationProfile={organizationProfile}
    >
      <OneColumn>
        <Header size="md" underlined className="BusinessMarketBuyPage-header">
          Create buying interest
        </Header>
        <ErrorMessage error={creatingBuyingInterestError} />
        <BuyingInterestForm
          value={newBuyingInterest}
          creating={creatingBuyingInterest}
          clear={appAction('buyingInterests.clear')}
          onCreate={appAction('buyingInterests.create')}
          onChange={appAction('buyingInterests.update')}
        />
        <Header size="md" underlined>Your buying interests</Header>
        <ErrorMessage
          className="BusinessMarketBuyPage-currentBuyingInterestsError"
          error={loadingBuyingInterestsError || deletingBuyingInterestError}
        />
        <BuyingInterestList
          buyingInterests={myBuyingInterests}
          onDelete={uid => { takeAction('buyingInterests.del', { uid }) }}
          onEdit={appAction('buyingInterests.edit')}
        />
      </OneColumn>
    </BobLayout>
  }
}

export default bindToAppState(
  [
    'organizationProfile',
    'buyingInterests:business:draftBuyingInterest', // newBuyingInterest,
    'buyingInterests:business:creating', // creatingBuyingInterest,
    'buyingInterests:business', // buyingInterests,
    'buyingInterests:business:loading:error', // loadingBuyingInterestsError,
    'buyingInterests:business:deleting:error', // deletingBuyingInterestError,
    'buyingInterests:business:creating:error', // creatingBuyingInterestError,
  ],
  BusinessMarketBuyPage,
  props => {
    props.takeAction('buyingInterests.load', {type: 'business'})
  },
)
