import logger from 'lib/logger'

export default function(changes){
  logger.debug('stageOrganizationProfileChanges', changes)

  const organizationProfileChanges = this.getState().organizationProfileChanges || {}
  const organizationProfile = this.getState().organizationProfile
  Object.assign(organizationProfileChanges, changes)
  for (const key in organizationProfileChanges) {
    const current = organizationProfile[key]
    const newStagedValue = organizationProfileChanges[key]
    if (
      newStagedValue === undefined ||
      (current === null || current === "" || current === undefined) &&
      (newStagedValue === null || newStagedValue === "" || newStagedValue === undefined)
      || current === newStagedValue
    ) {
      delete organizationProfileChanges[key]
    }
  }
  this.setState({
    organizationProfileChanges: {...organizationProfileChanges},
  })
}
