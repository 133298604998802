import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import moment from 'moment'
import Chart from 'chart.js'

import Header from 'components/Header'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'

import { VALID_CONSENT_KEYS, PERSONAL_DATA_KEYS } from 'lib/accountDataSpec'

import './index.sass'

const validateReportConsentObject = consentObject => {
  Object.keys(consentObject).forEach(consentType => {
    if (!VALID_CONSENT_KEYS.includes(consentType)) {
      throw Error(`invalid consent type: ${consentType}`)
    }
  })
}

const validateReportPersonalDataObject = personalDataObject => {
  Object.keys(personalDataObject).forEach(personalDataKey => {
    if (!PERSONAL_DATA_KEYS.includes(personalDataKey)) {
      throw Error(`invalid personal data key: ${personalDataKey}`)
    }
  })
}

const seventeenDistinctColors = [
  'rgb(255,0,0, 0.2)',
  'rgb(242,0,0, 0.2)',
  'rgb(76,0,0, 0.2)',
  'rgb(255,200,191, 0.2)',
  'rgb(255,170,0, 0.2)',
  'rgb(238,255,0, 0.2)',
  'rgb(150,153,115, 0.2)',
  'rgb(0,255,34, 0.2)',
  'rgb(0,102,27, 0.2)',
  'rgb(0,255,238, 0.2)',
  'rgb(0,51,64, 0.2)',
  'rgb(0,170,255, 0.2)',
  'rgb(0,102,255, 0.2)',
  'rgb(51,0,77, 0.2)',
  'rgb(234,191,255, 0.2)',
  'rgb(204,0,255, 0.2)',
  'rgb(255,0,136, 0.2)'
]

export default class BobConsentReport extends Component {

  static propTypes = {
    consentReport: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        consents: PropTypes.objectOf(validateReportConsentObject),
        lastUpdated: PropTypes.string,
        personal_data: PropTypes.objectOf(validateReportPersonalDataObject),
      })
    ).isRequired,
    consentDescriptions: PropTypes.objectOf(validateReportConsentObject).isRequired,
  }

  componentDidMount() {
    const { consentReport } = this.props
    const consentStatistics = {}
    consentReport.forEach(userConsentReport => {
      Object.keys(userConsentReport.consents).forEach(key => {
        if (!consentStatistics[key]) {
          consentStatistics[key] = 1
        } else {
          consentStatistics[key]++
        }
      })
    })

    const consentKeys = Object.keys(consentStatistics)
    const datasets = consentKeys.map(key => {
      const backgroundColor = seventeenDistinctColors[VALID_CONSENT_KEYS.indexOf(key)]
      return {
        label: key,
        backgroundColor,
        borderColor: 'grey',
        borderWidth: 1,
        data: [consentStatistics[key]],
      }
    })
    const data = {
      labels: ['Permission stats'],
      datasets,
    }
    if (this.consentStatistics) {
      new Chart(this.consentStatistics, {
        type: 'bar',
        data,
        options: {
          animation: false,
          scales: {
            xAxes: [{
              barPercentage: 0.4
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function(value) { if (Number.isInteger(value)) { return value } },
                stepSize: 1
              }
            }]
          }
        }
      })
    }
  }

  state = { chartHidden: false, userReports: {}, hideShowButtonText: 'Show all' }

  toggleChart = () => {
    this.setState({chartHidden: !this.state.chartHidden})
  }

  toggleUserReportConsents = ({event, index}) => {
    const { userReports } = this.state
    if (!event || ![...event.target.classList].includes('ExpandableText')) {
      if (this.state.userReports[index]) {
        this.setState({
          userReports: {...userReports, [index]: false}
        })
        if (Object.values(this.state.userReports).every(val => val === false)) {
          this.setState({ hideShowButtonText: 'Show all' })
        }
      } else {
        this.setState({
          userReports: {...userReports, [index]: true}
        })
        if (Object.values(this.state.userReports).every(val => val === true)) {
          this.setState({ hideShowButtonText: 'Hide all' })
        }
      }
    }
  }

  toggleAllUserReportConsents = () => {
    const setUserReportsTo = bool => this.props.consentReport
      .reduce((reportBooleans, userReport, index) => {
        return {...reportBooleans, [index]: bool}
      }, {})

    if (this.state.hideShowButtonText === 'Show all') {
      this.setState({ userReports: setUserReportsTo(true) })
      this.setState({ hideShowButtonText: 'Hide all' })
    } else {
      this.setState({ userReports: setUserReportsTo(false) })
      this.setState({ hideShowButtonText: 'Show all' })
    }
  }

  render(){
    const { consentReport, consentDescriptions } = this.props
    const { userReports } = this.state

    if (consentReport.length === 0) {
      return <Header size="xs">
        You have no users with permissions enabled
      </Header>
    }

    const userReportList = consentReport.map((userConsentReport, index) =>
      <UserReport
        report={userConsentReport}
        consentDescriptions={consentDescriptions}
        toggle={event => this.toggleUserReportConsents({event, index})}
        isOpen={userReports[index]}
      />
    )
    const chartClassName = `BobConsentReport-chart${this.state.chartHidden ? '-hidden' : ''}`

    return <div className="BobConsentReport">
      <Header size="md" underlined>Permission Report</Header>
      <Button
        onClick={this.toggleChart}
        className="BobConsentReport-toggleChart"
        type="none"
      >
        { `${this.state.chartHidden ? 'Show' : 'Hide'} Chart` }
      </Button>
      <div className={chartClassName}>
        <canvas ref={node => { this.consentStatistics = node }} width="400" height="200"/>
      </div>
      <div className="BobConsentReport-subHeaderRow">
        <Button
          onClick={this.toggleAllUserReportConsents}
          type="none"
        >
          { this.state.hideShowButtonText }
        </Button>
        <Header size="sm">Customer ID</Header>
        <Header size="sm">Email</Header>
        <Header size="sm">First Name</Header>
        <Header size="sm">Last Name</Header>
        <Header size="sm">Last Updated</Header>
      </div>
      { userReportList }
    </div>
  }
}

class UserReport extends Component {

  static propTypes = {
    report: PropTypes.shape({
      id: PropTypes.string.isRequired,
      consents: PropTypes.objectOf(validateReportConsentObject).isRequired,
      lastUpdated: PropTypes.string.isRequired,
      personal_data: PropTypes.objectOf(validateReportPersonalDataObject).isRequired,
    }).isRequired,
    consentDescriptions: PropTypes.objectOf(validateReportConsentObject).isRequired,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
  }

  render() {
    const {
      report: {
        id,
        consents,
        lastUpdated,
        personal_data: { firstname, lastname, email }
      },
      consentDescriptions,
      isOpen,
      toggle,
    } = this.props

    const iconType = isOpen ?
      "down-open" :
      "right-open"

    const topRow = <div
      className="BobConsentReport-Report-topRow flex-row"
      onClick={toggle}
    >
      <div className="BobConsentReport-Report-icons">
        <Icon type="master-data" />
        <Icon type={iconType} />
      </div>
      <Header
        size="sm"
        className="BobConsentReport-Report-idHeader"
      >
        <Tooltip text={id}>
          {id.slice(0, 8)}...
        </Tooltip>
      </Header>
      <Header size="sm">{email}</Header>
      <Header size="sm">{firstname}</Header>
      <Header size="sm">{lastname}</Header>
      <Header className="BobConsentReport-Report-updatedHeader" size="sm">
        { moment(lastUpdated).format('MMM Do YYYY h:mmA') }
      </Header>
    </div>

    const consentList = Object.entries(consents).map(([consentType, dateEnabled]) =>
      <Consent
        consentType={consentType}
        description={consentDescriptions[consentType]}
        dateEnabled={dateEnabled}
      />
    )

    return <div className="BobConsentReport-Report BobConsentReport-row">
      { topRow }
      <div className="BobConsentReport-Report-consentList">
        { isOpen ? consentList : null }
      </div>
    </div>
  }
}

const Consent = ({consentType, description, dateEnabled}) => (
  <div className="BobConsentReport-Report-Consent">
    <Header
      size="sm"
      className="BobConsentReport-Report-Consent-nameHeader"
    >
      { consentType }
    </Header>
    <span className="BobConsentReport-Report-Consent-text">
      : { description }
    </span>
    <Header
      size="xs"
      className="BobConsentReport-Report-Consent-timeHeader"
    >
      { moment(dateEnabled).format('MMM Do YYYY h:mmA') }
    </Header>
  </div>
)

Consent.propTypes = {
  consentType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dateEnabled: PropTypes.string.isRequired,
}
