import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import PropTypes from 'prop-types'

export default function LazyLoad({
  loader,
  props,
  whenLoading = defaultWhenLoading,
  whenError = defaultWhenError,
  whenLoaded = defaultWhenLoaded,
}){
  const { Component, loadError } = loader
  const [, setLoading] = useState(!Component && !loadError)
  useEffect(
    () => {
      if (Component || loadError) return
      if (!loader.promise) loader.promise = loader()
      loader.promise
        .then(
          exports => {
            loader.Component = exports.default || exports
          },
          error => {
            loader.loadError = error
            console.error('Error lazy loading component', error)
          },
        )
        .then(() => {
          delete loader.promise
          setLoading(false)
        })
    },
    [loader]
  )

  if (Component) return whenLoaded(Component, props)
  if (loadError) return whenError(loadError)
  return whenLoading()
}

LazyLoad.propTypes = {
  loader: PropTypes.func.isRequired,
  props: PropTypes.object,
  whenLoading: PropTypes.func,
  whenError: PropTypes.func,
  whenLoaded: PropTypes.func,
}

const defaultWhenLoading = () => null

const defaultWhenError = () => null

const defaultWhenLoaded = (Component, props) =>
  <Component {...props}/>
