import { getLocation, replace, set } from 'lib/location'

export const publish = function(){
  const lastLocation = this.getState().location
  const location = getLocation()
  if (
    lastLocation &&
    location.pathname === lastLocation.pathname &&
    JSON.stringify(location.query) === JSON.stringify(lastLocation.query)
  ) return
  this.setState({ location })
}

export { replace, set }
