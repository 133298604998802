import api from '../api'

export default function(key, value){
  const { organizationProfile } = this.getState()

  const changes = {
    requested_data: {
      ...organizationProfile.requested_data,
      [key]: value,
    }
  }

  this.setState({
    updatingOrganizationRequestedData: true,
    updatingOrganizationRequestedDataError: null,
  })

  return api.updateOrganizationProfile({ changes }).then(
    ({organizationProfile}) => {
      this.setState({
        updatingOrganizationRequestedData: false,
        organizationProfile,
      })
    },
    updatingOrganizationRequestedDataError => {
      this.setState({
        updatingOrganizationRequestedData: false,
        updatingOrganizationRequestedDataError,
      })
    }
  )
}
