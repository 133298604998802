import { h } from 'preact'

import './index.sass'

const BetaDisclaimer = () =>
  <div className="BetaDisclaimer">
    Please note this is a beta version of Jlinc. Features are subject to change.
    If you encounter any bugs, glitches, or have feedback, shoot us an email
    at <a tabIndex={-1} href="mailto:support@jlinclabs.com">support@jlinclabs.com</a>.
  </div>

export default BetaDisclaimer
