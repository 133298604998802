import { h, Component, createContext } from 'preact'
import { useEffect, useContext, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import logger from 'lib/logger'
import useForceUpdate from 'lib/useForceUpdate'
import useFirstRender from 'lib/useFirstRender'

const AppStateContext = createContext()

global.DEBUG = global.DEBUG || {}

const consoleStyles = ['color: teal;', 'color: inherit;']

export class AppState {

  constructor({ actions, init }){
    this.state = {}
    this.changedKeys = new Set()
    this.subscribers = []
    this.actions = actions
    if (init) {
      const context = this.createActorContext('init')
      init.call(context)
      this.changedKeys.clear()
    }
  }

  getState(actor){ // eslint-disable-line
    // logger.trace(`[appState][${actor}].getState`)
    return this.state
  }

  setState(actor, changes){
    this.throwIfPublishing('setState')
    logger.debugWithObjectCollapsed(
      `%c[appState]%c[${actor}]%c.setState`,
      ...consoleStyles,
      'color: lawngreen;',
      changes,
    )
    for(const key in changes){
      if (this.state[key] === changes[key]) continue
      this.changedKeys.add(key)
      if (typeof changes[key] === 'undefined'){
        delete this.state[key]
      }else{
        this.state[key] = changes[key]
      }
    }
    this.publish(actor)
  }

  resetState(actor){
    this.throwIfPublishing('resetState')
    logger.debug(
      `%c[appState]%c[${actor}]%c.resetState`,
      ...consoleStyles,
      'color: red;',
    )
    for (const key in this.state) {
      this.changedKeys.add(key)
      delete this.state[key]
    }
    this.publish(actor)
  }

  createActorContext(actor){
    return {
      getState: this.getState.bind(this, actor),
      setState: this.setState.bind(this, actor),
      resetState: this.resetState.bind(this, actor),
      takeAction: this.takeAction.bind(this, actor),
      deleteKeys,
      mergeState,
      extendObject,
      addToSet,
      removeFromSet,
    }
  }

  takeAction = (actor, actionName, ...args) => {
    this.throwIfPublishing('takeAction')
    // console.warn('AppStateProvider#takeAction', this, [actor, actionName, ...args])
    logger.debugWithObjectCollapsed(
      `%c[appState]%c[${actor}]%c.takeAction('${actionName}')`,
      ...consoleStyles,
      'color: deepskyblue;',
      args
    )
    const parts = actionName.split('.')
    let action
    if (parts.length === 1){
      action = this.actions[parts[0]]
    }else{
      const controller = this.actions[parts[0]] || {}
      action = controller[parts[1]]
    }
    if (typeof action !== 'function')
      throw new Error(`[appState][${actor}] called undefined action "${actionName}"`)
    const context = this.createActorContext(`actions.${actionName}`)
    return action.call(context, ...args)
  }

  subscribeToAppStateChanges = (handler) => {
    this.subscribers.push(handler)
  }

  unsubscribeFromAppStateChanges = (handler) => {
    this.subscribers = this.subscribers.filter(h => h !== handler)
  }

  throwIfPublishing(task){
    if (this.isPublishing) throw new Error(`[appState] refusing to ${task} while publishing`)
  }

  publish(actor){
    this.throwIfPublishing('publish')
    if (this.changedKeys.size === 0) return
    if (this.publishingTimeout) return

    logger.traceWithObjectCollapsed(
      `%c[appState]%c[${actor}]%c.schedulePublish`,
      ...consoleStyles,
      'color: lime;',
      {}
    )
    this.publishingTimeout = setTimeout(() => { this._publish(actor) }, 0)
  }

  _publish(actor){
    this.throwIfPublishing('publish')
    this.isPublishing = true
    const changedKeys = Object.freeze([...this.changedKeys])
    // console.warn('AppStateProvider publishing', { changedKeys, subs: this.subscribers.length })
    logger.traceWithObjectCollapsed(
      `%c[appState]%c[${actor}]%c.publish`,
      ...consoleStyles,
      'color: green;',
      {changedKeys}
    )
    this.changedKeys = new Set()
    try{
      this.subscribers.forEach(handler => {
        // if the previous handler causes subsequent handlers to unsub
        // don't call them
        if (this.subscribers.includes(handler)) handler(changedKeys)
      })
    }finally{
      this.isPublishing = false
      delete this.publishingTimeout
    }
  }
}

function extendObject(appStateKey, changes){
  const merged = {
    ...(this.getState()[appStateKey] || {}),
    ...changes,
  }
  for (const key in changes)
    if (typeof merged[key] === 'undefined')
      delete merged[key]
  this.setState({ [appStateKey]: merged })
}

function deleteKeys(...patterns){
  const keys = Object.keys(this.getState())
  const matchingKeys = keys.filter(key =>
    patterns.some(pattern => key.match(pattern))
  )
  const newState = {}
  matchingKeys.forEach(key => { newState[key] = undefined })
  this.setState(newState)
}

function mergeState(changes){
  const appState = this.getState()
  const newState = {}
  for (const key in changes){
    const currentValue = appState[key]
    const newValue = changes[key]
    if (typeof newValue !== 'object') continue
    if (typeof currentValue !== 'object'){
      newState[key] = newValue
      continue
    }
    newState[key] = Object.assign({}, currentValue, newValue)
    for (const subKey in newValue)
      if (typeof newValue[subKey] === 'undefined')
        delete newState[key][subKey]
  }
  this.setState(newState)
}

function addToSet(appStateKey, members){
  const set = new Set(this.getState()[appStateKey])
  members.forEach(member => { set.add(member) })
  this.setState({[appStateKey]: set})
}

function removeFromSet(appStateKey, members){
  const set = new Set(this.getState()[appStateKey])
  members.forEach(member => { set.delete(member) })
  this.setState({[appStateKey]: set})
}

export class AppStateProvider extends Component {

  static propTypes = {
    children: PropTypes.element.isRequired,
    appState: PropTypes.instanceOf(AppState).isRequired,
  }

  constructor(props){
    super()
    this.appState = props.appState
    global.DEBUG.appState = this.appState
  }

  componentWillUnmount(){
    if (global.DEBUG.appState === this.appState) delete global.DEBUG.appState
  }

  render(){
    console.warn('AppStateProvider#render !!!!!')
    const value = {
      appState: this.appState.state,
      takeAction: this.appState.takeAction,
      subscribeToAppStateChanges: this.appState.subscribeToAppStateChanges,
      unsubscribeFromAppStateChanges: this.appState.unsubscribeFromAppStateChanges,
    }
    return <AppStateContext.Provider value={value}>
      {this.props.children}
    </AppStateContext.Provider>
  }
}

function appStateKeysMapToAppStateKeys(appStateKeysMap){
  if (appStateKeysMap === Infinity) return Infinity
  if (Array.isArray(appStateKeysMap)) return appStateKeysMap
  if (typeof appStateKeysMap === 'undefined') return []
  if (typeof appStateKeysMap === 'object') return Object.keys(appStateKeysMap)
  console.warn('unknown appStateKeysMap type', appStateKeysMap)
  return []
}

function getAppStateSubset(appState, appStateKeysMap){
  if (typeof appStateKeysMap === 'undefined') return {}
  if (appStateKeysMap === Infinity) return {...appState}
  const appStateSubset = {}
  const entries = (
    Array.isArray(appStateKeysMap)
      ? appStateKeysMap.map(key => [key, key])
      : Object.entries(appStateKeysMap)
  )
  entries.forEach(([appStateKey, subsetKey]) => {
    appStateSubset[subsetKey] = appState[appStateKey]
  })
  return appStateSubset
}

function changeKeysIncludeAppStateKeys(changedKeys, appStateKeys){
  if (appStateKeys === Infinity) return true
  return changedKeys.some(key => appStateKeys.includes(key))
}

function bindActionMethods(name, takeAction){
  return {
    takeAction: useCallback( // eslint-disable-line react-hooks/rules-of-hooks
      takeAction.bind(null, name),
      [name],
    ),
    appAction: (actionName, ...boundArgs) =>
      useCallback( // eslint-disable-line react-hooks/rules-of-hooks
        (...args) => takeAction(name, actionName, ...boundArgs, ...args),
        [name, actionName, ...boundArgs],
      )
    ,
  }
}

export function useAppActions(name = 'unknown'){
  const {takeAction} = useContext(AppStateContext)
  return bindActionMethods(name, takeAction)
}

export function useAppState(appStateKeysMap, name = 'unknown'){
  if (process.env.NODE_ENV === 'development'){
    if (name === 'unknown') {
      console.trace('useAppState not given actor name')
    }
  }
  const {
    appState,
    takeAction,
    subscribeToAppStateChanges,
    unsubscribeFromAppStateChanges,
  } = useContext(AppStateContext)
  const firstRender = useFirstRender()
  const forceUpdate = useForceUpdate()

  const appStateSubset = getAppStateSubset(appState, appStateKeysMap)
  const appStateKeys = appStateKeysMapToAppStateKeys(appStateKeysMap)

  function _subscribeToAppStateChanges(){
    logger.traceWithObjectCollapsed(
      `%c[appState]%c[${name}]%c.subscribeToAppStateChanges`,
      ...consoleStyles,
      'color: green;',
      {appStateKeys}
    )
    subscribeToAppStateChanges(onAppStateChange)
  }

  if (firstRender && (appStateKeys === Infinity || appStateKeys.length > 0))
    _subscribeToAppStateChanges()

  function onAppStateChange(changedKeys) {
    if (changeKeysIncludeAppStateKeys(changedKeys, appStateKeys)){
      forceUpdate()
    }
  }

  useEffect(() => {
    // Note: we only subscribe to appState changes here when it is
    // not the first time this component has called useAppState
    if (!firstRender) _subscribeToAppStateChanges()
    return () => {
      logger.traceWithObjectCollapsed(
        `%c[appState]%c[${name}]%c.unsubscribeFromAppStateChanges`,
        ...consoleStyles,
        'color: green;',
        {appStateKeys}
      )
      unsubscribeFromAppStateChanges(onAppStateChange)
    }
  }, appStateKeys === Infinity ? [] : appStateKeys)

  return {
    ...appStateSubset,
    ...bindActionMethods(name, takeAction),
  }
}

export function bindToAppState(getAppStateKeys, component, onMount){
  if (typeof getAppStateKeys !== 'function'){
    const appStateKeys = getAppStateKeys
    getAppStateKeys = function(){ return appStateKeys }
  }
  let name = getName(component) || '?bindToAppState?'
  return function AppStateBind(props){
    const appStateKeys = getAppStateKeys(props)
    const appState = useAppState(appStateKeys, name)
    const mergedProps = {...props, ...appState}
    useEffect(() => { if (onMount) onMount(mergedProps) })
    return h(component, mergedProps)
  }
}

const getName = component => {
  if (typeof component === 'string') return component
  if (typeof component === 'function'){
    if (component.name) return component.name
    if (component.toString().match(/^function ([^\( ]+)/)) return RegExp.$1
  }else if ('constructor' in component) {
    return getName(component.constructor)
  }
}

// DEBUG
global.DEBUG.getState = (filter = /.*/) => {
  const appState = global.DEBUG.appState.getState('DEBUG')
  const filteredAppState = {}
  for (const key in appState)
    if (filter.test(key))
      filteredAppState[key] = appState[key]
  return filteredAppState
}
global.DEBUG.setState = state => global.DEBUG.appState.setState('DEBUG', state)
global.DEBUG.takeAction = (...args) => global.DEBUG.appState.takeAction('DEBUG', ...args)
global.DEBUG.publish = () => global.DEBUG.appState.publish()
