import logger from 'lib/logger'

export default function(){
  logger.debug('saveOrganizationRequestedDataChanges')
  const {organizationProfileChanges} = this.getState()
  if (!organizationProfileChanges) return
  const {requested_data} = organizationProfileChanges
  if (!requested_data) return
  return this.takeAction('updateOrganizationProfile', {requested_data})
}
