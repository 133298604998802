import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import { isValidMobilePhone, humanizePartialPhoneNumber } from 'lib/phone'

import Button from 'components/Button'
import Icon from 'components/Icon'
import BreadCrumbs from 'components/BreadCrumbs'
import Hamburger from 'components/Hamburger'
import './index.sass'

export default class Layout extends Component {

  static propTypes = {
    currentUser: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func,
    sideNav: PropTypes.element.isRequired,
    rightContent: PropTypes.element,
    crumbs: PropTypes.array,
    onScroll: PropTypes.func,
    onResize: PropTypes.func,
  }

  componentDidMount(){
    window.document.body.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount(){
    window.document.body.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  state = { mobileMenuVisible: false }

  onScroll = event => { if (this.props.onScroll) this.props.onScroll(event) }
  onResize = event => { if (this.props.onResize) this.props.onResize(event) }

  toggleMobileMenu = () => {
    this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible })
  }

  render({
    className = '',
    currentUser,
    logout,
    crumbs,
    sideNav,
    rightContent,
    children,
    onScroll, // eslint-disable-line
    onResize, // eslint-disable-line
    ...props
  }){
    const { mobileMenuVisible } = this.state
    //change this to leftColumn
    let sideNavClassName = 'Layout-leftColumn'
    if (mobileMenuVisible) sideNavClassName += ' Layout-leftColumn-mobileMenuVisible'

    const overlay = mobileMenuVisible ?
      <Overlay onClose={this.toggleMobileMenu} /> :
      null

    return <div
      className={`Layout ${className}`}
      {...props}
    >
      <aside className={sideNavClassName}>
        {sideNav}
      </aside>
      <TopNav
        logout={logout}
        currentUser={currentUser}
        crumbs={crumbs}
        toggleMobileMenu={this.toggleMobileMenu}
      />
      {overlay}
      <div className="Layout-maincontent">
        {children}
      </div>
      <aside className="Layout-rightColumn">
        {rightContent}
      </aside>
    </div>
  }
}

class Overlay extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  onShroudClick = (event) => {
    if (event.target === this.base){
      this.props.onClose()
    }
  }

  render() {
    return <div
      className="Layout-overlay"
      onClick={this.onShroudClick}
    />
  }
}

const TopNav = ({
  logout,
  currentUser,
  crumbs,
  toggleMobileMenu
}) => {
  return <nav className="Layout-topnav">
    <div className="flex-row flex-align-center">
      <BreadCrumbs crumbs={crumbs || []} />
      <Hamburger onClick={toggleMobileMenu}/>
    </div>
    <div className="flex-grow" />
    <div className="Layout-topnav-logoutText">
      <span>Logged in as&nbsp;</span>
      <CurrentUserLogin login={currentUser.login || currentUser.email} />
    </div>
    {logout && <Button type="none" onClick={logout} title="logout" className="Layout-logoutButton">
      <Icon type="log-out" />
    </Button>}
  </nav>
}

TopNav.propTypes = {
  logout: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    login: PropTypes.string,
  }).isRequired,
  crumbs: PropTypes.array,
  toggleMobileMenu: PropTypes.func.isRequired,
}

const CurrentUserLogin = ({ login }) => {
  if (isValidMobilePhone(login)) login = humanizePartialPhoneNumber(login)
  return <span className="Layout-login">{login}</span>
}
