import api from '../api'

export default function(key, value){
  const { organizationProfile } = this.getState()
  const changes = {
    communication_channels: {
      ...organizationProfile.communication_channels,
      [key]: value
    }
  }

  this.setState({
    updatingOrganizationCommunicationChannels: true,
  })

  return api.updateOrganizationProfile({ changes }).then(
    ({organizationProfile}) => {
      this.setState({
        updatingOrganizationCommunicationChannels: false,
        organizationProfile,
      })
    },
    updatingOrganizationCommunicationChannelsError => {
      this.setState({
        updatingOrganizationCommunicationChannels: false,
        updatingOrganizationCommunicationChannelsError,
      })
    }
  )
}
