let subscribers = []

const getInt = key =>
  (key in window.sessionStorage) && parseInt(window.sessionStorage[key], 10)

function trackBackAndForwardState(){
  let oldestVisitedAt = getInt('oldestVisitedAt')
  let latestVisitedAt = getInt('latestVisitedAt')

  const visitedAt = window.history.state && window.history.state.visitedAt
  if (!visitedAt){
    history.canGoForward = true
    history.canGoBack = true
    return
  }

  if (!latestVisitedAt || visitedAt >= latestVisitedAt)
    window.sessionStorage.latestVisitedAt = latestVisitedAt = visitedAt

  if (!oldestVisitedAt)
    window.sessionStorage.oldestVisitedAt = oldestVisitedAt = visitedAt

  history.canGoForward = visitedAt < latestVisitedAt
  history.canGoBack = window.history.length > 1 && visitedAt > oldestVisitedAt
}

function onChange(event){
  history.state = window.history.state
  history.length = window.history.length
  subscribers.forEach(handler => {
    handler(window.location, event)
  })
}

function pushState(replace, stateObject, title, href){
  if (history.hrefIsCurrentHref(href)) return
  const visitedAt = Date.now()
  stateObject = {...stateObject, visitedAt}
  window.history[replace ? 'replaceState' : 'pushState'](stateObject, title, href)
  if (!replace){
    window.sessionStorage.latestVisitedAt = visitedAt
    history.canGoForward = false
    history.canGoBack = true
  }
  history.justLoaded = false
  history.natural = true
  onChange()
}

const history = {
  // natural, boolean
  // state,
  // length,
  // canGoBack,
  // canGoForward,
  back(){
    window.history.back()
  },
  forward(){
    window.history.forward()
  },
  reload(){
    window.history.go(0)
  },
  pushState(...args){
    return pushState(false, ...args)
  },
  replaceState(...args){
    return pushState(true, ...args)
  },
  subscribe(handler){
    subscribers.push(handler)
  },
  unsubscribe(handler){
    subscribers = subscribers.filter(h =>
      h !== handler
    )
  },
  expandHref(href){
    const a = window.document.createElement('a')
    a.href = href
    return a.href
  },
  hrefIsSameOrigin(href){
    return this.expandHref(href).startsWith(window.location.origin)
  },
  hrefIsCurrentHref(href){
    return this.expandHref(href) === window.location.href
  },
  visit(href){
    if (this.hrefIsSameOrigin(href)){
      return this.pushState(null, window.document.title, href)
    }else{
      return window.location = href
    }
  }
}

if (!window.history.state || !window.history.state.visitedAt){
  window.history.replaceState(
    {...history.state, visitedAt: Date.now()},
    window.document.title,
    window.location,
  )
}
trackBackAndForwardState()
history.justLoaded = true
history.natural = false
onChange()
window.addEventListener('popstate', event => {
  // history.lastPopState = Date.now()
  history.justLoaded = false
  history.natural = false
  trackBackAndForwardState()
  onChange(event)
})

export default history

global.DEBUG = global.DEBUG || {}
global.DEBUG.history = history
