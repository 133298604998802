import { h } from 'preact'
import PropTypes from 'prop-types'

import useToggle from 'lib/useToggleHook'

import Switch from 'components/Switch'
import Icon from 'components/Icon'
import Button from 'components/Button'
import './index.sass'

export default function SwitchListMember({
  label,
  description,
  descriptionDefaultOpen,
  ...props
}){
  const [descriptionOpen, , , toggleDescription] =
    useToggle(description && descriptionDefaultOpen)

  let className = `SwitchListMember`
  if (props.unsaved) className += ' SwitchListMember-unsaved'
  if (descriptionOpen) className += ' SwitchListMember-descriptionOpen'
  if (props.className) className += ` ${props.className}`

  return <div {...{...props, className}}>
    <div className="SwitchListMember-row">
      <SwitchListLabel {...{ description, label, toggleDescription, descriptionOpen }}/>
      <Switch {...props} className="" />
    </div>
    {description && descriptionOpen &&
      <div className="SwitchListMember-description">{description}</div>
    }
  </div>
}

SwitchListMember.propTypes = {
  ...Switch.propTypes,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  descriptionDefaultOpen: PropTypes.bool,
}

function SwitchListLabel({ description, label, toggleDescription, descriptionOpen }) {
  const labelClass = "SwitchListMember-label"
  if (description) {
    return <Button onClick={toggleDescription} className="SwitchListMember-toggleDescriptionButton">
      <div className={labelClass}>{label}</div>
      <Icon type={descriptionOpen ? 'down-open' : 'right-open'} size="xs" />
    </Button>
  } else {
    return <div className={labelClass}>{label}</div>
  }
}
