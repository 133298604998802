import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import { isValidMobilePhone } from 'lib/phone'
import { isValidEmail } from 'lib/validators'

import Form from 'components/Form'
import TextInput from 'components/TextInput'
import PhoneAndCountryInput from 'components/PhoneAndCountryInput'
import Header from 'components/Header'
import Button from 'components/Button'
import CopyButton from 'components/CopyButton'
import Spinner from 'components/Spinner'
import Dropdown from 'components/Dropdown'
import TextArea from 'components/TextArea'
import HelpButton from 'components/HelpButton'
import CopyTextInput from 'components/CopyTextInput'
import exampleEmail from './exampleEmail.txt'
import './index.sass'

export default class BobInviteEndUserForm extends Component {
  static propTypes = {
    organizationName: PropTypes.string,
    invitingEndUser: PropTypes.bool,
    invitedEndUserSignupUrl: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
  }

  constructor(){
    super()
    this.state = this.getInitialState()
  }

  getInitialState(){
    return {
      email: null,
      mobile: null,
      copiedUrl: false,
      copiedEmail: false,
      type: 'email',
    }
  }

  componentDidUpdate(){
    if (this.props.invitedEndUserSignupUrl) {
      if (!this.input || !this.input.base) return
      const input = this.input.base.querySelector('input')
      if (input) {
        input.focus()
        input.select()
      }
    }
  }

  onSubmit = () => {
    const { email, mobile } = this.state
    this.props.onSubmit({ email, mobile })
  }

  onReset = () => {
    if (this.timeout){
      clearTimeout(this.timeout)
      delete this.timeout
    }
    this.setState(this.getInitialState())
    this.props.onReset()
  }

  setType = type => {
    this.setState({ type })
    setTimeout(() => {
      this.base.querySelector('input[type=tel], input[type=email]').focus()
    }, 10)
  }

  render(){
    const {
      organizationName,
      invitingEndUser,
      invitedEndUserSignupUrl,
    } = this.props

    const { type, email, mobile } = this.state

    const buttonDisabled = (type === 'email' && !isValidEmail(email))
      || (type === 'mobile' && !isValidMobilePhone(mobile))

    let exampleEmailText = exampleEmail.replace(/\[Data Custodian\]/g, organizationName)
    if (invitedEndUserSignupUrl)
      exampleEmailText = exampleEmailText.replace(/\[Link\]/g, invitedEndUserSignupUrl)

    const helpIcon = <HelpButton>
      <Header size="lg">Send an Invite to Your End Users</Header>
      <p>
        Enter the email address or phone number of one of your end users into the Mobile or Email field
        and click the Invite button.
      </p>
      <p>
        Once the example is replaced with the invite URL and you see the Copy
        button, Copy the invite url and email it to your end user.
      </p>
    </HelpButton>

    const copyEmailButton = <CopyButton
      value="Copy email text"
      copyValue={exampleEmailText}
    />

    const exampleEmailButton = <HelpButton
      iconType="mail"
      buttons={[copyEmailButton]}
      onClose={() => { this.setState({ copiedEmail: false}) }}
    >
      <Header size="lg">Example Invite Email</Header>
      <p>
        Here is an example of the kind of email to send:
      </p>
      <TextArea
        ref={textarea => { this.exampleEmailTextarea = textarea }}
        resize="none"
        value={exampleEmailText}
        immutable
      />
    </HelpButton>

    const content = invitedEndUserSignupUrl
      ? <div>
        <div className="BobInviteEndUserForm-inputWithHelp">
          <CopyTextInput
            ref={input => { this.input = input }}
            value={invitedEndUserSignupUrl}
          />
          {helpIcon}
          {exampleEmailButton}
        </div>
        <div className="BobInviteEndUserForm-buttons">
          <CopyButton
            copyValue={invitedEndUserSignupUrl}
            submit
            type="primary"
          />
          <Button
            submit
            type="normal"
            value="reset"
            onClick={this.onReset}
          />
        </div>
      </div>
      : <Form onSubmit={this.onSubmit}>
        <div className="BobInviteEndUserForm-inputWithHelp">
          <Dropdown
            key="typeInput"
            options={['email', 'mobile']}
            value={type}
            onChange={this.setType}
          />
          { type === 'email' &&
            <TextInput
              key="emailInput"
              type="email"
              autoFocus
              value={email}
              required
              onInput={email => { this.setState({ email }) }}
              placeholder="you@example.com"
            />
          }
          { type === 'mobile' &&
            <PhoneAndCountryInput
              key="mobileInput"
              autoFocus
              required
              value={mobile}
              onInput={mobile => { this.setState({ mobile }) }}
            />
          }
          {helpIcon}
        </div>
        <div className="BobInviteEndUserForm-buttons">
          <Button
            disabled={buttonDisabled}
            className="BobInviteEndUserForm-inviteButton"
            submit
            type="primary"
          >
            {
              invitingEndUser ?
                <span><Spinner />Inviting…</span> :
                'Invite'
            }
          </Button>
        </div>
      </Form>

    return <div className="BobInviteEndUserForm">
      {content}
    </div>
  }
}
