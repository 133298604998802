import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Form from 'components/Form'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import Alert from 'components/Alert'
import ErrorMessage from 'components/ErrorMessage'
import Spinner from 'components/Spinner'

import './index.sass'

export default class BobAddEmailForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    addingEmail: PropTypes.bool,
    addingEmailError: ErrorMessage.propTypes.error,
    onSubmit: PropTypes.func.isRequired,
    emails: PropTypes.object.isRequired,
  }

  componentWillReceiveProps(nextProps){
    const emailAdded = Object.keys(nextProps.emails).some(email => !Object.keys(this.props.emails).includes(email))

    if (emailAdded) {
      this.setState({ emailRecentlyAdded: this.state.email, email: '' })
      clearTimeout(this.emailRecentlyAddedTimeout)
      this.emailRecentlyAddedTimeout = setTimeout(() => { this.setState({ emailRecentlyAdded: false }) }, 5000)
    }
  }

  state = {
    email: '',
    emailRecentlyAdded: false,
    emailAlreadyAdded: false,
  }

  setEmail = email => {
    this.setState({email})
  }

  submit = () => {
    const { email } = this.state
    if (Object.keys(this.props.emails).includes(email)) {
      this.setState({ emailAlreadyAdded: this.state.email, email: '' })
      clearTimeout(this.emailAlreadyAdded)
      this.emailAlreadyAdded = setTimeout(() => { this.setState({ emailAlreadyAdded: false }) }, 5000)
    }
    this.props.onSubmit({ email })
  }

  render() {
    const {
      className = '',
      addingEmail,
      addingEmailError,
    } = this.props

    const successAlert = this.state.emailRecentlyAdded
      ? <Alert type="success">
        {`A verification link has been sent to ${this.state.emailRecentlyAdded}`}
      </Alert>
      : null

    const alreadyAddedError = this.state.emailAlreadyAdded
      ? <Alert type="error">
        {`${this.state.emailAlreadyAdded} is already in the above email list`}
      </Alert>
      : null

    return <Form className={`BobAddEmailForm ${className}`} onSubmit={this.submit}>
      {alreadyAddedError}
      {successAlert}
      <Header size="sm">Add a new email:</Header>
      <ErrorMessage error={addingEmailError} />
      <Form.Row className="BobAddEmailForm-wrapper">
        <TextInput
          ref={input => { this.emailInput = input }}
          value={this.state.email}
          onInput={this.setEmail}
          placeholder="Email"
          name="email"
          type="email"
          required
          disabled={addingEmail}
        />
        <Button
          type="primary"
          submit
          disabled={addingEmail}
        >
          { addingEmail ?
            <span><Spinner />&nbsp;Adding</span>  :
            <span>Add</span>
          }
        </Button>
      </Form.Row>
    </Form>
  }
}
