import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import Button from 'components/Button'
import './index.sass'

export default class CSVUploader extends Component {

  static propTypes = {
    className: PropTypes.string,
    sessionId: PropTypes.string.isRequired,
    helpIcon: PropTypes.node,
  }

  onFileSelect = async event => {
    const file = event.target.files[0]
    this.setState({ fileName: file.name })
  }

  onSubmit = () => {
    this.setState({ fileName: null })
  }

  render(){
    const {
      className = '',
      helpIcon,
      ...props
    } = this.props

    const title = !this.state.fileName ?
      'Click here to upload a CSV' :
      'Click generate to submit'

    return <form
      className={`CSVUploader ${className}`}
      encType="multipart/form-data"
      method="POST"
      action={`${JLINC_B_API}/orgs/invite_end_user_batch`}
      onSubmit={this.onSubmit}
      {...props}
    >
      <div className="CSVUploader-selectWrapper">
        <div className="CSVUploader-select">
          <span>{ this.state.fileName || 'Click here to upload a CSV' }</span>
          <Icon type="log-out" />
          <input
            name="emailListCSVStream"
            type="file"
            accept=".csv"
            onChange={this.onFileSelect}
            title={title}
          />
        </div>
        {helpIcon}
      </div>
      <div>
        <input type="hidden" name="sessionId" value={this.props.sessionId} />
        <Button
          type="primary"
          submit
          disabled={!this.state.fileName}
        >
          Generate
        </Button>
      </div>
    </form>
  }
}
