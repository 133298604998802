
import urlRegex from 'url-regex'

const EMAIL_REGEXP = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@' +
  '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
)

export function isValidUrl(value){
  if (
    typeof value !== 'string' ||
    value.length > 2000
  ) return false
  const match = urlRegex({ strict: false }).exec(value)
  return (
    match &&
    match.index === 0 &&
    match[0].length === value.length
  )
}

export function isValidEmail(value){
  return EMAIL_REGEXP.test(String(value).toLowerCase())
}

export function isInvalidPassword(password) {
  if (password.length < 10) return 'minimum 10 characters'
  return false
}

export function isBlank(string){
  return typeof string !== 'string' || string.trim() === ''
}
