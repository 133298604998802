import { h } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'

import BobLoginForm from 'components/BobLoginForm'
import BobSignupStripeForm from 'components/BobSignupStripeForm'
import BobSignupFromInviteForm from 'components/BobSignupFromInviteForm'

import './index.sass'

class LoginAndSignupPage extends Page {

  static pageTitle = 'Login / Signup'

  getInviteToken(){
    return this.props.location.query.it
  }

  componentDidMount(){
    const inviteToken = this.getInviteToken()
    if (inviteToken)
      this.props.takeAction('invites.getOrganizationInvite', { inviteToken })
  }

  render() {
    const inviteToken = this.getInviteToken()

    const {
      takeAction,
      appAction,
      loggingIn,
      loginError,
      signingUp,
      signupError,
      signupEmail,
      passwordResetRequest,
      gettingOrganizationInvite,
      errorGettingOrganizationInvite,
      organizationInvite,
    } = this.props

    const disabled = loggingIn || signingUp
    const className = "LoginAndSignupPage fullpage"

    if (inviteToken){
      return <div className={className}>
        <BobSignupFromInviteForm
          inviteToken={inviteToken}
          loadingInvite={gettingOrganizationInvite}
          errorLoadingInvite={errorGettingOrganizationInvite}
          invite={organizationInvite}
          onSubmit={appAction('signup.start')}
          signingUp={signingUp}
          errorSigningUp={signupError}
          signupEmail={signupEmail}
        />
      </div>
    }

    return <div className={className}>
      <BobLoginForm
        disabled={disabled}
        loggingIn={loggingIn}
        error={loginError}
        onSubmit={args => {
          takeAction(
            'session.login', { ...args, organizationAdminInviteToken: (organizationInvite || {}).inviteToken }
          )
        }}
        onRequestPasswordReset={appAction('requestPasswordReset')}
        resetRequestPasswordResetForm={appAction('resetRequestPasswordResetForm')}
        passwordResetRequest={passwordResetRequest}
      />
      <BobSignupStripeForm
        dismissErrorMessage={appAction('signup.clearErrors')}
        error={signupError}
        signup={appAction('signup.start')}
        signingUp={!!signingUp}
        disabled={disabled}
        email={signupEmail}
        autoOpenStripeForm={this.props.location.query.s === '1'}
      />
    </div>
  }
}


export default bindToAppState(
  [
    'loggingIn',
    'loginError',
    'signingUp',
    'signupError',
    'signupEmail',
    'passwordResetRequest',
    'gettingOrganizationInvite',
    'errorGettingOrganizationInvite',
    'organizationInvite',
  ],
  LoginAndSignupPage,
)
