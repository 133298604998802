import { h, cloneElement } from 'preact'
import { useRef } from 'preact/hooks'
import PropTypes from 'prop-types'

import useToggle from 'lib/useToggleHook'

export default function ClickChange({children, onClick}){
  const ref = useRef(null)
  const timeoutRef = useRef(null)
  const [changed, setChanged, unsetChanged] = useToggle(false)
  const element = children(changed)
  const _onClick = event => {
    if (
      !event.defaultPrevented &&
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey
    ){
      if (onClick) onClick(event)
      setChanged()
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(unsetChanged, 2000)
    }
    if (element.props.onClick) return element.props.onClick(event)
  }
  return cloneElement(element, { ref, onClick: _onClick })
}

ClickChange.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func,
}
