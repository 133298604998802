import { h } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import { bindToAppState } from 'lib/appState'

import Page from 'lib/Page'
import Card from 'components/Card'
import Loading from 'components/Loading'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import BobSignupWizard from 'components/BobSignupWizard'

import './index.sass'

class CompleteSignupPage extends Page {

  static pageTitle = 'Complete signup'

  static propTypes = {
    ...Page.propTypes,
    signupToken: PropTypes.string,
  }

  render() {
    const {
      signupToken,

      appAction,
      verifyingSignup,
      verifySignupError,
      signupEmail,
      completingSignup,
      completeSignupError,
      currentUser,
      loadingEmailsError,
      emails,

      customPersonalDataFields,

      loadingOrganizationProfile,
      loadingOrganizationProfileError,
      organizationProfile,
      organizationProfileChanges,
      updatingOrganizationProfile,
      errorUpdatingOrganizationProfile,

      updatingOrganizationConsents,
      updatingOrganizationConsentsError,
      updatingOrganizationRequestedData,
      updatingOrganizationRequestedDataError,
      updatingOrganizationCommunicationChannels,
      updatingOrganizationCommunicationChannelsError,

      'feed:uploadingMediaAsset': uploadingMediaAsset,
      'feed:uploadMediaAssetError': uploadMediaAssetError,
      'feed:draft': draftFeedPost,
      'feed:posting': postingToFeed,
      'feed:postingError': errorPostingToFeed,
      'feed:loading': loadingOrganizationFeedPosts,
      'feed:posts': organizationFeedPosts,
      'feed:deleting': deletingPosts,
      'feed:deleting:errors': errorsDeletingPosts,
    } = this.props

    if (
      (signupToken && verifyingSignup) ||
      loadingOrganizationProfile ||
      loadingOrganizationFeedPosts
    ) return <Loading type="fullScreen"/>

    if (verifySignupError) {
      return <FullPageError
        header="Ooops sorry! There was a problem with your signup url"
        error={verifySignupError}
      />
    }

    if (loadingOrganizationProfileError || loadingEmailsError){
      return <FullPageError
        header={`Ooops sorry! There was a problem loading your ${PNFO.singular}`}
        error={loadingOrganizationProfileError || loadingEmailsError}
      />
    }
    const props = {
      signupToken,
      signupEmail,
      completeSignup: appAction('signup.complete'),
      completingSignup,
      completeSignupError,
      logout: currentUser && appAction('session.logout'),
      currentUser,

      emails,
      loadingEmailsError,

      // organizationProfile props
      loadingOrganizationProfile,
      organizationProfile,
      stageOrganizationProfileChanges: appAction('stageOrganizationProfileChanges'),
      clearOrganizationProfileChanges: appAction('clearOrganizationProfileChanges'),
      organizationProfileChanges,
      updateOrganizationProfile: appAction('updateOrganizationProfile'),
      updatingOrganizationProfile: !!updatingOrganizationProfile,
      errorUpdatingOrganizationProfile,
      updateConsents: appAction('updateConsents'),
      updatingOrganizationConsents,
      updatingOrganizationConsentsError,
      toggleRequestedData: appAction('toggleRequestedData'),
      updatingOrganizationRequestedData,
      updatingOrganizationRequestedDataError,
      toggleCommunicationChannel: appAction('toggleCommunicationChannel'),
      updatingOrganizationCommunicationChannels,
      updatingOrganizationCommunicationChannelsError,

      customPersonalDataFields,
      stageOrganizationRequestedDataChanges: appAction('stageOrganizationRequestedDataChanges'),
      clearOrganizationRequestedDataChanges: appAction('clearOrganizationRequestedDataChanges'),
      saveOrganizationRequestedDataChanges: appAction('saveOrganizationRequestedDataChanges'),

      // feed post props
      loadingOrganizationFeedPosts,
      organizationFeedPosts,
      draftFeedPost,
      updateDraftFeedPost: appAction('updateDraftFeedPost'),
      uploadFeedPostMediaAsset: appAction('uploadFeedPostMediaAsset'),
      uploadingMediaAsset,
      uploadMediaAssetError,
      postDraftFeedPost: appAction('postDraftFeedPost'),
      postingToFeed,
      errorPostingToFeed,
      deleteFeedPost: appAction('deleteFeedPost'),
      deletingPosts,
      errorsDeletingPosts,
    }
    return <div className="CompleteSignupPage">
      <BobSignupWizard {...props} />
    </div>
  }
}

export default bindToAppState(
  [
    'verifyingSignup',
    'verifySignupError',
    'signupEmail',
    'completingSignup',
    'completeSignupError',
    'currentUser',
    'loadingEmailsError',
    'emails',

    'customPersonalDataFields',

    'loadingOrganizationProfile',
    'loadingOrganizationProfileError',
    'organizationProfile',
    'organizationProfileChanges',
    'updatingOrganizationProfile',
    'errorUpdatingOrganizationProfile',

    'updatingOrganizationConsents',
    'updatingOrganizationConsentsError',
    'updatingOrganizationRequestedData',
    'updatingOrganizationRequestedDataError',
    'updatingOrganizationCommunicationChannels',
    'updatingOrganizationCommunicationChannelsError',

    'feed:uploadingMediaAsset', // uploadingMediaAsset,
    'feed:uploadMediaAssetError', // uploadMediaAssetError,
    'feed:draft', // draftFeedPost,
    'feed:posting', // postingToFeed,
    'feed:postingError', // errorPostingToFeed,
    'feed:loading', // loadingOrganizationFeedPosts,
    'feed:posts', // organizationFeedPosts,
    'feed:deleting', // deletingPosts,
    'feed:deleting:errors', // errorsDeletingPosts,
  ],
  CompleteSignupPage,
  props => {
    if (props.signupToken && !props.signupEmail){
      props.takeAction('signup.verifySignupToken', {signupToken: props.signupToken})
    }
  },
)

const FullPageError = props =>
  <div className="CompleteSignupPage fullpage">
    <Card className="CompleteSignupPage-error">
      <Card.Header>{props.header}</Card.Header>
      <Card.Body>
        <ErrorMessage error={props.error} />
        <Button
          type="primary"
          block
          href="/"
        >OK</Button>
      </Card.Body>
    </Card>
  </div>
