import api from '../api'

export default function(){
  const { loadingOrganizationProfile } = this.getState()

  if (loadingOrganizationProfile) return

  this.setState({
    loadingOrganizationProfile: true,
  })

  api.loadOrganizationProfile().then(
    ({organizationProfile}) => {
      this.setState({
        loadingOrganizationProfile: undefined,
        organizationProfile,
      })
    },
    loadingOrganizationProfileError => {
      this.setState({
        loadingOrganizationProfile: undefined,
        loadingOrganizationProfileError,
      })
    }
  )
}
