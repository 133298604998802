import logger from 'lib/logger'
import api from '../api'

function validateType(type){
  if (type !== 'consumer' && type !== 'business')
    throw new Error(`invalid buying interest type: "${type}"`)
}

export function load({ type }) {
  validateType(type)
  const { [`buyingInterests:${type}`]: buyingInterests } = this.getState()
  if (buyingInterests) return Promise.resolve()
  return this.takeAction('buyingInterests.reload', { type })
}

export function reload({ type }) {
  validateType(type)

  const buyingInterestsKey = `buyingInterests:${type}`
  const loadingKey = `buyingInterests:${type}:loading`
  const loadingErrorKey = `buyingInterests:${type}:loading:error`

  this.setState({ [loadingKey]: true })

  return api.getBuyingInterests({ type }).then(
    ({buyingInterests}) => {
      const buyingInterestsByUid = {}
      buyingInterests.forEach(buyingInterest => {
        buyingInterestsByUid[buyingInterest.uid] = buyingInterest
      })
      this.setState({
        [loadingKey]: undefined,
        [buyingInterestsKey]: buyingInterestsByUid,
      })
    },
    error => {
      logger.error(`error loading ${type} buying interests: ${error}`)
      this.setState({
        [loadingKey]: undefined,
        [loadingErrorKey]: error,
      })
    }
  )
}

export function update(draftBuyingInterest){
  this.setState({
    ['buyingInterests:business:draftBuyingInterest']: draftBuyingInterest,
    ['buyingInterests:business:creating:error']: undefined,
  })
}

export function clear() {
  const creatingBuyingInterest = this.getState()['buyingInterests:business:creating']

  if (creatingBuyingInterest){
    logger.warn(
      'refusing to clear buyingInterests:business:draftBuyingInterest ' +
      'because were currently creating one'
    )
  }

  this.setState({
    ['buyingInterests:business:draftBuyingInterest']: undefined,
  })
}

export function create(){
  const creatingKey = 'buyingInterests:business:creating'
  const creatingErrorKey = 'buyingInterests:business:creating:error'
  const buyingInterestsKey = 'buyingInterests:business'
  const draftBuyingInterestKey = 'buyingInterests:business:draftBuyingInterest'

  if (this.getState()[creatingKey]) return

  const buyingInterest = this.getState()[draftBuyingInterestKey]

  this.setState({
    [creatingKey]: true,
    [creatingErrorKey]: undefined,
  })

  return api.createBuyingInterest({buyingInterest}).then(
    ({buyingInterest}) => {
      const buyingInterests = this.getState()[buyingInterestsKey]

      this.setState({
        [creatingKey]: undefined,
        [draftBuyingInterestKey]: undefined,
        [buyingInterestsKey]: {
          ...buyingInterests,
          [buyingInterest.uid]: buyingInterest,
        }
      })
    },
    error => {
      this.setState({
        [creatingKey]: undefined,
        [creatingErrorKey]: error,
      })
    }
  )
}

export function del({ uid, confirmed }){
  confirmed = confirmed || confirm('Are you sure you want to delete this buying interest?') // eslint-disable-line
  if (!confirmed) return

  const deletingKey = `buyingInterests:business:deleting`
  const deletingErrorKey = `buyingInterests:business:deleting:error`
  const buyingInterestsKey = `buyingInterests:business`

  if (this.getState()[deletingKey]) return
  this.setState({
    [deletingKey]: uid,
    [deletingErrorKey]: undefined,
  })

  return api.deleteBuyingInterest({ uid }).then(
    () => {
      const buyingInterests = {...this.getState()[buyingInterestsKey]}
      delete buyingInterests[uid]

      this.setState({
        [buyingInterestsKey]: buyingInterests,
        [deletingKey]: undefined,
      })
    },
    error => {
      this.setState({
        [deletingKey]: undefined,
        [deletingErrorKey]: error,
      })
    }
  )
}

export function edit(uid){
  const confirmation = (
    'Are you sure you want to edit this buying interest? ' +
    'This will delete the buying interest and populate the create form above.'
  )
  const confirmed = confirm(confirmation) // eslint-disable-line
  if (!confirmed) return

  const buyingInterest = this.getState()['buyingInterests:business'][uid]

  this.setState({
    ['buyingInterests:business:draftBuyingInterest']: {...buyingInterest},
  })

  this.takeAction('buyingInterests.del', { uid, confirmed: true })
}
