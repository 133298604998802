import api from '../api'

export default function(){
  const { loadingOverview } = this.getState()

  if (loadingOverview) return

  this.setState({
    loadingOverview: true,
  })

  api.loadOverview().then(
    ({
      emails,
      organizationProfile,
      bisas,
      bisaOfferings,
      networkInvites,
    }) => {
      const emailsAsObject = {}
      emails.forEach(({email, verified}) => {
        emailsAsObject[email] = verified
      })

      this.setState({
        loadingOverview: undefined,
        emails: emailsAsObject,
        organizationProfile,
        bisas,
        bisaOfferings,
        networkInvites,
      })
    },
    loadingOverviewError => {
      this.setState({
        loadingOverview: undefined,
        loadingOverviewError,
      })
    }
  )
}
