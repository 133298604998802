export default function classNames(prefix, subclasses){
  let className = `${prefix}`
  if (subclasses) {
    for (const subclass in subclasses){
      if (subclass === 'className') continue
      if (subclasses[subclass]) className += ` ${prefix}-${subclass}`
    }
    if (subclasses.className) className += ` ${subclasses.className}`
  }
  return className
}
