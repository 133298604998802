import { h } from 'preact'
import { createPortal } from 'preact/compat'
import { useEffect, useRef } from 'preact/hooks'

let portalElement

export function Portal(){
  return <div ref={element => { portalElement = element }}/>
}

export function usePortal(){
  const portalRef = useRef(global.document.createElement('div'))
  useEffect(
    () => {
      if (!portalElement) { console.warn(`no portal container element?`); return }
      if (!portalRef.current) { console.warn(`no portal element?`); return }
      const portalContainer = portalElement
      const portal = portalRef.current
      portalContainer.appendChild(portal)
      return () => { portalContainer.removeChild(portal) }
    },
    []
  )
  return element => createPortal(element, portalRef.current)
}
