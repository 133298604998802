import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import IconButton from 'components/IconButton'
import TextInput from 'components/TextInput'
import Button from 'components/Button'

import './index.sass'

export default class TagsInput extends Component {

  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    unsaved: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    onBlur: PropTypes.func,
  }

  static defaultProps = {
    value: [],
  }

  state = { tag: '' }

  getValue(){ return this.props.value || [] }

  addTag = () => {
    const value = this.getValue()
    const { onChange, disabled } = this.props
    if (disabled) return
    let { tag } = this.state
    tag = tag.replace(/\s+/g, ' ').trim()
    if (value.includes(tag) || !tag) {
      this.setTag('')
      return
    }
    const newTags = [...value, tag]
    onChange(newTags)
    this.setTag('')
  }

  removeTag = tagIndex => {
    const value = this.getValue()
    const { onChange, disabled } = this.props
    if (disabled) return
    const newTags = [...value]
    const removedTag = newTags.splice(tagIndex, 1)[0]
    onChange(newTags)
    return removedTag
  }

  onKeyDown = event => {
    const tags = this.getValue()
    const valueBlank = event.target.value === ''
    if (tags.length > 0 && event.code === 'Backspace' && valueBlank) {
      event.preventDefault()
      const removedTag = this.removeTag(tags.length - 1)
      this.setTag(removedTag)
    }else if (event.code === 'Enter' && !valueBlank) {
      event.preventDefault()
      this.addTag()
    }
  }

  setTag = (tag) => {
    tag = tag.replace(/^\s+/, '').replace(/\s+(\w)/g, ' $1').replace(/\s+$/, ' ')
    this.setState({ tag })
  }

  onBlur = event => {
    if (event.target.value !== '') this.addTag()
    if (this.props.onBlur) return this.props.onBlur(event)
  }

  focus = () => {
    TextInput.focus(this.input)
  }

  render() {
    const value = this.getValue()
    const { required, disabled, unsaved, ...props } = this.props
    const { tag } = this.state
    delete props.onChange

    const tagsList = value.map((tag, index) => {
      return <span key={tag} className="TagsInput-tag">
        {tag}
        <IconButton
          className="TagsInput-deleteIcon"
          type="cancel-circled"
          onClick={() => { this.removeTag(index) }}
          tabIndex={-1}
        />
      </span>
    })

    let className = 'TagsInput'
    if (disabled) className += ' TagsInput-disabled'
    if (unsaved) className += ' TagsInput-unsaved'
    if (this.props.className) className += ` ${this.props.className}`

    return <div className={className} onMouseUp={this.focus}>
      <div className="TagsInput-tags">{tagsList}</div>
      <TextInput
        {...props}
        required={value.length === 0 && required}
        ref={node => this.input = node}
        disabled={disabled}
        unsaved={unsaved}
        onKeyDown={this.onKeyDown}
        onInput={this.setTag}
        value={tag}
        readOnly={false}
        onBlur={this.onBlur}
      />
      <Button
        tabIndex={-1}
        type="none"
        onClick={this.addTag}
        className="TagsInput-addButton"
        value="+"
      />
    </div>
  }
}
