import PathnameRouter from 'lib/PathnameRouter'

import SetUpPage from './pages/SetUpPage'
import ConsumerAdminPage from './pages/ConsumerAdminPage'
import ConsumerAgreementsPage from './pages/ConsumerAgreementsPage'
import BusinessAgreementsPage from './pages/BusinessAgreementsPage'
// import OrganizationsPage from './pages/OrganizationsPage'
import ConsumerMarketplacePage from './pages/ConsumerMarketplacePage'
// import ConsumerFeedPage from './pages/ConsumerFeedPage'
// import BusinessOrgShowPage from './pages/BusinessOrgShowPage'
import BusinessAgreementPage from './pages/BusinessAgreementPage'
import BusinessMarketBuyPage from './pages/BusinessMarketBuyPage'
import BusinessMarketSellPage from './pages/BusinessMarketSellPage'
// import BusinessFeedPage from './pages/BusinessFeedPage'
import BusinessAdminPage from './pages/BusinessAdminPage'
// import NetworkMembersPage from './pages/NetworkMembersPage'
// import NetworksPage from './pages/NetworksPage'
// import NetworkFeedPage from './pages/NetworkFeedPage'
// import NetworkAdminPage from './pages/NetworkAdminPage'
// import OrganizationNetworkPage from './pages/OrganizationNetworkPage'
import UserDataPage from './pages/UserDataPage'
import NotFoundPage from './pages/NotFoundPage'

export default new PathnameRouter(map => {
  map('/',                                        SetUpPage)
  map('/c/user-data',                             UserDataPage)
  map('/c/agreements',                            ConsumerAgreementsPage)
  map('/c/market/sell',                           ConsumerMarketplacePage)
  // map('/c/feed',                                  ConsumerFeedPage)
  map('/c/admin',                                 ConsumerAdminPage)
  // map('/b/orgs',                                  OrganizationsPage)
  map('/b/agreements',                            BusinessAgreementsPage)
  map('/b/orgs/:organizationApikey/bisa',         BusinessAgreementPage)
  // map('/b/orgs/:organizationApikey',              BusinessOrgShowPage)
  map('/b/market/buy',                            BusinessMarketBuyPage)
  map('/b/market/sell',                           BusinessMarketSellPage)
  // map('/b/feed',                                  BusinessFeedPage)
  map('/b/admin',                                 BusinessAdminPage)
  // map('/networks',                                NetworksPage)
  // map('/networks/:organizationApikey/members',    OrganizationNetworkPage)
  // map('/networks/:organizationApikey/feed',       OrganizationNetworkPage)
  // map('/n/members',                               NetworkMembersPage)
  // map('/n/feed',                                  NetworkFeedPage)
  // map('/n/admin',                                 NetworkAdminPage)
  map(/.*/,                                  NotFoundPage)
})
