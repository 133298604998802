import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import Header from 'components/Header'
import HelpButton from 'components/HelpButton'
import CSVUploader from 'components/CSVUploader'

import './index.sass'

const PERSONAL_DATA_KEYS_TO_CSV_COLUMN_NAMES = Object.freeze({
  email: 'email',
  mobilephone: 'sms',
  firstname: 'first',
  lastname: 'last',
  salutation: 'salutation',
  birthdate: 'birthdate',
  gender: 'gender',
  mailingstreet: 'street',
  mailingcity: 'city',
  mailingstate: 'state',
  mailingpostalcode: 'zip',
  mailingcountry: 'country',
  homephone: 'homephone',
  faxnumber: 'fax',
  businessname: 'businessname',
  businessindustry: 'businessindustry',
  title: 'businessjobtitle',
  businessstreet: 'businessstreet',
  businesscity: 'businesscity',
  businesscountry: 'businesscountry',
  businesspostalcode: 'businesszip',
  businessphone: 'businessphone',
})

export default class BobInviteEndUserBatch extends Component {
  static propTypes = {
    sessionId: PropTypes.string.isRequired,
  }

  renderCSVColumns(){
    const required = <i>&nbsp;(email or sms required)</i>
    return Object.entries(PERSONAL_DATA_KEYS_TO_CSV_COLUMN_NAMES).map(([key, label]) =>
      <li key={key}>{label}{(key === 'email' || key === 'mobilephone') ? required : null}</li>
    )
  }

  render(){
    const { sessionId } = this.props

    const helpIcon = <HelpButton>
      <Header size="lg">Batch Invite Your End Users Via a CSV</Header>
      <p>
        When uploading a CSV to batch invite users,
        JLINC will look for columns with the following headers:
      </p>
      <ul>
        {this.renderCSVColumns()}
      </ul>
      <p>Use the downloaded CSV to batch send invites to your end users</p>
    </HelpButton>

    return <div className="BobInviteEndUserBatch">
      <CSVUploader helpIcon={helpIcon} sessionId={sessionId} />
    </div>
  }
}
