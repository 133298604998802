export default function(){
  const {
    selectedMailingConsentType: consentType,
    currentUser: { sessionId }
  } = this.getState()

  window.open(
    `${JLINC_B_API}/email_list/${sessionId}/${encodeURIComponent(consentType)}`,
    'CSV Downloading...'
  )

  this.takeAction('clearSelectedMailingConsentType')
}
