import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default class Table extends Component {

  static propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.array.isRequired,
    keyProperty: PropTypes.string.isRequired,
    renderRow: PropTypes.func.isRequired,
    onEmpty: PropTypes.func,
  }

  static defaultProps = {
    renderRow: row => row.map(cell => <span className="Table-text">{cell}</span>),
    onEmpty: () => '',
  }

  render(){

    const {
      className = '',
      columns,
      rows,
      keyProperty,
      renderRow,
      onEmpty,
      ...props
    } = this.props

    const renderedRows = rows.length === 0
      ? <tr className="Table-empty">
        <td colSpan={columns.length}>{onEmpty()}</td>
      </tr>
      : rows.map(row => {
        const renderedRow = renderRow(row)
        const renderedColumns = columns.map((column, index) =>
          <td x-column={column}>{renderedRow[index] || empty()}</td>
        )
        return <tr
          key={row[keyProperty]}
        >
          {renderedColumns}
        </tr>
      })

    const headerRow = columns.map(column =>
      <th x-column={column}>{column}</th>
    )

    return <div
      className={`Table ${className}`}
      {...props}
    >
      <table>
        <tr>{headerRow}</tr>
        {renderedRows}
      </table>
    </div>
  }
}

const empty = () => <span>&nbsp;</span>
