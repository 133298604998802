import { h } from 'preact'
import PropTypes from 'prop-types'
import moment from 'moment'

import Card from 'components/Card'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Header from 'components/Header'

import './index.sass'

export default function BuyingInterest({ onDelete, onEdit, buyingInterest }){
  const {
    uid,
    industry,
    description,
    price_high,
    price_low,
    location,
    currency,
    beginning_date,
    end_date,
    brands,
    tags,
  } = buyingInterest

  const tagsList = tags => tags.map(tag =>
    <span className="BuyingInterest-tag">{tag}</span>
  )

  const buyingInterestRow = (description, value, type) => (
    <div className={`BuyingInterest-row ${type ? `BuyingInterest-row-${type}` : ''}`}>
      <Header
        className="BuyingInterest-row-label"
        size="sm"
      >
        {description}:
      </Header>
      <span
        className="BuyingInterest-row-value"
      >
        {value}
      </span>
    </div>
  )

  return <Card className="BuyingInterest">
    <Card.Body>
      <div className="BuyingInterest-buttons">
        { onEdit &&
          <Button onClick={() => onEdit(uid)} className="BuyingInterest-editIcon" >
            <Icon type="edit" />
          </Button>
        }
        { onDelete &&
          <Button onClick={() => onDelete(uid)} className="BuyingInterest-deleteIcon" >
            <Icon type="cancel-circled" />
          </Button>
        }
      </div>
      {buyingInterestRow('Description', description)}
      {industry && buyingInterestRow('Industry', industry)}
      {(typeof price_low === 'number' || typeof price_high === 'number') &&
        buyingInterestRow(
          'Price range',
          `${price_low ? formatAsMoney(currency, price_low) : '0'} - ` +
          `${price_high ? formatAsMoney(currency, price_high) : 'unlimited'}`
        )
      }
      {(beginning_date || end_date) &&
        buyingInterestRow(
          'Buying window',
          `${formatAsDate(beginning_date)} - ${end_date ? formatAsDate(end_date) : 'open-ended'}`
        )
      }
      {location && buyingInterestRow('Location', location)}
      {brands && brands.length > 0 && buyingInterestRow('Brands', tagsList(brands), 'tags')}
      {tags && tags.length > 0 && buyingInterestRow('Product Features', tagsList(tags), 'tags')}
    </Card.Body>
  </Card>
}

BuyingInterest.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  buyingInterest: PropTypes.shape({
    uid: PropTypes.string,
    industry: PropTypes.string,
    description: PropTypes.string,
    currency: PropTypes.string,
    price_low: PropTypes.number,
    price_high: PropTypes.number,
    beginning_date: PropTypes.string,
    end_date: PropTypes.string,
    location: PropTypes.string,
    tags: PropTypes.array,
    brands: PropTypes.array,
  })
}

const formatAsMoney = (currency, value) =>
  typeof value === 'number' ? `${currency}${(value / 100).toFixed(2)}` : ''

const formatAsDate = date =>
  date ? moment(date).format('L') : ''
