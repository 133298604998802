import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Form from 'components/Form'
import Link from 'components/Link'
import BackButton from 'components/BackButton'
import Dropdown from 'components/Dropdown'
import Modal from 'components/Modal'
import Header from 'components/Header'
import Switch from 'components/Switch'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import ErrorMessage from 'components/ErrorMessage'
import SisaText from 'components/SisaText'
import TextArea from 'components/TextArea'
import TextInput from 'components/TextInput'
import CountryDropdownPicker from 'components/CountryDropdownPicker'

import {
  BOB_SISA_NOTICE,
  BOB_SISA_SUMMARY,
  FULL_SISA_URL,
} from 'lib/accountDataSpec'
import { ORGANIZATION_TYPE_LABELS } from 'lib/organizationProfileHelpers'

import './index.sass'

const organizationProfileShape = {
  address:                 PropTypes.string,
  city:                    PropTypes.string,
  contact_phone:           PropTypes.string,
  contact_phone_2:         PropTypes.string,
  country:                 PropTypes.string,
  description:             PropTypes.string,
  domain:                  PropTypes.string,
  dpo_email:               PropTypes.string,
  icon:                    PropTypes.string,
  logo:                    PropTypes.string,
  name:                    PropTypes.string,
  legal_name:              PropTypes.string,
  type:                    PropTypes.string,
  post_code:               PropTypes.string,
  state:                   PropTypes.string,
  public:                  PropTypes.bool,
  published:               PropTypes.bool,
}

export default class OrganizationProfileForm extends Component {

  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    hasChanges: PropTypes.bool.isRequired,
    organizationProfile: PropTypes.shape({
      ...organizationProfileShape,
      apikey:         PropTypes.string.isRequired,
      salesforce:     PropTypes.bool.isRequired,
      public_allowed: PropTypes.bool.isRequired,
      public:         organizationProfileShape.public.isRequired,
    }).isRequired,
    organizationProfileChanges: PropTypes.shape({
      ...organizationProfileShape,
    }),
    errorUpdatingOrganizationProfile: ErrorMessage.propTypes.error,
    emails: PropTypes.object.isRequired,
    loadingEmailsError: ErrorMessage.propTypes.error,
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.updating && !nextProps.updating) {
      this.hideSisaModal()
    }
  }

  state = { sisaModalVisible: false }

  showSisaModal = () => {
    this.setState({ sisaModalVisible: true })
  }

  hideSisaModal = () => {
    this.setState({ sisaModalVisible: false })
  }

  publish = () => {
    const { onChange } = this.props
    onChange({ published: true })
    this.update()
  }

  update = () => {
    this.props.onUpdate(this.props.organizationProfileChanges)
  }

  render({
    onChange,
    organizationProfile,
    organizationProfileChanges = {},
    updating,
    hasChanges,
    emails,
    errorUpdatingOrganizationProfile,
    loadingEmailsError,
  }) {
    const { published } = organizationProfile
    const organizationProfileWithChanges = Object.assign(
      {},
      organizationProfile,
      organizationProfileChanges,
    )

    const verifiedEmails = Object.keys(emails).filter(email => emails[email])

    const boundTextInputFormItem = ({label, key, type = 'text', name, disabled, defaultValue}) => {
      const hasStagedChange = key in organizationProfileChanges
      const value = key === 'type'
        ? ORGANIZATION_TYPE_LABELS[organizationProfileWithChanges[key]]
        : organizationProfileWithChanges[key]
      return <Form.Item>
        <Form.Label>{label}</Form.Label>
        <TextInput
          type={type}
          name={name}
          unsaved={hasStagedChange}
          value={value}
          onInput={value => onChange({[key]: value})}
          disabled={disabled || updating}
          defaultValue={defaultValue}
        />
      </Form.Item>
    }

    const boundTextAreaFormItem = ({label, key, name, maxLines, maxLength}) => {
      const hasStagedChange = key in organizationProfileChanges
      return <Form.Item>
        <Form.Label>{label}</Form.Label>
        <TextArea
          className={`OrganizationProfileForm-textarea-${key}`}
          name={name}
          unsaved={hasStagedChange}
          value={organizationProfileWithChanges[key]}
          onInput={value => { onChange({[key]: value}) }}
          disabled={updating}
          maxLength={maxLength}
          maxLines={maxLines}
        />
      </Form.Item>
    }

    return <Form
      className="OrganizationProfileForm"
      onSubmit={this.update}
    >
      <ErrorMessage error={errorUpdatingOrganizationProfile || loadingEmailsError} />
      <Form.Row>
        {boundTextInputFormItem({label: 'ENTITY TYPE', key: 'type', name: 'organization_type', disabled: true })}
      </Form.Row>
      <Form.Row>
        {boundTextInputFormItem({label: 'NAME', key: 'name', name: 'organization'})}
        {boundTextInputFormItem({label: 'LEGAL NAME OF ENTITY (OR INDIVIDUAL)', key: 'legal_name', name: 'legal_name'})}
      </Form.Row>
      <Form.Row>
        <Form.Item>
          <Form.Label className="OrganizationProfileForm-dpoLabel">
            EMAIL
          </Form.Label>
          <Dropdown
            className="OrganizationProfileForm-dpoEmail"
            value={organizationProfileWithChanges.dpo_email}
            options={verifiedEmails}
            postMessage="Add additional emails in the 'Account Settings' form below"
            onChange={dpo_email => { onChange({dpo_email}) }}
            unsaved={'dpo_email' in organizationProfileChanges}
          />
        </Form.Item>
        {boundTextInputFormItem({label: 'WEBSITE', key: 'domain', type: 'text', defaultValue: 'http://www.', name: 'url'})}
      </Form.Row>

      {boundTextAreaFormItem({label: 'STREET', key: 'address', name: 'mailingstreet'})}

      <Form.Row>
        {boundTextInputFormItem({label: 'TOWN / CITY', key: 'city', name: 'city'})}
        {boundTextInputFormItem({label: 'STATE / PROVINCE / COUNTY', key: 'state', name: 'mailingstate'})}
      </Form.Row>
      <Form.Row>
        {boundTextInputFormItem({label: 'POSTCODE / ZIP CODE', key: 'post_code', name: 'mailingpostalcode'})}
        <Form.Item>
          <Form.Label>COUNTRY</Form.Label>
          <CountryDropdownPicker
            unsaved={'country' in organizationProfileChanges}
            onChange={country => { onChange({country})}}
            disabled={updating}
            value={organizationProfileWithChanges.country}
          />
        </Form.Item>
      </Form.Row>
      {boundTextInputFormItem({label: 'CONTACT PHONE', key: 'contact_phone', type: 'tel', name: 'tel'})}
      <Form.Row>
        <Form.Item>
          <Form.Label>SHOW END USER DID</Form.Label>
          <Tooltip text="When enabled, show your end users the DID used in their SISA with you">
            <Switch
              disabled={updating}
              checked={organizationProfileWithChanges.display_end_user_did}
              onChange={value => { onChange({display_end_user_did: value}) }}
            />
          </Tooltip>
        </Form.Item>
      </Form.Row>
      <Form.Row className="OrganizationProfileForm-saveRow">
        <Button
          disabled={updating || !hasChanges}
          type="normal"
          value="Reset"
          onClick={this.props.onCancel}
        />
        <Button
          disabled={updating || !hasChanges}
          type="success"
          submit
        >
          { updating ? 'Saving…' : 'Save' }
        </Button>
        {
          !published &&
          <Button
            disabled={updating}
            onClick={this.showSisaModal}
            type="success"
          >
            Publish to JLINC
          </Button>
        }
      </Form.Row>
      {
        this.state.sisaModalVisible &&
        <SisaModal
          organization={organizationProfile}
          onClose={this.hideSisaModal}
          onSignSisa={this.publish}
          signingSisa={updating}
        />
      }
    </Form>
  }
}

class SisaModal extends Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSignSisa: PropTypes.func.isRequired,
    signingSisa: PropTypes.bool,
  }

  state = { showingSisaSummary: true }

  showSisaSummary = () => {
    this.setState({showingSisaSummary: true})
  }

  hideSisaSummary = () => {
    this.setState({showingSisaSummary: false})
  }

  render({ organization, onClose, signingSisa, onSignSisa }) {

    const { showingSisaSummary } = this.state

    const sisaNotice = <SisaText
      text={BOB_SISA_NOTICE}
      organization={organization}
      onSummaryClick={this.showSisaSummary}
    />
    const fullSisaLink = <Link
      type="link"
      href={FULL_SISA_URL}
      newWindow
    >
      Full SISA text
    </Link>


    const sisaSummary = <div>
      <SisaText text={BOB_SISA_SUMMARY} organization={organization} />
      <br />
      <br />
      {fullSisaLink}
    </div>

    let headerClassName = 'OrganizationProfileForm-SisaModal-header'
    if (!showingSisaSummary) headerClassName += ' OrganizationProfileForm-SisaModal-header-notice'

    const headerText = showingSisaSummary
      ? 'Standard Information Sharing Agreement Summary'
      : 'Notice to Data Custodian'

    return <Modal
      className="OrganizationProfileForm-SisaModal OrganizationProfileForm-SisaModal"
      onClose={onClose}
    >
      {
        !showingSisaSummary
          ? <BackButton onClick={this.showSisaSummary}/>
          : null
      }
      <Header className={headerClassName} size="lg">{headerText}</Header>
      <p className="OrganizationProfileForm-SisaModal-textContent">
        { showingSisaSummary
          ? sisaSummary
          : sisaNotice
        }
      </p>
      { showingSisaSummary
        ? <div className="OrganizationProfileForm-SisaModal-continueButton">
          <Button type="primary" onClick={this.hideSisaSummary}>
            Continue
          </Button>
        </div>
        : <Form.ButtonRow>
          <Button
            disabled={!!signingSisa}
            type="normal"
            value="cancel"
            onClick={onClose}
          />
          <Button
            disabled={!!signingSisa}
            type="success"
            value={signingSisa ? 'Publishing…' : 'Sign SISA & Publish'}
            onClick={onSignSisa}
          />
        </Form.ButtonRow>
      }
    </Modal>
  }
}
