import { h, Component } from 'preact'
import PropTypes from 'prop-types'

const PERSONAL_DATA_LABELS = require('jlinc-shared/personal_data_labels')
import {
  isCustomPersonalDataField,
  getLabelForPersonalDataField,
} from 'lib/accountDataSpec'

import ErrorMessage from 'components/ErrorMessage'
import Form from 'components/Form'
import DropdownPicker from 'components/DropdownPicker'
import Button from 'components/Button'
import IconButton from 'components/IconButton'

import './index.sass'

export default class BobsPersonalDataConfigurationForm extends Component {

  static propTypes = {
    customPersonalDataFields: PropTypes.array,
    requestedData: PropTypes.objectOf(PropTypes.bool).isRequired,
    changes: PropTypes.objectOf(PropTypes.bool),
    onChange: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: ErrorMessage.propTypes.error,
  }

  reset = () => {
    this.props.onReset()
  }

  save = () => {
    this.props.onSave(this.props.changes)
  }

  removeField = propName => {
    this.props.onChange({ [propName]: false })
  }

  resetField = propName => {
    this.props.onChange({ [propName]: undefined })
  }

  addField = () => {
    const label = this.state.newFieldLabel
    if (!label) return
    const standardField = Object.entries(PERSONAL_DATA_LABELS)
      .find(entry => entry[1] === label)
    const propName = standardField ? standardField[0] : `_${label}`
    this.props.onChange({
      [propName]: true,
    })
    this.setState({ newFieldLabel: '' })
  }

  setNewFieldLabel = newFieldLabel => {
    this.setState({ newFieldLabel })
  }

  render(){
    const {
      customPersonalDataFields,
      requestedData,
      changes = {},
      disabled,
      error,
    } = this.props

    const hasChanges = changes && Object.keys(changes).length > 0
    const value = { ...requestedData, ...changes }
    const allFields = new Set(Object.keys(PERSONAL_DATA_LABELS))
    ;[requestedData, changes].forEach(set => {
      Object.keys(set).forEach(propName => {
        if (isCustomPersonalDataField(propName)) allFields.add(propName)
      })
    })
    const visibleFields = Array.from(allFields)
      .filter(propName => propName in changes || requestedData[propName] === true)
    return <div className="BobsPersonalDataConfigurationForm">
      <ErrorMessage error={error} />
      {visibleFields.map(propName =>
        <PersonalDataField
          disabled={disabled}
          propName={propName}
          unsaved={propName in changes}
          enabled={value[propName]}
          onRemove={() => { this.removeField(propName) }}
          onReset={() => { this.resetField(propName) }}
        />
      )}
      <Form.ButtonRow>
        <AddFieldForm
          disabled={disabled}
          customPersonalDataFields={customPersonalDataFields}
          value={this.state.newFieldLabel}
          onInput={this.setNewFieldLabel}
          onSubmit={this.addField}
          visibleFields={visibleFields}
        />
        <Button
          disabled={disabled || !hasChanges}
          type="normal"
          value="reset"
          onClick={this.reset}
        />
        <Button
          disabled={disabled || !hasChanges}
          type="primary"
          value="save"
          onClick={this.save}
        />
      </Form.ButtonRow>
    </div>
  }
}

function PersonalDataField({
  disabled,
  propName,
  unsaved,
  enabled,
  onRemove,
  onReset,
}){
  let className = "BobsPersonalDataConfigurationForm-field"
  if (unsaved) {
    className += " BobsPersonalDataConfigurationForm-unsaved"
    if (!enabled) className += " BobsPersonalDataConfigurationForm-removing"
  }
  return <div className={className}>
    <div>
      <span>{getLabelForPersonalDataField(propName)}</span>
      {unsaved && <i>&nbsp;({enabled ? 'adding' : 'removing'})</i>}
    </div>
    {unsaved
      ? <IconButton
        disabled={disabled}
        type="cancel-circled"
        title={`re-${enabled ? 'disable' : 'enable'} this field`}
        onClick={onReset}
      />
      : <IconButton
        disabled={disabled}
        type="cancel-circled"
        title="remove field"
        onClick={onRemove}
      />
    }
  </div>
}

function AddFieldForm({disabled, customPersonalDataFields, value, onInput, onSubmit, visibleFields}){
  const options = []
  Object.entries(PERSONAL_DATA_LABELS).forEach(([field, label]) => {
    if (!visibleFields.includes(field)) options.push(label)
  })
  if (customPersonalDataFields) customPersonalDataFields.forEach(label => {
    if (!visibleFields.includes(`_${label}`)) options.push(label)
  })
  return <Form onSubmit={onSubmit} className="BobsPersonalDataConfigurationForm-AddFieldForm">
    <DropdownPicker
      allowCustom
      placeholder="Add Field"
      options={options}
      value={value || ''}
      onInput={onInput}
    />
    <Button
      submit
      disabled={disabled || !value}
      type="normal"
      value="Add"
    />
  </Form>
}
