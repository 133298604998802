import { h, Component } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'

import BobLayout from 'components/BobLayout'
import Accordion from 'components/Accordion'
import OneColumn from 'components/OneColumn'
import BobsPersonalDataConfigurationForm from 'components/BobsPersonalDataConfigurationForm'
import BobCommunicationChannels from 'components/BobCommunicationChannels'
import BobConsentsForm from 'components/BobConsentsForm'

class ConsumerAgreementsPage extends Page {

  static pageTitle = 'Consumer - Agreements'

  render() {
    const {
      appAction,
      takeAction,
      organizationProfile,
      organizationProfileChanges,
      errorUpdatingOrganizationProfile,
      updatingOrganizationConsents,
      updatingOrganizationConsentsError,
      updatingOrganizationCommunicationChannels,
      updatingOrganizationCommunicationChannelsError,
      updatingOrganizationRequestedData,
      updatingOrganizationRequestedDataError,
      customPersonalDataFields,
    } = this.props

    return <BobLayout {...{
      className: 'ConsumerAgreementsPage',
      location: this.props.location,
      crumbs: ['Consumer', 'Data Agreements'],
      organizationProfile,
    }}>
      <OneColumn noPadding>
        <ConsumerAgreementsPageContent
          params={this.props.params}
          organizationProfile={organizationProfile}
          organizationProfileChanges={organizationProfileChanges}
          errorUpdatingOrganizationProfile={errorUpdatingOrganizationProfile}
          updatingOrganizationConsents={updatingOrganizationConsents}
          updatingOrganizationConsentsError={updatingOrganizationConsentsError}
          updatingOrganizationCommunicationChannels={updatingOrganizationCommunicationChannels}
          updatingOrganizationCommunicationChannelsError={updatingOrganizationCommunicationChannelsError}
          updatingOrganizationRequestedData={updatingOrganizationRequestedData}
          updatingOrganizationRequestedDataError={updatingOrganizationRequestedDataError}
          customPersonalDataFields={customPersonalDataFields}
          stageOrganizationRequestedDataChanges={appAction('stageOrganizationRequestedDataChanges')}
          clearOrganizationRequestedDataChanges={appAction('clearOrganizationRequestedDataChanges')}
          saveOrganizationRequestedDataChanges={appAction('saveOrganizationRequestedDataChanges')}
          toggleConsent={consents => {
            takeAction('updateConsents', consents)
          }}
          toggleCommunicationChannel={appAction('toggleCommunicationChannel')}
        />
      </OneColumn>
    </BobLayout>
  }
}

class ConsumerAgreementsPageContent extends Component{
  render() {
    const {
      organizationProfile,
      organizationProfileChanges,
      updatingOrganizationRequestedData,
      updatingOrganizationRequestedDataError,
      customPersonalDataFields,
      errorUpdatingOrganizationProfile,
      updatingOrganizationConsents,
      updatingOrganizationConsentsError,
      updatingOrganizationCommunicationChannels,
      updatingOrganizationCommunicationChannelsError,
      stageOrganizationRequestedDataChanges,
      clearOrganizationRequestedDataChanges,
      saveOrganizationRequestedDataChanges,
      toggleConsent,
      toggleCommunicationChannel,
    } = this.props

    return <Accordion useLocalStorageKey="b-portal:/c/agreements:accordion" >
      <Accordion.Section title="Data Type Requests">
        <BobsPersonalDataConfigurationForm
          customPersonalDataFields={customPersonalDataFields}
          requestedData={organizationProfile.requested_data}
          changes={(organizationProfileChanges || {}).requested_data}
          disabled={updatingOrganizationRequestedData}
          error={updatingOrganizationRequestedDataError || errorUpdatingOrganizationProfile}
          onChange={stageOrganizationRequestedDataChanges}
          onReset={clearOrganizationRequestedDataChanges}
          onSave={saveOrganizationRequestedDataChanges}
        />
      </Accordion.Section>
      <Accordion.Section title="GDPR Permissions">
        <BobConsentsForm
          onChange={toggleConsent}
          organization={organizationProfile}
          disabled={updatingOrganizationConsents}
          error={updatingOrganizationConsentsError}
          type="GDPR"
        />
      </Accordion.Section>
      <Accordion.Section title="CCPA Permissions">
        <BobConsentsForm
          onChange={toggleConsent}
          organization={organizationProfile}
          disabled={updatingOrganizationConsents}
          error={updatingOrganizationConsentsError}
          type="CCPA"
        />
      </Accordion.Section>
      <Accordion.Section title="Communication Channels">
        <BobCommunicationChannels
          channels={organizationProfile.communication_channels}
          onChange={toggleCommunicationChannel}
          error={updatingOrganizationCommunicationChannelsError}
          disabled={updatingOrganizationCommunicationChannels}
        />
      </Accordion.Section>
    </Accordion>
  }
}

export default bindToAppState(
  [
    'organizationProfile',
    'organizationProfileChanges',
    'errorUpdatingOrganizationProfile',
    'updatingOrganizationConsents',
    'updatingOrganizationConsentsError',
    'updatingOrganizationCommunicationChannels',
    'updatingOrganizationCommunicationChannelsError',
    'updatingOrganizationRequestedData',
    'updatingOrganizationRequestedDataError',
    'customPersonalDataFields',
  ],
  ConsumerAgreementsPage,
  props => {
    props.takeAction('customPersonalDataFields.load')
  },
)
