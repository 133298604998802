const MAX_FILE_SIZE_IN_MB = 200
const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MB * 1000000

export async function uploadAsset({ file, assetUploadToken }){
  if (!file) throw new Error(`file is required`)
  if (!assetUploadToken) throw new Error(`assetUploadToken is required`)
  if (file.size > MAX_FILE_SIZE)
    throw new Error(`"${file.name}" is too big. Please select a file smaller than ${MAX_FILE_SIZE_IN_MB}mb.`)
  const postUrl = `${ASSETS_SERVER_URL}/upload/${assetUploadToken}` // eslint-disable-line
  const formData = new FormData()
  formData.append('asset', file)
  const response = await fetch(postUrl, {method: 'POST', body: formData})
  const { error, url } = await response.json()
  if (error) throw new Error(error)
  return url
}
