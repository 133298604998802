import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import TextInput from 'components/TextInput'
import Button from 'components/Button'
import Card from 'components/Card'
import Form from 'components/Form'
import Checkbox from 'components/Checkbox'
import ErrorMessage from 'components/ErrorMessage'
import BetaDisclaimer from 'components/BetaDisclaimer'
import RequestPasswordResetForm from 'components/RequestPasswordResetForm'
import Spinner from 'components/Spinner'
import Link from 'components/Link'
import './index.sass'

export default class BobLoginForm extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    loggingIn: PropTypes.bool,
    error: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onRequestPasswordReset: PropTypes.func.isRequired,
    passwordResetRequest: RequestPasswordResetForm.propTypes.passwordResetRequest,
    resetRequestPasswordResetForm: PropTypes.func.isRequired,
  }

  componentDidMount(){
    this.focusFirstEmptyInput()
  }

  state = {
    email: "",
    password: "",
    requestPasswordResetFormVisible: false,
    showPassword: false,
    keepMeLoggedIn: false,
  }

  focusFirstEmptyInput(){
    if (this.state.requestPasswordResetFormVisible) return

    setTimeout(() => {
      const focus = input => {
        if (input) TextInput.focus(input)
      }
      if (!this.state.email) {
        focus(this.emailInput)
      } else if (!this.state.password) {
        focus(this.passwordInput)
      } else {
        focus(this.emailInput)
      }
    }, 0)
  }

  onSubmit = () => {
    this.props.onSubmit(this.state)
  }

  setEmail = email => {
    email = email.trim()
    this.setState({email})
  }

  setPassword = password => {
    this.setState({password})
  }

  setShowPassword = showPassword => {
    this.setState({showPassword})
  }

  setKeepMeLoggedIn = keepMeLoggedIn => {
    this.setState({keepMeLoggedIn})
  }

  toggleResetPasswordForm = () => {
    this.setState(
      {requestPasswordResetFormVisible: !this.state.requestPasswordResetFormVisible},
      () => { this.focusFirstEmptyInput() }
    )
  }

  onRequestPasswordReset = ({login}) => {
    this.props.onRequestPasswordReset({ email: login })
  }

  render(){
    const {
      disabled,
      loggingIn,
      error,
      passwordResetRequest,
      resetRequestPasswordResetForm,
    } = this.props

    const {
      email = '',
      password,
      requestPasswordResetFormVisible,
      showPassword,
      keepMeLoggedIn,
    } = this.state

    const loginForm = () => <div>
      <Card.Header>
        Business Login
      </Card.Header>
      <Card.Body>
        <ErrorMessage error={error} />
        <Form onSubmit={this.onSubmit}>
          <Form.Item>
            <TextInput
              ref={input => { this.emailInput = input }}
              value={email}
              onInput={this.setEmail}
              placeholder="email"
              name="email"
              type="email"
              disabled={disabled}
              autocomplete="email,username"
              lpignore={false}
              required
            />
          </Form.Item>

          <Form.Item>
            <TextInput
              ref={input => { this.passwordInput = input }}
              value={password}
              onChange={this.setPassword}
              placeholder="password"
              name="password"
              disabled={disabled}
              password={!showPassword}
              autocomplete="current-password"
              lpignore={false}
              required
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              checked={showPassword}
              onChange={this.setShowPassword}
              label="Show password"
              disabled={disabled}
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              checked={keepMeLoggedIn}
              onChange={this.setKeepMeLoggedIn}
              label="Keep me logged in"
              disabled={disabled}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              submit
              block
              disabled={disabled}
              fat
            >
              { loggingIn ?
                <span><Spinner />&nbsp;Logging in…</span> :
                <span>Login</span>
              }
            </Button>
          </Form.Item>
        </Form>
      </Card.Body>
      <div className="BobLoginForm-forgotPassword">
        <Link type="text" onClick={this.toggleResetPasswordForm}>
          Forgot password?
        </Link>
      </div>
      <BetaDisclaimer />
    </div>

    return <Card className="BobLoginForm">
      {
        requestPasswordResetFormVisible ?
          <RequestPasswordResetForm
            inputType="email"
            placeholder="email"
            goBack={this.toggleResetPasswordForm}
            login={email}
            onInput={this.setEmail}
            onSubmit={this.onRequestPasswordReset}
            reset={resetRequestPasswordResetForm}
            passwordResetRequest={passwordResetRequest}
          /> :
          loginForm()
      }
    </Card>
  }
}
