import api from '../api'

export default function(){
  const { loadingConsentReport } = this.getState()
  if (loadingConsentReport) return

  this.setState({
    loadingConsentReport: true,
    loadingConsentReportError: undefined,
  })

  api.loadConsentReport().then(
    ({consentReport}) => {
      this.setState({
        loadingConsentReport: undefined,
        consentReport,
      })
    },
    loadingConsentReportError => {
      this.setState({
        loadingConsentReport: undefined,
        loadingConsentReportError,
      })
    }
  )
}
