import api from '../api'

export default function({ pageUid }){
  const { page, filters } = JSON.parse(pageUid)

  const loadingKey = `loadingEndUsers:${pageUid}`
  const pageKey = `endUsers:${pageUid}`
  const errorKey = `loadingEndUsersError:${pageUid}`

  const { [loadingKey]: loadingEndUsers } = this.getState()

  if (loadingEndUsers) return

  this.setState({
    [loadingKey]: true,
    [errorKey]: undefined,
  })

  api.loadEndUsers({ page, filters }).then(
    ({endUsers}) => {
      this.setState({
        [loadingKey]: undefined,
        [pageKey]: endUsers,
      })
    },
    loadingEndUsersError => {
      this.setState({
        [loadingKey]: undefined,
        [errorKey]: loadingEndUsersError,
      })
    }
  )
}
