import api from '../api'

export default function({ resetPasswordToken, password, passwordConfirmation }) {
  this.setState({
    passwordResetRequest: {pending: true},
  })

  api.resetPassword({ resetPasswordToken, password, passwordConfirmation }).then(
    () => {
      this.setState({
        passwordResetRequest: {success: true},
      })
    },
    error => {
      this.setState({
        passwordResetRequest: {error},
      })
    }
  )
}
