import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import Button from 'components/Button'
import './index.sass'

export default class SideNav extends Component {
  render(props){
    let className = `SideNav ${props.className || ''}`
    return <div className={className}>
      <div className="SideNav-content">
        {props.children}
      </div>
    </div>
  }
}

SideNav.Header = ({children, className = ''}) => {
  return <div className={`SideNav-Header ${className}`}>
    {children}
  </div>
}

SideNav.Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

SideNav.Button = ({children, value, icon, href, onClick, selected, disabled, className = '' }) => {
  className = `SideNav-Button ${className}`
  if (selected) className += ' SideNav-Button-selected'
  return <Button
    type="none"
    className={className}
    href={href}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon type={icon} />
    { children ? children : <span>{value}</span> }
  </Button>
}

SideNav.Button.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.string.isRequired,
}

SideNav.Dropdown = ({open, onClick, icon, value, selected, children}) => {
  let className = 'SideNav-Dropdown'
  if (selected) className += ' SideNav-Dropdown-selected'
  return <div className={className}>
    <SideNav.Button
      icon={icon}
      selected={selected}
      onClick={onClick}
      value={value}
    >
      <span>&nbsp;{value}</span>
      <Icon
        type="right-open"
        size="xs"
        rotate={open ? 90 : null}
        transitionRotation
      />
    </SideNav.Button>
    <div className="SideNav-Dropdown-options">
      { open ? children : null }
    </div>
  </div>
}

SideNav.Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const DropdownOption = ({selected, children, href, disabled}) => {
  let className = 'SideNav-Dropdown-Option'
  if (selected) className += ' SideNav-Dropdown-Option-selected'
  return <Button
    type="none"
    className={className}
    href={href}
    disabled={disabled}
  >
    {children}
  </Button>
}

DropdownOption.propTypes = {
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

SideNav.Dropdown.Option = DropdownOption
