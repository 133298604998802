import api from '../api'

export default function({ email }) {
  this.setState({
    passwordResetRequest: {pending: true},
  })

  api.requestPasswordReset({ email })
    .then(
      () => {
        this.setState({
          passwordResetRequest: {sent:true},
        })
      },
      error => {
        this.setState({
          passwordResetRequest: {error},
        })
      }
    )
}
