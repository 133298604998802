import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Form from 'components/Form'
import './index.sass'

export default class Prompt extends Component {

  static propTypes = {
    className: PropTypes.string,
    query: PropTypes.node.isRequired,
    onConfirmation: PropTypes.func.isRequired,
    onRejection: PropTypes.func.isRequired,
    autoFocus: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['rejection', 'confirmation']),
    ]).isRequired,
    rejectionValue: PropTypes.string,
    confirmationValue: PropTypes.string,
  }

  static defaultProps = {
    autoFocus: 'rejection'
  }

  componentDidMount(){
    if (this.props.autoFocus){
      if (this.props.autoFocus === true || this.props.autoFocus === 'rejection'){
        this.rejectionButton.base.focus()
      }
      if (this.props.autoFocus === 'confirmation'){
        this.confirmationButton.base.focus()
      }
    }
  }

  render(){

    const {
      className,
      query,
      onConfirmation,
      onRejection,
      autoFocus, // eslint-disable-line
      rejectionValue = "no",
      confirmationValue = "Yes",
      ...props
    } = this.props

    return <Modal
      className={`Prompt ${className || ''}`}
      onClose={() => {}}
      {...props}
    >
      <div className="Prompt-query">{query}</div>
      <Form.ButtonRow>
        <Button
          ref={node => {this.rejectionButton = node}}
          type="normal"
          thin
          value={rejectionValue}
          onClick={onRejection}
        />
        <Button
          ref={node => {this.confirmationButton = node}}
          type="primary"
          thin
          value={confirmationValue}
          onClick={onConfirmation}
        />
      </Form.ButtonRow>
    </Modal>
  }
}
