import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import classNames from 'lib/classNames'
import { useAppState } from 'lib/appState'

import Alert from 'components/Alert'
import IconButton from 'components/IconButton'

import './index.sass'

export default function ErrorMessage({
  className, error, dismissable, slideDown, onDismiss
}){
  onDismiss = useMemo(
    () => (onDismiss || (error && error.dismiss && (() => { error.dismiss() }))),
    [onDismiss, error]
  )
  className = classNames('ErrorMessage', { dismissable, className })
  let errorMessageText = error && (error instanceof Error ? error.message : `${error}`)

  const alert = errorMessageText && <div className="ErrorMessage-alertWrapper">
    { dismissable &&
      (onDismiss
        ? <DismissViaCallback {...{error, onDismiss}}/>
        : <DismissViaAppState {...{error}}/>
      )
    }
    <Alert type="error" className="ErrorMessage-error">
      {errorMessageText}
    </Alert>
  </div>

  if (slideDown) return (
    <TransitionGroup className={className}>
      {alert &&
        <CSSTransition
          key={errorMessageText}
          classNames="ErrorMessage-slideDown"
          timeout={300}
        >{alert}</CSSTransition>
      }
    </TransitionGroup>
  )
  return <div className={className}>{alert}</div>
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Error),
  ]),
  dismissable: PropTypes.bool,
  className: PropTypes.string,
  slideDown: PropTypes.bool,
  onDismiss: PropTypes.func,
}

ErrorMessage.defaultProps = {
  dismissable: true,
  slideDown: true,
}

function DismissViaCallback({ error, onDismiss }){
  return <DismissButton
    onClick={() => { onDismiss(error) }}
  />
}

function DismissViaAppState({ error }){
  const { takeAction } = useAppState(undefined, 'ErrorMessage')
  return <DismissButton
    onClick={() => {
      takeAction('ui.dismissError', error)
    }}
  />
}

function DismissButton({ onClick }){
  return <IconButton
    type="cancel-circled"
    className="ErrorMessage-dismissButton"
    size="sm"
    onClick={onClick}
  />
}
