import { h } from 'preact'
import PropTypes from 'prop-types'

import ErrorMessage from 'components/ErrorMessage'
import SwitchListMember from 'components/SwitchListMember'
import './index.sass'

// TODO rename content to switches
export default function SwitchList({content, onChange, className, disabled, error}) {
  const contentsList = content.map(({title, checked, unsaved}, index) =>
    <SwitchListMember
      key={index}
      checked={checked}
      label={title}
      unsaved={unsaved}
      onChange={checked => onChange(index, checked)}
      disabled={disabled}
    />
  )

  return <div className={`SwitchList ${className || ''}`}>
    <ErrorMessage error={error} />
    {contentsList}
  </div>
}

SwitchList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      unsaved: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: ErrorMessage.propTypes.error,
}
