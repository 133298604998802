import { h, Component } from 'preact'
import { bindToAppState } from 'lib/appState'
import PNFO from 'jlinc-shared/PNFO'

import Page from 'lib/Page'

import BobLayout from 'components/BobLayout'
import OneColumn from 'components/OneColumn'
import Loading from 'components/Loading'
import Accordion from 'components/Accordion'
import BobMailing from 'components/BobMailing'
import BobConsentReport from 'components/BobConsentReport'
import BobInviteEndUsers from 'components/BobInviteEndUsers'

import { VALID_CONSENT_KEYS, BOB_CONSENT_DESCRIPTIONS } from 'lib/accountDataSpec'

import './index.sass'

class ConsumerAdminPage extends Page {

  static pageTitle = 'Consumer - Dashboard'

  componentWillReceiveProps(nextProps){
    super.componentWillReceiveProps(nextProps)
    const nextPage = getPageFromProps(nextProps)
    const {
      [`endUserInvites:${nextPage}`]: endUserInvites,
    } = nextProps
    if (!endUserInvites) this.props.takeAction('invites.loadEndUserInvites', { page: nextPage })
  }

  createEndUserInvite = ({ email, mobile }) => {
    const page = getPageFromProps(this.props)
    this.props.takeAction('invites.create', { email, mobile, page })
  }

  deleteEndUserInvite = inviteToken => {
    const confirmed = confirm('Are you sure you want to delete this end user invite?') // eslint-disable-line
    if (!confirmed) return
    const page = getPageFromProps(this.props)
    this.props.takeAction('invites.deleteEndUserInvite', { inviteToken, page })
  }

  render(){
    const page = getPageFromProps(this.props)
    const {
      currentUser: { sessionId },
      selectedMailingConsentType,
      organizationProfile,
      consentReport,
      invitingEndUser,
      invitedEndUserSignupUrl,
      invitingEndUserError,
      deletingEndUserInvite,
      errorDeletingEndUserInvite,
      [`loadingEndUserInvites:${page}`]: loadingEndUserInvites,
      [`endUserInvites:${page}`]: endUserInvites,
      [`loadingEndUserInvitesError:${page}`]: loadingEndUserInvitesError,
    } = this.props

    const layoutProps = {
      className: 'ConsumerAdminPage',
      location: this.props.location,
      crumbs: ['Consumer', 'Admin Dashboard'],
      organizationProfile,
    }

    if (!consentReport) {
      return <BobLayout {...layoutProps}>
        <Loading type="fullPage" />
      </BobLayout>
    }

    return <BobLayout {...layoutProps}>
      <OneColumn noPadding>
        <ConsumerAdminPageContent
          selectedMailingConsentType={selectedMailingConsentType}
          organizationProfile={organizationProfile}
          consentReport={consentReport}
          deleteEndUserInvite={this.deleteEndUserInvite}
          createEndUserInvite={this.createEndUserInvite}
          setSelectedConsentType={consentType => {
            this.props.takeAction('setSelectedMailingConsentType', {consentType})
          }}
          downloadMailingListForConsentType={() => this.props.takeAction('downloadMailingListForConsentType')}
          resetEndUserInvite={this.props.appAction('invites.resetEndUserInvite')}
          invitingEndUser={invitingEndUser}
          invitedEndUserSignupUrl={invitedEndUserSignupUrl}
          invitingEndUserError={invitingEndUserError}
          deletingEndUserInvite={deletingEndUserInvite}
          errorDeletingEndUserInvite={errorDeletingEndUserInvite}
          loadingEndUserInvites={loadingEndUserInvites}
          endUserInvites={endUserInvites}
          loadingEndUserInvitesError={loadingEndUserInvitesError}
          sessionId={sessionId}
          page={page}
        />
      </OneColumn>
    </BobLayout>
  }
}

class ConsumerAdminPageContent extends Component {

  state = { currentAccordionSection: 0 }

  setCurrentAccordianSection = currentAccordionSection => { this.setState({ currentAccordionSection }) }

  render() {
    const {
      selectedMailingConsentType,
      organizationProfile,
      setSelectedConsentType,
      downloadMailingListForConsentType,
      consentReport,
      resetEndUserInvite,
      invitedEndUserSignupUrl,
      invitingEndUserError,
      deletingEndUserInvite,
      errorDeletingEndUserInvite,
      loadingEndUserInvites,
      endUserInvites,
      loadingEndUserInvitesError,
      sessionId,
      page,
      createEndUserInvite,
      deleteEndUserInvite,
    } = this.props

    const activeConsents = Object.keys(organizationProfile.consents)
      .filter(key => organizationProfile.consents[key].enabled)

    return <div>
      <div className="ConsumerAdminPage-explanationText">
        <p>
          This dashboard provides a place to paste a single email or mobile to generate an invite link to
          copy and send, and a way to upload a csv file from your emailing service or CRM.
        </p>
        <p>
          You can upload a csv file for your list and then download a csv that includes an invite link
          for each user. You upload that new csv file to your email service and send out an invitation to each user.
        </p>
        <p>
          When a user creates their account, it is pre-populated with the data you have for them. This helps each
          user manage and correct their own data. When a new user connects with your {PNFO.singular} here on JLINC,
          they share their good data with you. When any user changes their info, they can instantly share the
          new version with all {PNFO.plural} that they are connected to, under their control.
        </p>
      </div>
      <Accordion useLocalStorageKey="b-portal:/c/admin:accordion">
        <Accordion.Section title="Invite Users">
          <BobInviteEndUsers
            resetEndUserInvite={resetEndUserInvite}
            organizationProfile={organizationProfile}
            invitedEndUserSignupUrl={invitedEndUserSignupUrl}
            invitingEndUserError={invitingEndUserError}
            deletingEndUserInvite={deletingEndUserInvite}
            errorDeletingEndUserInvite={errorDeletingEndUserInvite}
            loadingEndUserInvites={loadingEndUserInvites}
            endUserInvites={endUserInvites}
            loadingEndUserInvitesError={loadingEndUserInvitesError}
            sessionId={sessionId}
            createEndUserInvite={createEndUserInvite}
            deleteEndUserInvite={deleteEndUserInvite}
            page={page}
          />
        </Accordion.Section>
        <Accordion.Section title="Permission Report">
          <BobConsentReport
            consentReport={consentReport.map(userReport => {
              const normalizedConsents = { ...userReport.consents }
              Object.keys(normalizedConsents).forEach(key => {
                if (!VALID_CONSENT_KEYS.includes(key)) delete normalizedConsents[key]
              })
              return {
                ...userReport,
                consents: normalizedConsents,
              }
            })}
            consentDescriptions={BOB_CONSENT_DESCRIPTIONS}
          />
        </Accordion.Section>
        <Accordion.Section title="Permission CSV">
          <BobMailing
            onChange={setSelectedConsentType}
            value={selectedMailingConsentType}
            activeConsents={activeConsents}
            onSubmit={downloadMailingListForConsentType}
          />
        </Accordion.Section>
      </Accordion>
    </div>
  }
}

function getPageFromProps(props){
  let page = props.location.query.page
  if (typeof page === 'undefined') return 1
  page = Number(page)
  return page < 1 ? 1 : page
}

export default bindToAppState(
  props => {
    const page = getPageFromProps(props)
    return [
      'currentUser',
      'selectedMailingConsentType',
      'organizationProfile',
      'consentReport',
      'invitingEndUser',
      'invitedEndUserSignupUrl',
      'invitingEndUserError',
      'deletingEndUserInvite',
      'errorDeletingEndUserInvite',
      `loadingEndUserInvites:${page}`, // loadingEndUserInvites,
      `endUserInvites:${page}`, // endUserInvites,
      `loadingEndUserInvitesError:${page}`, // loadingEndUserInvitesError,
      'organizationProfile',
    ]
  },
  ConsumerAdminPage,
  props => {
    const page = getPageFromProps(props)
    props.takeAction('invites.loadEndUserInvites', { page })
    props.takeAction('loadConsentReport')
  },
)
