import moment from 'moment'
import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useUpdateEverySecond from 'lib/useUpdateEverySecond'

export default function TimeAgo({
  className = '',
  time,
  tiny,
  ...props
}){
  useUpdateEverySecond()
  const {title, value} = useMemo(
    () => {
      const timeAsMoment = moment(time)
      const delta = timeAsMoment.diff(moment())
      const title = timeAsMoment.format('MMM Do YYYY h:mmA')
      const value = tiny
        ? (delta > -44000) ? `${Math.round(Math.abs(delta) / 1000)}s` : tinyFormat(timeAsMoment.fromNow(), delta)
        : (delta > -2000 ? 'just now' : timeAsMoment.fromNow())
      return {title, value}
    },
    [time, tiny]
  )
  return <span {...{
    ...props,
    className: classNames('TimeAgo', { className }),
    title,
  }}>{value}</span>
}

TimeAgo.propTypes = {
  className: PropTypes.string,
  time: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  tiny: PropTypes.bool,
}

function tinyFormat(timeAgoString){
  const matches = timeAgoString.toLowerCase().match(/^(\d+|an?)\s+(\w+?)s? ago/)
  if (!matches) return ''
  let [_, int, range] = matches
  if (int === 'a' || int === 'an') int = '1'
  int = parseInt(int, 10)
  if (range === 'month'){
    int *= 4
    range = 'week'
  }
  return `${int}${range[0].toLowerCase()}`
}
