import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import { isInvalidPassword } from 'lib/validators'
import filterObject from 'lib/filterObject'
import { organizationProfileShape } from 'lib/organizationProfileHelpers'
import { getGDPRConsents } from 'lib/accountDataSpec'

import Layout from 'components/Layout'
import SideNav from 'components/SideNav'
import OneColumn from 'components/OneColumn'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Header from 'components/Header'
import Accordion from 'components/Accordion'
import ErrorMessage from 'components/ErrorMessage'
import FormBuilder from 'components/FormBuilder'
import Form from 'components/Form'
import Checkbox from 'components/Checkbox'
import Dropdown from 'components/Dropdown'
import Prompt from 'components/Prompt'
import CountryDropdownPicker from 'components/CountryDropdownPicker'
import SuccessAnimation from 'components/SuccessAnimation'

import BobsPersonalDataConfigurationForm from 'components/BobsPersonalDataConfigurationForm'
import BobCommunicationChannels from 'components/BobCommunicationChannels'
import BobBrandingForm from 'components/BobBrandingForm'
// import BobPreviewOrganizationPage from 'components/BobPreviewOrganizationPage'
import BobMarketplaceTagsForm from 'components/BobMarketplaceTagsForm'
import BobConsentsForm from 'components/BobConsentsForm'

import ORGANIZATION_TYPES_BY_PURPOSE from 'jlinc-shared/organization_purposes_labels'
import { ORGANIZATION_TYPE_LABELS } from 'lib/organizationProfileHelpers'

import './index.sass'

export default class BobSignupWizard extends Component {

  static propTypes = {
    signupToken:         PropTypes.string,
    signupEmail:         PropTypes.string,
    completeSignup:      PropTypes.func,
    completingSignup:    PropTypes.bool,
    completeSignupError: ErrorMessage.propTypes.error,
    logout:              PropTypes.func,
    currentUser:         PropTypes.object,

    // organizationProfile props
    organizationProfile: PropTypes.shape({
      ...organizationProfileShape,
      apikey:         PropTypes.string.isRequired,
      salesforce:     PropTypes.bool.isRequired,
      public_allowed: PropTypes.bool.isRequired,
      public:         organizationProfileShape.public.isRequired,
    }),
    stageOrganizationProfileChanges:  PropTypes.func.isRequired,
    clearOrganizationProfileChanges:  PropTypes.func.isRequired,
    organizationProfileChanges:       PropTypes.shape(organizationProfileShape),
    updateOrganizationProfile:        PropTypes.func.isRequired,
    updatingOrganizationProfile:      PropTypes.bool.isRequired,
    errorUpdatingOrganizationProfile: ErrorMessage.propTypes.error,

    // feed props
    loadingOrganizationFeedPosts: PropTypes.bool,
    organizationFeedPosts:        PropTypes.object,
    draftFeedPost:                PropTypes.object,
    updateDraftFeedPost:          PropTypes.func.isRequired,
    uploadFeedPostMediaAsset:     PropTypes.func.isRequired,
    uploadingMediaAsset:          PropTypes.bool,
    uploadMediaAssetError:        ErrorMessage.propTypes.error,
    postDraftFeedPost:            PropTypes.func.isRequired,
    postingToFeed:                PropTypes.bool,
    errorPostingToFeed:           ErrorMessage.propTypes.error,
    deleteFeedPost:               PropTypes.func.isRequired,
    deletingPosts:                PropTypes.bool,
    errorsDeletingPosts:          PropTypes.object,
  }

  constructor(props){
    super()
    this.stepRefs = []
    this.state = {
      currentStepIndex: this.findNextUnfinishedStep(props),
    }
  }

  componentDidMount(){
    const { currentStepIndex } = this.state
    this.scrollToStep(currentStepIndex)
    this.focusFirstInputOfCurrentStep()
  }

  componentWillReceiveProps(nextProps){
    const justLoggedIn = () =>
      !this.props.currentUser && nextProps.currentUser

    const justUpdatedOrganizationProfile = () =>
      this.props.updatingOrganizationProfile &&
      !nextProps.updatingOrganizationProfile &&
      !nextProps.errorUpdatingOrganizationProfile

    const justPostedToOurFeed = () =>
      this.props.postingToFeed &&
      !nextProps.postingToFeed &&
      !nextProps.errorPostingToFeed

    const justLoadedOrganizationFeedPosts = () =>
      !this.props.organizationFeedPosts &&
      nextProps.organizationFeedPosts

    if (
      justLoggedIn() ||
      justUpdatedOrganizationProfile() ||
      justPostedToOurFeed() ||
      justLoadedOrganizationFeedPosts()
    ){
      const nextUnfinishedStep = this.findNextUnfinishedStep(nextProps)
      if (nextUnfinishedStep !== this.state.currentStepIndex){
        this.startSuccessAnimation()
      }
    }
  }

  getSteps(){
    return Object.keys(STEPS)
  }

  isStepFinished(step, props = this.props){
    return STEPS[step].isStepFinished(props)
  }

  findNextUnfinishedStep(props = this.props){
    const { signupToken } = props
    const nextUnfinishedStep = signupToken ? 0 :
      this.getSteps(props).findIndex(step => !this.isStepFinished(step, props))
    return nextUnfinishedStep
  }

  startSuccessAnimation(){
    this.stopSuccessAnimation()
    // TODO get the height of the container right now and make the check animation
    // that same height
    this.setState({showingSuccessAnimationForStep: this.state.currentStepIndex})
    this.stopSuccessAnimationTimeout = setTimeout(() => {
      if (this.state.currentStepIndex === (this.getSteps().length - 1)){
        this.stopSuccessAnimation()
        return
      }
      this.gotoStep(this.state.currentStepIndex + 1)
      this.stopSuccessAnimationTimeout = setTimeout(() => {
        this.stopSuccessAnimation()
      }, 500)
    }, 1500)
  }

  stopSuccessAnimation = () => {
    clearTimeout(this.stopSuccessAnimationTimeout)
    this.setState({showingSuccessAnimationForStep: null})
  }

  gotoStep = stepIndex => {
    if (!(stepIndex === null || (typeof stepIndex === 'number' && stepIndex >= 0))) return
    this.scrollToStep(stepIndex)
    if (this.state.currentStepIndex !== stepIndex){
      this.setState({ currentStepIndex: stepIndex })
      clearTimeout(this.focusFirstInputOfCurrentStepTimeout)
      this.focusFirstInputOfCurrentStepTimeout = setTimeout(this.focusFirstInputOfCurrentStep, 500)
    }
  }

  focusFirstInputOfCurrentStep = () => {
    const { currentStepIndex } = this.state
    const ref = this.stepRefs[currentStepIndex]
    if (!ref || !ref.base) return
    const inputs = Array.from(ref.base.querySelectorAll('input,textarea,select,button'))
    if (inputs.includes(window.document.activeElement)) return
    const inputToFocus = inputs.find(input => input.style.display !== 'none' && !input.disabled)
    if (inputToFocus) inputToFocus.focus()
  }

  scrollToStep(nextStepIndex){
    if (typeof nextStepIndex !== 'number' || nextStepIndex < 0) return
    const { currentStepIndex } = this.state
    if (!this.base) return
    const sections = this.base.querySelectorAll('.Accordion-Section')
    const nextSection = sections[nextStepIndex]
    const scrollParent = window.document.body

    const currentSection = sections[currentStepIndex]
    let contentChangeOffset = 0

    if (currentSection && currentStepIndex < nextStepIndex){
      contentChangeOffset -= getSectionContentHeight(currentSection)
    }
    const pageTopOffset = -58 // scroll past the top nav bar
    const currentScrollTop = scrollParent.scrollTop
    const nextSectionOffsetTop = nextSection.getBoundingClientRect().y + currentScrollTop
    const targetScrollTop = nextSectionOffsetTop + pageTopOffset + contentChangeOffset
    const distance = (targetScrollTop - currentScrollTop)
    if (Math.abs(distance) < 1) return

    const start = Date.now()
    const duration = 500
    const step = () => {
      const elapsed = Date.now() - start
      if (elapsed >= duration){
        scrollParent.scrollTop = targetScrollTop
        return
      }
      const percentage = elapsed / duration
      const partialDistance = distance * percentage
      const partialScrollTop = currentScrollTop + partialDistance
      scrollParent.scrollTop = partialScrollTop
      window.cancelAnimationFrame(this.scrollTopStepTimeout)
      this.scrollTopStepTimeout = window.requestAnimationFrame(step)
    }
    step()
  }

  render(){
    const {
      signupEmail,
      logout,
      currentUser,
    } = this.props

    const email = currentUser && currentUser.email || signupEmail

    const { currentStepIndex, showingSuccessAnimationForStep } = this.state

    const steps = this.getSteps()
    const nextUnfinishedStep = this.findNextUnfinishedStep()
    const availableSteps = steps.filter((step, stepIndex) =>
      step === 'CompleteSignupStep' ? !currentUser :
      stepIndex <= nextUnfinishedStep
    )
    const completedSteps = steps.filter(step =>
      step === 'CompleteSignupStep' ? currentUser :
      availableSteps.includes(step) && this.isStepFinished(step)
    )

    const stepProps = {
      email,
      ...this.props
    }

    const stepsWithState = steps.map((step, index) => ({
      step,
      current: currentStepIndex === index,
      enabled: availableSteps.includes(step),
      completed: completedSteps.includes(step),
      title: STEPS[step].title,
      Contents: STEPS[step],
    }))

    const sideNav = <BobSignupWizardSideNav
      gotoStep={this.gotoStep}
      steps={stepsWithState}
    />

    const sections = stepsWithState.map(({current, enabled, completed, Contents}, index) => {
      const showingSuccessAnimation = showingSuccessAnimationForStep === index

      let className = 'BobSignupWizard-Section'
      if (completed) className += ' BobSignupWizard-Section-completed'
      if (current) className += ' BobSignupWizard-Section-current'

      const title = <Header size="lg">
        <Icon type={completed ? 'ok-circled' : 'right-open'}/>
        {Contents.title}
      </Header>
      const contents = (
        (!current && !enabled) ? null :
        showingSuccessAnimation ? <StepSuccessAnimation /> :
        <Contents
          ref={node => { this.stepRefs[index] = node }}
          {...stepProps}
          open={current}
          completed={completed}
          gotoNextStep={() => { this.gotoStep(index + 1) }}
        />
      )
      return <Accordion.Section
        key={index}
        title={title}
        disabled={!enabled}
        className={className}
      >
        {contents}
      </Accordion.Section>
    })

    return <Layout
      className="BobSignupWizard"
      currentUser={{email}}
      crumbs={[]}
      sideNav={sideNav}
      logout={logout}
    >
      <OneColumn>
        <Accordion
          openSection={currentStepIndex}
          onChange={this.gotoStep}
          className="BobSignupWizard-accordion"
        >{sections}</Accordion>
      </OneColumn>
    </Layout>
  }
}

const StepSuccessAnimation = () =>
  <div className="BobSignupWizard-success">
    <SuccessAnimation height={200} width={200} />
  </div>


const BobSignupWizardSideNav = ({ gotoStep, steps }) => {
  const buttons = steps.map(({current, enabled, completed, title}, index) =>
    <Button
      type="none"
      className={
        'BobSignupWizard-SideNav-Button' +
        (current ? ' BobSignupWizard-SideNav-Button-selected' : '') +
        (completed ? ' BobSignupWizard-SideNav-Button-completed' : '') +
        (steps[index + 1] && !steps[index + 1].completed ? ' BobSignupWizard-SideNav-Button-nextIncomplete' : '')
      }
      onClick={() => { enabled && gotoStep(index) }}
      disabled={!enabled && !completed}
    >
      <svg height="150" width="20">

        <circle
          className="BobSignupWizard-SideNav-Button-circle"
          cx="10"
          cy="9"
          r="8"
        />
        {completed &&
          <circle
            className="BobSignupWizard-SideNav-Button-completed-circle"
            cx="10"
            cy="9"
            r="4"
          />
        }
        {
          steps[index + 1] && steps[index + 1].enabled &&
          <line x1="10" y1="18" x2="10" y2="130" className="BobSignupWizard-SideNav-Button-bottomline"/>
        }
      </svg>
      <span>{title}</span>
    </Button>
  )
  return <SideNav className="BobSignupWizard-SideNav">
    <SideNav.Header>NAVIGATION</SideNav.Header>
    {buttons}
  </SideNav>
}

class CompleteSignupStep extends Component {

  static propTypes = {
    email: PropTypes.string.isRequired,
    signupToken: PropTypes.string.isRequired,
    completeSignup: PropTypes.func.isRequired,
    completingSignup: PropTypes.bool.isRequired,
    errorUpdatingOrganizationProfile: ErrorMessage.propTypes.error,
  }

  static title = 'Create Account'

  static isStepFinished(props){
    return !!props.currentUser
  }

  state = {
    name: '',
    password: '',
    passwordConfirmation: '',
    keepMeLoggedIn: false,
  }

  validate({ name, password, passwordConfirmation }){
    if (!name || name.length < 3) return 'organization name must be at least three characters'
    if (!password) return 'password is required'
    if (!passwordConfirmation) return 'password confirmation is required'
    if (isInvalidPassword(password)) return isInvalidPassword(password)
    if (password !== passwordConfirmation) return 'password and password confirmation must match'
  }

  onChange = ({ name, password, passwordConfirmation, keepMeLoggedIn }) => {
    this.setState({ name, password, passwordConfirmation, keepMeLoggedIn })
  }

  completeSignup = () => {
    const { signupToken } = this.props
    const { name, password, passwordConfirmation, keepMeLoggedIn } = this.state
    this.props.completeSignup({
      signupToken, name: name.trim(), password, passwordConfirmation, keepMeLoggedIn
    })
  }

  render(){
    return <FormBuilder
      error={this.props.errorUpdatingOrganizationProfile}
      value={this.state}
      onChange={this.onChange}
      onSubmit={this.completeSignup}
      validator={this.validate}
      submitting={!!this.props.completingSignup}
      render={form =>
        <div>
          <p>
            JLINC is a totally new way of letting your users control their relationship
            with you and with each of their {PNFO.plural}.
          </p>
          {form.textItem({
            valueProp: 'name',
            label: 'ORGANIZATION NAME',
            required: true,
            maxLength: 29,
          })}
          {form.textItem({
            label: 'EMAIL',
            type: 'email',
            disabled: true,
            value: this.props.email,
            bindTo: false,
            autocomplete: 'username', // this helps browsers save the username and password
          })}
          {form.passwordItem({
            valueProp: 'password',
            autocomplete: 'new-password',
            required: true,
          })}
          {form.passwordConfirmationItem({
            valueProp: 'passwordConfirmation',
            autocomplete: 'new-password',
            required: true,
          })}
          {form.item({
            valueProp: 'keepMeLoggedIn',
            input: <Checkbox label="Keep me logged in" />
          })}
          <Form.ButtonRow>
            {form.submitButton({
              value: 'Create Account',
              submittingValue: 'Creating Account…'
            })}
          </Form.ButtonRow>
        </div>
      }
    />
  }
}

class OrganizationProfileStep extends Component {
  static propTypes = {
    errorUpdatingOrganizationProfile: ErrorMessage.propTypes.error,
    organizationProfile: PropTypes.shape(organizationProfileShape).isRequired,
    organizationProfileChanges: PropTypes.shape(organizationProfileShape),
    stageOrganizationProfileChanges: PropTypes.func.isRequired,
    updateOrganizationProfile: PropTypes.func.isRequired,
    updatingOrganizationProfile: PropTypes.bool.isRequired,
  }

  static isStepFinished(props){
    const keys = this.keys || []
    return props.organizationProfile && keys.every(key => {
      const value = props.organizationProfile[key]
      return (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        (typeof value !== 'object' || Object.keys(value).length > 0)
      )
    })
  }

  getKeys(){ return this.constructor.keys || [] }

  change(...args){
    this.props.stageOrganizationProfileChanges(...args)
  }

  submit(...args){
    this.props.updateOrganizationProfile(...args)
  }

  render(){
    const keys = this.getKeys()
    return <FormBuilder
      error={this.props.errorUpdatingOrganizationProfile}
      value={filterObject(this.props.organizationProfileChanges, keys)}
      persistedValues={filterObject(this.props.organizationProfile, keys)}
      submitting={!!this.props.updatingOrganizationProfile}
      validator={this.validator}
      onChange={this.change.bind(this)} /* bind here so super works in subclass */
      onSubmit={this.submit.bind(this)} /* bind here so super works in subclass */
      render={this.renderForm}
    />
  }
}

class InfoStep extends OrganizationProfileStep {

  static keys = ['domain', 'address', 'city', 'state', 'post_code', 'country', 'contact_phone']

  static title = 'Information'

  renderForm = form =>
    <div>
      <p>
        This is the basic info that will be displayed about your {PNFO.plural} – to real human users who have
        created an account using a mobile phone. This info is NOT published on the open Internet.
      </p>
      <Form.Row>
        {form.textItem({
          valueProp: 'domain',
          label: 'WEBSITE',
          type: 'text',
          autocomplete: 'website',
          required: true,
          defaultValue: 'http://www.',
        })}
        {form.textItem({
          valueProp: 'contact_phone',
          label: 'CONTACT PHONE',
          type: 'tel',
          autocomplete: 'tel',
          required: true,
        })}
      </Form.Row>
      {form.textItem({
        valueProp: 'address',
        label: 'STREET',
        name: 'street-address',
        autocomplete: 'street-address',
        required: true,
      })}
      <Form.Row>
        {form.textItem({
          valueProp: 'city',
          label: 'TOWN / CITY',
          name: 'city',
          autocomplete: 'address-level1 locality city',
          required: true,
        })}
        {form.textItem({
          valueProp: 'state',
          label: 'STATE / PROVINCE / COUNTY',
          name: 'mailingstate',
          autocomplete: 'administrative-area state province region',
          required: true,
        })}
      </Form.Row>
      <Form.Row>
        {form.textItem({
          valueProp: 'post_code',
          label: 'POSTCODE / ZIP CODE',
          name: 'mailingpostalcode',
          autocomplete: 'postal-code',
          required: true,
        })}
        {form.item({
          valueProp: 'country',
          label: 'COUNTRY',
          input: <CountryDropdownPicker
            readOnly={false}
            required
          />
        })}
      </Form.Row>
      <Form.ButtonRow>
        {form.resetButton({})}
        {form.submitButton({value: 'Update', submittingValue: 'Updating…'})}
      </Form.ButtonRow>
    </div>
}

class IdentificationStep extends OrganizationProfileStep {

  static title = 'Identification'

  static keys = ['name', 'purpose', 'type', 'legal_name']

  state = {
    typeConfirmed: false,
    confirmingType: false,
  }

  validator(changes){
    if (!('type' in changes))
      return 'type is required'
    if (!('purpose' in changes))
      return 'Purpose is required'
  }

  change(value){
    if (value.type !== this.state.confirmingType) this.setState({ confirmingType: false })
    if (value.type !== this.state.typeConfirmed) this.setState({ typeConfirmed: false })
    super.change(value)
  }

  submit(value){
    if (value.type && !this.state.typeConfirmed) this.setState({ confirmingType: value.type })
    else super.submit(value)
  }

  confirmType = form => {
    this.setState(
      { confirmingType: false, typeConfirmed: form.value.type },
      () => { form.submit() },
    )
  }

  stopConfirmingType = () => {
    this.setState({ confirmingType: false })
  }

  renderForm = form =>
    <div>
      <p>
        This is the legal identity info for your {PNFO.singular}.
      </p>
      {this.state.confirmingType && this.state.confirmingType === form.value.type &&
        <Prompt
          autoFocus={'confirmation'}
          query={`
            Please confirm your ${PNFO.singular} type: "${ORGANIZATION_TYPE_LABELS[form.value.type]}."
            You cannot change this later!
          `}
          onConfirmation={() => {
            this.confirmType(form)
          }}
          onRejection={this.stopConfirmingType}
        />
      }
      {form.textItem({
        valueProp: 'name',
        label: 'ORGANISATION NAME',
        disabled: true,
      })}
      {form.item({
        valueProp: 'purpose',
        label: 'ORGANIZATION PURPOSE',
        name: 'purpose',
        input: <Dropdown
          placeHolder="Choose a purpose"
          options={Object.keys(ORGANIZATION_TYPES_BY_PURPOSE)}
          fullWidth
          disabled={!!form.persistedValues.purpose}
          required
        />
      })}
      {form.item({
        valueProp: 'type',
        label: 'ORGANIZATION TYPE',
        name: 'organization_type',
        input: <Dropdown
          options={
            (ORGANIZATION_TYPES_BY_PURPOSE[form.value.purpose || form.persistedValues.purpose] || [])
              .map(type => ({
                display: ORGANIZATION_TYPE_LABELS[type],
                value: type,
              }))
          }
          fullWidth
          disabled={!!form.persistedValues.type || !form.value.purpose}
          required
        />
      })}
      {form.textItem({
        valueProp: 'legal_name',
        label: 'ORGANISATION LEGAL NAME',
        name: 'legal-entit-name',
        required: true,
      })}
      <Form.ButtonRow>
        {form.resetButton({})}
        {form.submitButton({value: 'Update', submittingValue: 'Updating…'})}
      </Form.ButtonRow>
    </div>
}

class ThemeStep extends OrganizationProfileStep {
  static title = 'Theme & Branding'

  static keys = [
    'consumer_icon',
    'consumer_logo',
    'consumer_banner',
    'consumer_description',
    'public',
  ]

  static isStepFinished = function(props){
    return (
      props.organizationProfile &&
      props.organizationProfile.consumer_description
    )
  }

  render(){
    return <div>
      <p>
        Click-on, or drag-and-drop a file on the box to upload a LOGO and BANNER image.
      </p>
      <p>
        You can also skip this now or change or complete it later under Brand & Posts.
      </p>
      <BobBrandingForm
        brandingType="consumer"
        organizationProfile={this.props.organizationProfile}
        organizationProfileChanges={this.props.organizationProfileChanges}
        stageOrganizationProfileChanges={changes => {
          copyConsumerBrandingToBuisnessAndNetwork(
            changes,
            this.props.stageOrganizationProfileChanges,
          )
        }}
        updateOrganizationProfile={changes => {
          copyConsumerBrandingToBuisnessAndNetwork(
            changes,
            this.props.updateOrganizationProfile,
          )
        }}
        updatingOrganizationProfile={this.props.updatingOrganizationProfile}
        errorUpdatingOrganizationProfile={this.props.errorUpdatingOrganizationProfile}
      />
    </div>
  }
}

function copyConsumerBrandingToBuisnessAndNetwork(changes, onChange){
  if (changes.consumer_icon) {
    changes.business_icon = changes.consumer_icon
  }
  if (changes.consumer_logo) {
    changes.business_logo = changes.consumer_logo
  }
  if (changes.consumer_banner) {
    changes.business_banner = changes.consumer_banner
  }
  if (changes.consumer_description) {
    changes.business_description = changes.consumer_description
  }

  onChange(changes)
}

function DataTypeRequestsStep(props){
  return <div>
    <p>
      This section lets you set what personal data you ask users to share with you.
    </p>
    <p>
      Only email, first and last name are preset by default. To add additional data fields, simply start
      typing and choose from the auto-complete, or type a custom field name and hit return. You can come
      back and adjust this at any time.
    </p>
    <BobsPersonalDataConfigurationForm
      customPersonalDataFields={props.customPersonalDataFields}
      requestedData={props.organizationProfile.requested_data || {}}
      changes={(props.organizationProfileChanges || {}).requested_data || {}}
      disabled={props.updatingOrganizationRequestedData}
      error={props.updatingOrganizationRequestedDataError}
      onChange={props.stageOrganizationRequestedDataChanges}
      onReset={props.clearOrganizationRequestedDataChanges}
      onSave={props.saveOrganizationRequestedDataChanges}
    />
    <Form.ButtonRow>
      <Button
        type="primary"
        value="next"
        onClick={props.gotoNextStep}
      />
    </Form.ButtonRow>
  </div>
}
DataTypeRequestsStep.title = 'Requested User Data'
DataTypeRequestsStep.isStepFinished = function(props){
  return (
    props.organizationProfile &&
    Object.values(props.organizationProfile.requested_data).some(x => x)
  )
}

function GDPRPermissionsStep(props){
  return <div>
    <p>
      This section lets you set which GDPR data permissions you request from users.
    </p>
    <p>
      The list covers generic marketing options. A few basic ones have been pre-set by default. Select any
      others that you actually need. You can also come back and change these later.
    </p>
    <p>
      In Europe, a new law called GDPR requires all corporation that do business with people there to comply with
      these instructions from users.
    </p>
    <BobConsentsForm
      onChange={props.updateConsents}
      organization={props.organizationProfile}
      disabled={props.updatingOrganizationConsents}
      error={props.updatingOrganizationConsentsError}
      descriptionsDefaultOpen
      type="GDPR"
    />
    <Form.ButtonRow>
      <Button
        type="primary"
        value="next"
        onClick={props.gotoNextStep}
        disabled={!props.completed}
      />
    </Form.ButtonRow>
  </div>
}
GDPRPermissionsStep.title = 'Requested GDPR Permissions'
GDPRPermissionsStep.isStepFinished = function(props){
  const GDPRConsents = getGDPRConsents({ })
  return (
    props.organizationProfile &&
    GDPRConsents.some(consent => consent in props.organizationProfile.consents)
  )
}

function CCPAPermissionsStep(props){
  return <div>
    <p>
      This section lets you set which CCPA permissions you request from users.
    </p>
    <p>
      In California, CCPA requires permission to sell users data.
    </p>
    <BobConsentsForm
      onChange={props.updateConsents}
      organization={props.organizationProfile}
      disabled={props.updatingOrganizationConsents}
      error={props.updatingOrganizationConsentsError}
      descriptionsDefaultOpen
      type="CCPA"
    />
    <Form.ButtonRow>
      <Button
        type="primary"
        value="next"
        onClick={props.gotoNextStep}
        disabled={!props.completed}
      />
    </Form.ButtonRow>
  </div>
}
CCPAPermissionsStep.title = 'Requested CCPA Permissions'
CCPAPermissionsStep.isStepFinished = function(){ return true }

function CommunicationChannelsStep(props){
  return <div>
    <p>
      This section lets you set which channels you use to communicate with users. It also offers users a way to
      tell you which channels they prefer. Networks and {PNFO.plural} can also use this to specify the channel they use
      to communicate as a group (for example Telegram).
    </p>
    <p>
      Channels owned by companies that track and monetize personal data as their core business model are not
      currently listed.
    </p>
    <BobCommunicationChannels
      channels={props.organizationProfile.communication_channels}
      onChange={props.toggleCommunicationChannel}
      error={props.updatingOrganizationCommunicationChannelsError}
      disabled={props.updatingOrganizationCommunicationChannels}
    />
    <Form.ButtonRow>
      <Button
        type="primary"
        value="next"
        onClick={props.gotoNextStep}
        disabled={!props.completed}
      />
    </Form.ButtonRow>
  </div>
}
CommunicationChannelsStep.title = 'Requested Communication Channels'
CommunicationChannelsStep.isStepFinished = function(props){
  return (
    props.organizationProfile &&
    Object.keys(props.organizationProfile.communication_channels).length > 0
  )
}

class MarketplaceStep extends OrganizationProfileStep {
  static title ='Marketplace'
  static keys = [
    'consumer_marketplace_industries',
    'consumer_marketplace_brands',
    'consumer_marketplace_tags',
  ]
  static isStepFinished(){
    return true
  }

  render(){
    return <div>
      <p>
        This lets you describe how users can find your info, solutions, products & services in the Marketplace.
      </p>
      <p>
        You must enter at least one keyword to describe your {PNFO.singular}. More are recommended.
      </p>
      <p>
        To create a keyword tag, simply type in the field and hit return, or click on +.
      </p>
      <p>
        Tags are freeform. You can name your industry, enter your brand, and add any other keywords
        that a user might type to search.
      </p>
      <p>
        When a user enters the same words in their marketplace search page, your matching item will appear
        in their home page feed. This is a simple working beta. Auto-complete lists are being expanded
        and the marketplace will ultimately be powered by AI.
      </p>
      <BobMarketplaceTagsForm
        marketplace="consumer"
        organizationProfile={this.props.organizationProfile}
        organizationProfileChanges={this.props.organizationProfileChanges}
        stageOrganizationProfileChanges={this.props.stageOrganizationProfileChanges}
        updateOrganizationProfile={this.props.updateOrganizationProfile}
        updatingOrganizationProfile={this.props.updatingOrganizationProfile}
        errorUpdatingOrganizationProfile={this.props.errorUpdatingOrganizationProfile}
      />
      <Form.ButtonRow>
        <Button
          type="primary"
          value="next"
          onClick={this.props.gotoNextStep}
          disabled={!this.props.completed}
        />
      </Form.ButtonRow>
    </div>
  }
}

function PublishStep(props){
  return <div>
    <ErrorMessage error={props.errorUpdatingOrganizationProfile} />
    <Button
      block
      fat
      type="primary"
      value={props.updatingOrganizationProfile ? `Publishing your ${PNFO.singular}…` : `Publish your ${PNFO.singular}`}
      disabled={!!props.updatingOrganizationProfile}
      onClick={() => { props.updateOrganizationProfile({ published: true }) }}
    />
  </div>
}
PublishStep.title = 'Publish'
PublishStep.isStepFinished = function(props){
  return props.organizationProfile && props.organizationProfile.published
}

const STEPS = {
  CompleteSignupStep,
  InfoStep,
  IdentificationStep,
  ThemeStep,
  DataTypeRequestsStep,
  GDPRPermissionsStep,
  CCPAPermissionsStep,
  CommunicationChannelsStep,
  MarketplaceStep,
  PublishStep,
}

function getSectionContentHeight(section){
  return section.querySelector('.Accordion-Section-content > div').clientHeight
}
