import { h, Component, cloneElement } from 'preact'
import { Children } from 'preact/compat'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import Button from 'components/Button'
import './index.sass'

export default class Accordion extends Component {

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    openSection: PropTypes.number,
    defaultOpenSection: PropTypes.number,
    onChange: PropTypes.func,
    useLocalStorageKey: PropTypes.string,
  }

  static defaultProps = {
    defaultOpenSection: 0,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.openSection !== nextProps.openSection) {
      this.openSection(nextProps.openSection)
    }
  }

  isUsingLocalStorage(props = this.props) {
    return typeof props.useLocalStorageKey === 'string'
  }

  getOpenSectionIndex(props = this.props){
    if (this.isUsingLocalStorage(props)) {
      const valueInLocalStorage = window.localStorage[props.useLocalStorageKey]
      return /^-?\d+$/.test(valueInLocalStorage)
        ? +valueInLocalStorage
        : this.props.defaultOpenSection
    }
    return props.openSection
  }

  openSection(index) {
    if (this.isUsingLocalStorage()) {
      window.localStorage[this.props.useLocalStorageKey] = index
      this.forceUpdate()
    } else if (this.props.onChange) {
      this.props.onChange(index)
    }
  }

  closeSection() {
    if (this.isUsingLocalStorage()) {
      window.localStorage[this.props.useLocalStorageKey] = -1
      this.forceUpdate()
    } else if (this.props.onChange) this.props.onChange(null)
  }

  render(){
    const {
      className = '',
      children,
      onChange, // eslint-disable-line
      ...props
    } = this.props

    const openSectionIndex = this.getOpenSectionIndex()
    const sections = Children.map(children, (section, index) =>
      cloneElement(section, {
        open: index === openSectionIndex,
        onTitleClick: () => {
          if (index === openSectionIndex) {
            this.closeSection()
          } else {
            this.openSection(index)
          }
        },
      })
    )
    return <div
      {...props}
      className={`Accordion ${className}`}
    >
      {sections}
    </div>
  }
}


const ANIMATION_DURATION = 500 // ms
const waitForAnimation = cb => setTimeout(cb, ANIMATION_DURATION)

class Section extends Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    onTitleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
  }

  componentWillReceiveProps(nextProps){
    if (this.props.open !== nextProps.open){
      this.onOpenOrClose(!this.props.open && nextProps.open)
    }
  }

  state = {
    animating: false,
    contentHeight: '5px',
  }

  onOpenOrClose(opening){
    if (this.props.open && this.base.querySelector('.Accordion-Section-content').style.maxHeight === '0px') {
      opening = true
    }
    clearTimeout(this._timeout)
    const contentDiv = this.base.querySelector('.Accordion-Section-content')
    const contentHeight = this.base.querySelector('.Accordion-Section-content > div').clientHeight
    contentDiv.style.maxHeight = opening ? '0px' : `${contentHeight}px`
    this.setState({animating: true})
    this._timeout = setTimeout(() => {
      contentDiv.style.maxHeight = opening ? `${contentHeight}px` : '0px'
      this._timeout = waitForAnimation(() => {
        this.setState({animating: false})
        contentDiv.style.maxHeight = opening ? 'initial' : '0px'
      })
    }, 10)
  }

  render(){
    const { animating } = this.state
    let {
      className = '',
      title,
      children,
      disabled,
      open,
      onTitleClick,
      // onChange,
      ...props
    } = this.props
    if (disabled) className += ` Accordion-Section-disabled`
    if (open) className += ` Accordion-Section-open`
    if (animating) className += ` Accordion-Section-animating`
    return <div {...props} className={`Accordion-Section ${className}`}>
      <Button
        type="none"
        className="Accordion-Section-title"
        onClick={onTitleClick}
        disabled={disabled}
      >
        <span>{title}</span>
        <Icon
          type="right-open"
          size="lg"
          rotate={open ? "90" : undefined}
          transitionRotation
        />
      </Button>
      <div className="Accordion-Section-content">
        <div>{children}</div>
      </div>
    </div>
  }
}

Accordion.Section = Section
