import { h } from 'preact'
import { bindToAppState } from 'lib/appState'
import Page from 'lib/Page'
import CONSENTS from 'jlinc-shared/consents'

import BobLayout from 'components/BobLayout'
import ErrorMessage from 'components/ErrorMessage'
import OneColumn from 'components/OneColumn'
import Header from 'components/Header'
import PageOfEndUsers, { FILTER_TYPES } from 'components/PageOfEndUsers'

class UserDataPage extends Page {

  static pageTitle = 'Consumer - User Data'

  componentDidMount(){
    super.componentDidMount()
    this.loadEndUsersPage(getPageUidFromProps(this.props))
  }

  componentWillReceiveProps(nextProps){
    super.componentWillReceiveProps(nextProps)
    const thisPageUid = getPageUidFromProps(this.props)
    const nextPageUid = getPageUidFromProps(nextProps)
    if (thisPageUid !== nextPageUid) this.loadEndUsersPage(nextPageUid)
  }

  loadEndUsersPage(pageUid){
    this.props.takeAction('loadEndUsers', { pageUid })
  }

  toHref = ({ page, filters }) => {
    let href = `/c/user-data?page=${page}`
    if (filters && filters.length > 0){
      const value = encodeURIComponent(
        JSON.stringify(
          filters.map(([type, value]) => [FILTER_TYPES.indexOf(type), value])
        )
      )
      href = href += `&filters=${value}`
    }
    return href
  }

  render() {
    const pageUid = getPageUidFromProps(this.props)
    const {
      currentUser: { sessionId },
      organizationProfile,
      [`loadingEndUsers:${pageUid}`]: loadingEndUsers,
      [`endUsers:${pageUid}`]: endUsers,
      [`loadingEndUsersError:${pageUid}`]: loadingEndUsersError,
    } = this.props

    const { page, filters } = JSON.parse(pageUid)

    const filteredConsents = organizationProfile
      ? CONSENTS.filter(key => organizationProfile.consents[key])
      : []

    const requestedPersonalData = organizationProfile
      ? Object.entries(organizationProfile.requested_data)
        .filter(([, enabled]) => enabled)
        .map(([key]) => key)
      : []

    return <BobLayout {...{
      className: 'UserDataPage',
      location: this.props.location,
      crumbs: ['Consumer', 'User Data'],
      organizationProfile,
    }}>
      <OneColumn >
        <ErrorMessage error={loadingEndUsersError} />
        <Header size="md" underlined>User Data</Header>
        <p>
          This page gives you a view of all of the data and permissions you have for your connected users.
          The interface allows you to sort and filter lists of users that can be exported as csv files and
          uploaded to an email service or CRM.
        </p>
        <p>
          The "Rights Holder DID" is a unique identifier that allows users to maintain a persistent identity
          with each entity they connect with, while not actually divulging any personal data if they so
          choose. This allows the system to fulfill all GDPR personal data rights requirements in the EU.
        </p>
        <p>
          This is still an early beta interface and this function will also be augmented by API calls with
          services in the future. Direct two way data exchange with Salesforce has also been demonstrated using JLINC.
        </p>
        <PageOfEndUsers
          toHref={this.toHref}
          loading={loadingEndUsers || !endUsers}
          sessionId={sessionId}
          page={page}
          filters={filters}
          endUsers={endUsers}
          consents={filteredConsents}
          requestedPersonalData={requestedPersonalData}
        />
      </OneColumn>
    </BobLayout>
  }
}

function getPageAndFiltersFromProps(props){
  let { page, filters } = props.location.query
  filters = filters ? JSON.parse(filters) : []
  filters = filters.map(([typeIndex, value]) => [FILTER_TYPES[typeIndex], value])
  page = typeof page === 'string' ? Number(page) : 1
  return { page, filters }
}

function getPageUidFromProps(props){
  const { page, filters } = getPageAndFiltersFromProps(props)
  return JSON.stringify({ page, filters })
}

export default bindToAppState(
  props => {
    const pageUid = getPageUidFromProps(props)
    return [
      'currentUser',
      'organizationProfile',
      `loadingEndUsers:${pageUid}`, // loadingEndUsers,
      `endUsers:${pageUid}`, // endUsers,
      `loadingEndUsersError:${pageUid}`, // loadingEndUsersError,
    ]
  },
  UserDataPage,
)
