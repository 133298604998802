import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import TextInput from 'components/TextInput'

import './index.sass'

let DropdownPickerDataListIdSequence = 0

export default class DropdownPicker extends Component {
  static propTypes = {
    options: PropTypes.array,
    allowCustom: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    unsaved: PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    onInput: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }

  static defaultProps = {
    allowCustom: false,
  }

  componentDidMount(){
    if (
      this.props.defaultValue &&
      typeof this.props.value !== 'string'
    ) this.onChange(this.props.defaultValue)
  }

  componentWillReceiveProps(nextProps){
    this.setState({ value: nextProps.value })
  }

  onInput = value => {
    this.setState({ value })
    if (this.props.onInput) this.props.onInput(value)
    if (
      this.props.onChange &&
      (
        value === '' ||
        (
          value !== this.props.value &&
          (this.props.allowCustom || this.props.options.includes(value))
        )
      )
    ) {
      this.props.onChange(value)
    }
  }

  onChange = value => {
    if (value === this.props.value) return
    if (this.props.options.includes(value) || this.props.allowCustom) {
      this.props.onChange(value)
    }else{
      this.forceUpdate()
    }
  }

  onBlur = event => {
    const { value } = event.target
    if (!this.props.allowCustom && !this.props.options.includes(value)) {
      this.setState({ value: '' })
    }
    this.onChange(value)
    if (this.props.onBlur) this.props.onBlur(event)
  }

  uuid(){
    if (!this._uuid) this._uuid = DropdownPickerDataListIdSequence++
    return this._uuid
  }

  render(){
    const {
      disabled,
      className = '',
      unsaved,
      options,
      ...props
    } = this.props
    delete props.value
    delete props.allowCustom
    delete props.onChange
    delete props.defaultValue

    const value = typeof this.state.value === 'string'
      ? this.state.value
      : this.props.value

    const uniqueListId = `DropdownPickerList-${this.uuid()}`

    const optionsList = options.map(option =>
      <option option={option} value={option} />
    )

    return <div
      className={
        `DropdownPicker ` +
        `${className} ` +
        `${disabled ? 'DropdownPicker-disabled' : ''} ` +
        `${unsaved ? 'DropdownPicker-unsaved' : ''}`
      }
    >
      <TextInput
        {...props}
        value={value}
        onInput={this.onInput}
        unsaved={unsaved}
        list={uniqueListId}
        className="DropdownPicker-input"
        onChange={this.onChange}
        onBlur={this.onBlur}
        disabled={disabled}
        type="text"
      />
      <datalist id={uniqueListId}>
        {optionsList}
      </datalist>
    </div>
  }
}
