import { h } from 'preact'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Icon from 'components/Icon'
import './index.sass'

export default function BackButton({ className = '', ...props }){
  return <Button
    className={`BackButton ${className}`}
    type="link"
    {...props}
  >
    <Icon type="left-open"/>
    <span className="BackButton-text">Back</span>
  </Button>
}

BackButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
}
