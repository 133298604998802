import { h } from 'preact'
import { useState, useCallback } from 'preact/hooks'
import { useAppState } from 'lib/appState'

export default function useAssetUpload(componentName){
  const { takeAction } = useAppState(undefined, componentName)

  let [upload, setUpload] = useState()

  const uploadAsset = useCallback(
    file => {
      if (upload) upload.cancelled = true
      upload = { file, uploading: true }
      setUpload(upload)
      takeAction('assets.uploadDirect', file).then(
        url => {
          if (upload.cancelled) return
          setUpload({
            file,
            uploading: false,
            assetUrl: url,
            mimeType: file.type,
          })
        },
        error => {
          if (upload.cancelled) return
          setUpload({
            file,
            uploading: false,
            error,
          })
        }
      )
    },
    [upload],
  )

  const cancelAssetUpload = useCallback(
    () => {
      if (!upload) return
      upload.cancelled = true
      setUpload()
    },
    [upload],
  )

  return {
    uploadAsset,
    cancelAssetUpload,
    uploadingAsset: !!(upload && upload.uploading),
    uploadingFile: upload && upload.file,
    assetUrl: upload && upload.assetUrl,
    mimeType: upload && upload.mimeType,
    uploadingAssetError: upload && upload.error,
  }
}
