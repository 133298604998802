import { h, Component } from 'preact'
import PropTypes from 'prop-types'

export default class Page extends Component {

  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      params: PropTypes.object.isRequired,
    }).isRequired,
    params: PropTypes.object.isRequired,
  }

  componentDidMount(){
    this.setPageTitle(this.props)
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(nextProps){
    this.setPageTitle(nextProps)
  }

  getPageTitle(){
    return this.constructor.pageTitle || this.constructor.name
  }

  setPageTitle(props){
    setPageTitle(this.getPageTitle(props))
  }

}


export function setPageTitle(title){
  window.document.title = `${title}`
}
