import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import HelpButton from 'components/HelpButton'
import './index.sass'

export default function Card(props){
  props = Object.assign({}, props) // clone props

  let className = `Card ${props.className || ''}`

  let header
  if (props.title){
    header = <Card.Header
      title={props.title}
      help={props.help}
    />
    delete props.title
    delete props.help
  }

  return <div {...props} className={className}>
    {header}
    {props.children}
  </div>
}

Card.propTypes = {
  title: PropTypes.string,
  help: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Card.Header = ({className, centered, ...props}) => {
  className = classNames('Card-Header', {className, centered})

  let help
  if (props.help){
    help = <HelpButton>{props.help}</HelpButton>
    delete props.help
  }

  let title
  if (props.title){
    title = <Card.Header.Title>
      <span>{props.title}&nbsp;</span>
      {help}
    </Card.Header.Title>
    delete props.title
  }

  return <div className={className} {...props}>
    {title}
    {props.children}
  </div>
}

Card.Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  help: PropTypes.node,
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
}

Card.Body = ({
  className = '',
  ...props
}) => {
  className = `Card-Body ${className}`

  return <div className={className} {...props}>
    {props.children}
  </div>
}

Card.Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const HeaderTitle = props =>
  <div className="Card-Header-Title">
    {props.children}
  </div>

HeaderTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

Card.Header.Title = HeaderTitle

const HeaderSpacer = () =>
  <div className="Card-Header-spacer"/>

HeaderSpacer.propTypes = {
  children: PropTypes.node.isRequired,
}

Card.Header.Spacer = HeaderSpacer

Card.Buttons = ({ className = '', ...props }) =>
  <div className={`Card-Buttons ${className}`} {...props}>
    {props.children}
  </div>
