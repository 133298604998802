import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { BRANDING_TYPES_PROP_TYPE } from 'lib/organizationProfileHelpers'
import DEFAULT from './default.jpg'

import BannerImage from 'components/BannerImage'

export default function OrganizationBanner({
  className,
  src,
  organization,
  brandingType,
  ...props
}){
  if (organization && brandingType)
    src = organization[`${brandingType}_banner`]
  return <BannerImage {...{
    ...props,
    defaultSrc: DEFAULT,
    src,
    className: classNames('OrganizationBanner', { className }),
  }}/>
}

OrganizationBanner.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  organization: PropTypes.object,
  brandingType: BRANDING_TYPES_PROP_TYPE.isRequired,
}

OrganizationBanner.defaultProps = {
  brandingType: 'consumer',
}

OrganizationBanner.DEFAULT = DEFAULT
